import React, { Component } from "react";
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import 'bootstrap/dist/css/bootstrap.css';

export default class ClientReferralList extends Component {
  state = {
    clientRadio: "business",
    // referalList:[]
  };

  componentDidCatch

  // handleChange = (e) => {
  //   const { name, value } = e.target;
  //   let array = [];
  //   this.props.list.forEach((item) => {
  //     if (item._id === name) {
  //       array.push({ ...item, status: value });
  //     } else {
  //       array.push({ ...item });
  //     }
  //   });
  //   this.props.handlepropsListFun(array);
  // };

  handleRadio = () => {
    this.setState({
      clientRadio:
        this.state.clientRadio === "business" ? "personal" : "business",
    });
  };

  // handleChangeListType = () => {

  //   let array = [];
  //   let filtered = this.props.filter(num => num % 2 == 0)
  // };

  render() {
    const { list } = this.props;
    const referalList = list;
    let filtered = [];
    if (this.state.clientRadio === "business") {
      filtered = referalList.filter(item => item.clientType === "business")
    } else {
      filtered = referalList.filter(item => item.clientType === "personal")
    }
    const { first_name, last_name } = this.props.data;
    // console.log("this.props", this.props);
    // console.log("this.state.clientRadio = ", this.state.clientRadio);
    // console.log("filtered = ", filtered);


    return (
      <section className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-md-2">
              <span className="cui-utils-title">
                <strong>Your Referrals</strong>
              </span>
            </div>
            <div className="col-md-8 mb-3 pl-0" style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
              <div className="row" style={{ marginLeft: 0 }}>
                <label className="cui-utils-control cui-utils-control-radio mr-5">
                  <strong>Business</strong>
                  <input
                    type="radio"
                    checked={this.state.clientRadio === "business"}
                    onChange={this.handleRadio}
                  />
                  <span className="cui-utils-control-indicator" />
                </label>
                <label className="cui-utils-control cui-utils-control-radio">
                  <strong>Personal</strong>
                  <input
                    type="radio"
                    checked={this.state.clientRadio === "personal"}
                    onChange={this.handleRadio}
                  />
                  <span className="cui-utils-control-indicator" />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-12">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      {
                        this.state.clientRadio === "business" ?
                          <th scope="col">Business Name</th> :
                          <th scope="col">No Of T1s</th>
                      }
                      <th scope="col">Email</th>
                      <th scope="col">Name</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Remarks</th>
                      <th scope="col">Refered By</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filtered.length > 0 &&
                      filtered.map((v, k) => {
                        return (
                          <tr key={v._id}>
                            <th scope="row">{k + 1}</th>
                            {
                              this.state.clientRadio === "business" ?
                                <td>{v?.businessName || ""}</td> :
                                <td>{v?.t1Count || ""}</td>
                            }
                            <td>{v.email}</td>
                            <td>
                              {v.last_name}, {v.first_name}
                            </td>
                            <td>{v.phone_no}</td>
                            <td>
                              <OverlayTrigger
                                placement="top"
                                overlay={(props) => {
                                  return (<Tooltip id="button-tooltip" {...props}>
                                    <span>{v.remarks}</span>
                                  </Tooltip>)
                                }}>
                                <span className="icmn-eye" />
                              </OverlayTrigger>
                            </td>
                            <td>
                              {last_name}, {first_name}
                            </td>
                            <td>{v?.refStatus || "Prospect"}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
