import React,{Fragment, useState, useEffect} from 'react';
// import {getClientNotification} from '../../../../utils/API'
import {  getClientNotifications } from '../../../../utils/API';


function ClientNotification(props) {

    const [clientNotify, setClientNotify] = useState([]);
    



   // get api
    useEffect(() => {
        // console.log(props.clientData)
        if(props.clientData.length){
            // console.log(props.clientData[0].loggedInClient)
            const {clientId, primary_email} = props.clientData[0].loggedInClient;
            const notify = async()=>{
                const ab = await getClientNotifications(clientId, primary_email);
                //   console.log(ab,"hd'dh'hd")
                setClientNotify(ab.data)  
            }
            notify();
        }        
    }, []);


     // week
     function getCurrentWeek() {
   
        let weekDay=[]
        let first = new Date((new Date()).getTime() - (7 * 24 * 60 * 60 * 1000));
        for(let i of clientNotify ){
            let date = new Date(i.createdAt);

            if(date.getTime()>first.getTime())
            {
                //   console.log(a)
                weekDay.push(i);

            }
           
        }
        // console.log(weekDay);
       const ClientData= weekDay.map((e , idx)=>{
        
            return <tr key={idx}>
                
                <td>{new Date(e.createdAt).toString()}</td>
                <td>{e.clientName.split("&").join(', ')}</td>
                <td>{e.Name}</td>
                <td>{e.Type}</td>
    
            </tr>
        })
        return ClientData
        
    }
    

    function getLastWeek(){
        const weekDay=[];
        let first = new Date((new Date()).getTime() - (7 * 24 * 60 * 60 * 1000));
        for(let a of clientNotify ){
            let date = new Date(a.createdAt);
            
            if(date.getTime()<=first.getTime())
            {  
                //  console.log(a)
                weekDay.push(a);

            }
            
        }

        const clientData= weekDay.map((e , idx)=>{
            
                return <tr key={idx}>
                    
                    <td>{new Date(e.createdAt).toString()}</td>
                    <td>{e.clientName.split("&").join(', ')}</td>
                    <td>{e.Name}</td>
                    <td>{e.Type}</td>
        
                </tr>
            })
            return clientData
    }


    return (
                   <Fragment>
                {/* {this.renderTest()} */}
       
                <section className='card'>
                    <div className='card-header'>
                        <span className='cui-utils-title'>NOTIFICATION</span>
                    </div>
                
                        <div className='card-body'>
                            <div className="row">
                                <div className="col-lg-12">
                                    <h5>Current Week</h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Time</th>
                                                <th scope="col">Client</th>
                                                <th scope="col">File Name</th>
                                                <th scope="col">Type</th>                                                
                                            </tr>
                                        </thead>
                                        
                                        <tbody>
                                        
                                            {getCurrentWeek().length===0 ? 
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        No Data Found
                                                    </td>
                                                    <td></td>
                                                </tr> : getCurrentWeek()
                                            }      
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        {/* last week */}
                        <div className='card-body'>
                            <div className="row">
                                <div className="col-lg-12">
                                    <h5>Last Week</h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Time</th>
                                                <th scope="col">Client</th>
                                                <th scope="col">File Name</th>
                                                <th scope="col">Type</th>                                                
                                            </tr>
                                        </thead>
                                        
                                        <tbody>
                                            {getLastWeek().length===0 ? 
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        No Data Found
                                                    </td>
                                                    <td></td>
                                                </tr> : getLastWeek()
                                            }                                          
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    
                </section>
            </Fragment>
    )
}

export default ClientNotification


