import React, { Component } from 'react';
import { readString } from 'react-papaparse';
import axios from "axios";
import { Baseurl } from '../../../../utils/Baseurl';
import LaddaButton, {EXPAND_RIGHT} from 'react-ladda';

export default class BulkAddClient extends Component {
    state = {
        accountantId: ''
    }

    // createBulkFirebase = async() => {
    //     try {
    //         const result = await axios({
    //             method: 'get',
    //             url: Baseurl + `api/client/createBulkFirebase`,
    //             headers: { 'content-type': 'application/json' },
    //         });
    //         console.log('create bulk firebase. ', result)
    //     } catch (e) {
    //         console.log(e)
    //     }
    // }

    componentDidMount(){
        const {accountantData, location} = this.props;
        const [,,accountantId] = location.pathname.split('/');

        const validAccountant = accountantData.some(acc => acc.accountantId === accountantId);

        if(!validAccountant){
            this.props.history.push(`/bulk/${accountantData[0].accountantId}`);
            this.setState({accountantId: accountantData[0].accountantId});
        } else {
            this.setState({accountantId});
        }
    }

    uploadBulkPersonalClients = async(data, createLogin) => {
        let newData = data.data;
        newData.pop();

        if(newData.length < 1){
            alert('File does not contain client list.');
            return
        }

        const firstNameMissing = newData.some(value => value.firstName.trim() === '');
        const lastNameMissing = newData.some(value => value.lastName.trim() === '');
        const emailMissing = newData.some(value => value.email.trim() === '');
        let passwordMissing;

        if(createLogin){
            passwordMissing = newData.some(value => value.password.trim() === '');
        }        
        
        // console.log(firstNameMissing, lastNameMissing, emailMissing, passwordMissing, newData)
        if(firstNameMissing || lastNameMissing || emailMissing || (createLogin && passwordMissing)){
            alert(`
                ${firstNameMissing 
                    ? 'First name of client is missing in file. First name' 
                    : lastNameMissing
                        ? 'Last name of client is missing in file. Second name'
                        : emailMissing 
                            ? 'Email of client is missing in file. Email'
                            : 'Password of client is missing in file. Password'
                } is mandatory.
            `)
            return
        }

        let newArry = newData.map(value => {
            const {firstName, lastName, email, primaryContact, relation, phone, password} = value;
            return {
                firstName, lastName, primaryContact, relation, phone, password,
                primary_email: email.toLowerCase().trim()
            }
        });
        
        if(window.confirm(`Add ${newArry.length} personal client${createLogin ? ' with their login' : ''}. Confirm.?`)){
            // console.log(this.state.accountantId)
            createLogin 
                ? this.setState({isLoading2: true})
                : this.setState({isLoading: true})

            try {
                const result = await axios({
                    method: 'post',
                    url: Baseurl + `api/client/uploadBulkPersonalClients`,
                    headers: { 'content-type': 'application/json' },
                    data: {clientsToUpload: newArry, accountantId: this.state.accountantId, createLogin},
                });
                const data = result.data;
                // console.log('result. ', data);

                alert(`${data.message}`)
                this.props.reload();
                this.setState({isLoading: false, isLoading2: false});
            } catch (e) {
                const err = e.response;
                err.data.message && alert(err.data.message);
                console.log(e)
                this.setState({isLoading: false, isLoading2: false});
            }
        }        
    }

    handleFiles = async (e, clientType, createLogin) => {
        const self = this;
        const reader = new FileReader();
        reader.onload = function(e) {
            // Use reader.result
            const result = readString(reader.result, {header: true});
            self.uploadBulkPersonalClients(result, createLogin)
        };
        reader.readAsText(e.target.files[0]);
    };


    render(){
        const { isLoading, isLoading2 } = this.state;
        // console.log(this.props)
        return (
            <section className='card'>
                <div className='card-header'>

                    <div className='pull-left'>
                        <span className='cui-utils-title'><strong>Bulk Import Clients</strong></span>
                    </div>
                </div>
                <div className='card-body'>                    
                    <div className='col-lg-12'>
                        <div className='row'>
                            <label className='col-md-3 col-form-label mb-2'><strong>Upload Bulk Personal Clients</strong> </label>
                            <div>
                                <input
                                    ref="fileInput"
                                    onChange={e => this.handleFiles(e, 'personal', false)} 
                                    type="file"
                                    style={{ display: "none" }}
                                    accept=".csv"
                                    disabled={isLoading || isLoading2}
                                    // multiple={false}
                                />
                                <LaddaButton loading={isLoading}
                                            onClick={() => this.refs.fileInput.click()}
                                            data-style={EXPAND_RIGHT}
                                            className='btn btn-sm btn-primary mr-4'
                                >
                                    {isLoading ? 'Uploading..' : 'Upload'}
                                </LaddaButton>
                                <input
                                    ref="fileInputWithLogin"
                                    onChange={e => this.handleFiles(e, 'personal', true)} 
                                    type="file"
                                    style={{ display: "none" }}
                                    accept=".csv"
                                    disabled={isLoading || isLoading2}
                                    // multiple={false}
                                />
                                <LaddaButton loading={isLoading2}
                                            onClick={() => this.refs.fileInputWithLogin.click()}
                                            data-style={EXPAND_RIGHT}
                                            className='btn btn-sm btn-primary'
                                >
                                    {isLoading2 ? 'Uploading..' : 'Upload and create clients login'}
                                </LaddaButton>
                            </div>
                        </div>
                    </div>
                    {/* <hr />
                    <label className='col-lg-6 col-form-label mb-2'><strong>Upload Bulk Business Clients</strong> </label>
                    <div className='col-lg-12'>
                        <div className='row'>
                            
                        </div>
                    </div> */}
                </div>
            </section>
        )
    }
}