import React, {Component} from 'react';
import firebaseApp from "../../../../utils/Fire";
// import MoveModal from "./MoveModal";
import LaddaButton, {EXPAND_RIGHT} from "react-ladda";
import { POST } from '../../../../utils/API';


export default class UploadModalAdmins extends Component {
    state = {
        files: [],
        folder: '',
        subFolders: [],
        showModal: false,
        selectedItem: {},
        year: '2019',
        projects: [],
        projectId: 'none',
        scanType: 'Other',
        status: false
    };

    componentDidMount(){
        const {subFolders, projects, scanType, year,folder} = this.props;

        this.setState({
            subFolders,
			folder: subFolders[0],
            scanType, projects, year,
            folder,
            projectId: projects.length ? projects[0].id : null
        });
    }

    handleRadioClick = (state) => {
        this.setState({structure: state})
    };

    handleChange = e => {
        this.setState({[e.target.name]: e.target.value})
    };

    handleFiles = e => {
        const {files} = this.state;

        // console.log(e.target.files);
        Object.keys(e.target.files).map((key) => {
            let ext = e.target.files[key].name.split(".").pop();
            if (ext === "pdf") {
                files.push(e.target.files[key]);
            }
            return null;
        });
        this.setState({files});
    };

    

    handleUpload = e => {
        const {client, user, accountantData,handleUploadState} = this.props;
        const {folder, files, year} = this.state;
        const folderType = client[0].clientType === 'Business' ? folder : "T1" ;
        
        

        this.setState({isLoading: true});
        
        let fileNames = [];

        const storageRef = firebaseApp.storage().ref();
        const newPromise = files.map(file => {
            return new Promise((resolve, reject) => {
                const metadata = {
                    customMetadata: {
                        user: user.first_name,
                        // type: this.state.docType,
                        // structure: this.state.structure,
                        status: 'adminUpload'
                    }
                };
                // console.log(metadata);
                const userFileRef =  client[0].clientType === 'Business' 
                    ? storageRef.child(`${client[0].clientId}/${folder}/${year}/${file.name}`)
                    : storageRef.child(`${client[0].clientId}/${folder}/${file.name}`)

                userFileRef.put(file, metadata)
                    .then(res => {
                        fileNames.push(file.name)
                        resolve(res)
                    })
                    .catch(err => {
                        console.log(`Error uploading: ${file.name}`, err);
                        reject(`Error uploading: ${file.name}`)
                    })
                    // console.log(userFileRef);
            })
        });

        Promise.all(newPromise)
            .then(res => {

                const data = {
                    accountantId: accountantData[0].accountantId , 
                    clientId: client[0].clientId, 
                    folderType, 
                    fileNames
                    // fileName: files[0].name,
                }
                // post api 
                POST('api/storage/clientNotify', data).then(res =>{
                    // console.log(data);
                })
                .catch(error => {
                    console.log(error, "Not Notify");
                })

                // console.log(res);
                this.setState({isLoading: false, files: []});
                handleUploadState({folder,year})
                this.props.toggleModal();
            })
            .catch(err => console.log('Promise err: ', err))

         
            
    };
    

    render() {
        const {folder, files, isLoading, year, subFolders, projects, projectId, scanType} = this.state;
        // console.log(this.props.years)
        const isBusiness = this.props.client[0].clientType === 'Business';
        
        return (
            <div className={`modal fade show`} style={{display: 'block'}}>
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Upload Files</h5>
                            <button className="close" onClick={this.props.toggleModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className='modal-body'>
                            <div className='col-lg-12'>
                                {
                                    isBusiness && 
                                    <div className='mb-4' style={{display: 'flex'}}>
                                        {/* {scanType === 'Project' &&
                                            <select className='col-md-4 form-control mr-4' name='projectId' onChange={this.handleChange} value={projectId}>
                                           
                                                {
                                                    projects.map((p, i) => {
                                                        return <option key={i} value={p.id}>{p.name}</option>
                                                    })
                                                }
                                            </select>
                                        } */}
                                        <select className='col-md-3 form-control mr-4' name='folder' onChange={this.handleChange} value={folder}>
                                        {/* <option value='inbox'>Inbox</option>
                                        <option value='archive'>Archive</option> */} 
                                            {subFolders.map((folder, i) => {
                                                if(!isBusiness){
                                                    return folder === 'T1' && <option key={i} value={folder}>{folder}</option>
                                                } else {
                                                    return folder !== 'T1' &&  <option key={i} value={folder}>{folder}</option>
                                                }
                                                // return <option key={i} value={folder}>{folder}</option>
                                            })}
                                        </select>
                                        <select className='col-md-3 form-control width-100' name='year' onChange={this.handleChange} value={year}>
                                            {this.props.years.map((year, i) => {
                                                return <option key={i} value={year}>{year}</option>
                                            })}
                                        </select>
                                    </div>
                                }
                                
                                <div className="dropify-wrapper">
                                    <div className="dropify-message">
                                        <span className="file-icon"/>
                                        <p>Drag and drop a file here or click to upload</p>
                                        <p className="dropify-error">Ooops, something wrong appended.</p>
                                    </div>
                                    <div className="dropify-loader" style={{display: 'none'}}/>

                                    {this.state.status && (
                                        <p className="text-danger">
                                            Oops, something went wrong, Please choose pdf file.
                                        </p>
                                    )}
                                    <input type="file" accept='.pdf' className="dropify" multiple={true} disabled={isLoading}
                                           onChange={this.handleFiles}/>
                                    <button type="button" className="dropify-clear">Remove</button>
                                    <div className="dropify-preview" style={{display: 'none'}}>
                                        <span className="dropify-render"/>
                                        <div className="dropify-infos">
                                            <div className="dropify-infos-inner">
                                                <p className="dropify-filename">
                                                    <span className="file-icon"/>
                                                    <span className="dropify-filename-inner"/>
                                                </p>
                                                <p className="dropify-infos-message">Drag and drop or click to
                                                    replace</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    files.length > 0 &&
                                    <div className='table-responsive' style={{maxHeight: 185}}>
                                        <table className='table table-hover'>
                                            <thead>
                                            <tr>
                                                <th>File Name (Only PDF accepted)</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                files.map((item, idx) => {
                                                    return (
                                                        <tr key={idx}>
                                                            <td>{item.name}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='modal-footer'>
                            {files.length > 0 &&
                                <LaddaButton loading={isLoading}
                                             onClick={this.handleUpload}
                                             data-style={EXPAND_RIGHT}
                                             className='btn btn-sm btn-primary'
                                >
                                    {isLoading ? 'Uploading..' : 'Upload'}
                                </LaddaButton>
                            }
                            <button className='btn btn-sm'
                                    onClick={this.props.toggleModal}
                                    disabled={this.state.isLoading}>Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
