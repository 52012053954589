import React, { Component } from "react";
import { GET, POST } from "../../../utils/API";
import moment from "moment";
import 'bootstrap/dist/css/bootstrap.css';
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';


export default class Referal extends Component {
  state = {
    referals: [],
    clientType: "business",
  };

  componentDidMount() {
    // console.log("this.props", this.props);
    let url = "api/referal";
    if (this.props.isAccountant) {
      let account = this.props.accountantData[0];
      url += "?accountantId=" + account.accountantId;
    }
    GET(url).then((referal) => {
      this.setState({
        referals: referal.data
      });
    });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    if (window.confirm('Confirm to change the status of referral.')) {
      POST('api/referal/update-refStatus', { _id: name, refStatus: value })
        .then(res => {
          this.componentDidMount()
        }).catch(err => console.log(err))
    }
  };

  handleRadioClick = () => {
    this.setState({
      clientType:
        this.state.clientType === "business" ? "personal" : "business",
      // t1Count: "",
      // remarks: "",
      // businessName: "",
    });
  };


  render() {
    const { referals } = this.state;
    const referalList = referals;
    let filtered = [];
    if (this.state.clientType === "business") {
      filtered = referalList.filter(item => item.clientType === "business")
    } else {
      filtered = referalList.filter(item => item.clientType === "personal")
    }

    return (
      <section className="card">
        <div className="card-header">
        <div className="row">
          <div className="col-md-2">
            <span className="cui-utils-title">
              <strong>Referals</strong>
            </span>
          </div>
          <div className="col-md-8 mb-3 pl-0" style={{display:'flex',alignItems:"center",justifyContent:"center"}}>
            <div className="row" style={{ marginLeft: 0 }}>
              <label className="cui-utils-control cui-utils-control-radio mr-5">
                <strong>Business</strong>
                <input
                  type="radio"
                  name="radio"
                  checked={this.state.clientType === "business"}
                  onChange={this.handleRadioClick}
                />
                <span className="cui-utils-control-indicator" />
              </label>
              <label className="cui-utils-control cui-utils-control-radio">
                <strong>Personal</strong>
                <input
                  type="radio"
                  name="radio"
                  checked={this.state.clientType === "personal"}
                  onChange={this.handleRadioClick}
                />
                <span className="cui-utils-control-indicator" />
              </label>
            </div>
          </div>
          </div>
        </div>
        <div className="card-body">

          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-12">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      {
                        this.state.clientType === "business" ?
                          <th scope="col">Business Name</th> :
                          <th scope="col">No Of T1s</th>
                      }
                      <th scope="col">Email</th>
                      <th scope="col">Name</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Refered By</th>
                      <th scope="col">Remarks</th>
                      <th scope="col">Status</th>
                      <th scope="col">Created Date</th>
                      <th scope="col">Activated Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filtered.length > 0 &&
                      filtered.map((v, k) => {
                        let clientName = v?.referedBy?.clientName || "";
                        if (clientName.length) {
                          if (clientName.indexOf("&") !== -1) {
                            const [firstName, lastName] = clientName.split("&");
                            clientName = lastName + ", " + firstName;
                          }
                        }
                        return (
                          <tr key={k}>
                            <th scope="row">{k + 1}</th>
                            {
                              this.state.clientType === "business" ?
                                <td>{v?.businessName || ""}</td> :
                                <td>{v?.t1Count || ""}</td>
                            }
                            <td>{v.email}</td>
                            <td>
                              {v.last_name}, {v.first_name}
                            </td>
                            <td>{v.phone_no}</td>
                            <td>{clientName}</td>
                            <td style={{ textAlign: 'center' }}>
                              <OverlayTrigger
                                placement="top"
                                overlay={(props) => {
                                  return (<Tooltip id="button-tooltip" {...props}>
                                    <span>{v.remarks}</span>
                                  </Tooltip>)
                                }}>
                                <span class="icmn-eye" />
                              </OverlayTrigger>
                            </td>
                            <td>
                              <select
                                className="form-control mr-2"
                                style={{ height: 31, padding: "2px 1rem" }}
                                name={v._id}
                                value={v?.refStatus}
                                onChange={this.handleChange}
                              >
                                <option value="Prospect">Prospect</option>
                                <option value="Success">Success</option>
                                <option value="Inactive">Inactive</option>
                                <option value="Failed">Failed</option>
                              </select>
                            </td>
                            <td>{
                              v.createdAt ?
                                moment(
                                  v.createdAt
                                ).format(" YYYY-MM-DD") : ""
                            }
                            </td>
                            <td>
                              {
                                v.activatedAt ?
                                  moment(
                                    v.activatedAt
                                  ).format(" YYYY-MM-DD") : ""
                              }
                            </td>

                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
