import React from 'react';
import defaultImage from '../../../../assests/img/no-image-icon.jpg';
import { JustSpinner } from '../../../../assests/loader/Spinner';
import { GET, POST } from '../../../../utils/API';
import firebaseApp from "../../../../utils/Fire";


export default class DisplayPicture extends React.Component {

    state = {
        image: '',
        isLoading: true
    }

    getDP = () => {
        const accountantId = window.location.href.split('/').pop();
        // console.log(accountantId)
        GET(`api/accountant/get-accountant-display-pic?id=` + accountantId)
            .then(res => { 
                const storageRef = firebaseApp.storage().ref();
                if(res.data.displayPic){
                    storageRef.child(res.data.displayPic).getDownloadURL().then(url => {
                        this.setState({image: url, isLoading: false})
                    }).catch(err => console.log('get dp url error: ', err))
                } else {
                    this.setState({image: '', isLoading: false})
                }
            }).catch(err => console.log('Get accountant display pic error: ', err))
    }

    updateImageAPI = (imageURL) => {
        const accountantId = window.location.href.split('/').pop();
        POST('api/accountant/update-accountant-display-pic', {displayPic: imageURL, accountantId}).then(() => {
            // console.log(res.data, result);
            this.getDP()
        }).catch(err => {console.log(err.res); this.setState({isLoading: false})})
    }

    componentDidUpdate(prevProps){
        // console.log('updated.....', this.props, prevProps)
        if(prevProps.accountantId !== this.props.accountantId){
            this.setState({isLoading: true})
            this.getDP()
        }
    }

    handleFiles = (e) => {
        const file = e.target.files[0]
        const accountantId = window.location.href.split('/').pop();
        const imageURL = file.type === 'image/png' ? `${accountantId}/images/displayPic/dp.png` : `${accountantId}/images/displayPic/dp.jpg`
        const storageRef = firebaseApp.storage().ref();
        const userFileRef = storageRef.child(imageURL);
        this.setState({isLoading: true});
        userFileRef.put(file)
            .then(() => {
                this.updateImageAPI(imageURL)
            })
            .catch(err => {
                console.log(`Error uploading to storage: ${file.name}`, err);
            })
        
    }

    removeImage = () => {
        if(window.confirm('Are you sure you want to remove the display image/logo')){
            this.setState({isLoading: true})
            this.updateImageAPI('')
        }
    }

    render(){
        const {image, isLoading} = this.state;
        
        const newStyle = {
            backgroundImage: `url('${image}')`,
            backgroundSize: 'cover',
            height: 100,
            width: 100,
            backgroundPosition: 'center'
        }
        const spinnerStyle = {
            paddingLeft: 45,
            paddingTop: 45,
            marginLeft: 25,
            marginTop: 25
        }
        return (
            <div className="col-lg-6">                                
                <div className="text-center d-flex" >
                    <div>
                        <label className="col-form-label"><strong>Display Image/Logo</strong> </label><br/>
                        {image && <button className="btn btn-sm btn-danger" onClick={this.removeImage}>Remove</button>}
                    </div>
                    
                    {isLoading && <div className="ml-4" style={{height: 100, width: 100}}><JustSpinner style={spinnerStyle}/></div>}
                    {!isLoading
                        ?
                        <div onClick={() => this.inputElement.click()} style={{cursor: 'pointer'}}>
                            {
                                image
                                    ? <div style={newStyle} className="ml-4 rounded-circle" />
                                    : <img src={defaultImage} alt='' style={{height: 100}} className="ml-4 rounded-circle"/>
                            }
                        </div>
                        
                        : null
                    }
                    
                    <input accept="image/*" hidden ref={el => this.inputElement = el} type="file" onChange={this.handleFiles}/>
                </div>
            </div>
        )
    }
}