import React, {Component} from 'react';
import axios from "axios";
import {Baseurl} from '../../../utils/Baseurl';


export default class TokenHandler extends Component {
    state ={}


    componentDidMount(){        
        // console.log(this.props)
        let code = this.props.location.search.slice(1).split('&')[0];
        console.log(this.props.location.search)
        code = code.split('=')[1];
        console.log(code)
    }

    getRefreshToken = async () => {
        
        let code = this.props.location.search.slice(1).split('&')[0];
        code = code.split('=')[1];
        console.log(code)
        const options = {
            method: 'post',
            url: Baseurl + 'api/storage/getRefreshToken',
            headers: {"Content-Type": "application/json",},
            data: {code},
        };
        
        const response = await axios(options);  // wrap in async function
        console.log(response);
    }

    render(){
        console.log(this.props)
        return(
            <div>
                <button className="btn btn-primary" onClick={this.getRefreshToken}>Refresh Token</button>
            </div>
        )
    }
}
