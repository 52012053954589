import React, {Component} from 'react';


export default class ScanType extends Component {
    state = {
        projectName: ''
    };

    handleChange = (e) => {
        this.setState({projectName: e.target.value})
    };

    handleAddMore = () => {
        const id = String(Date.now());

        this.props.handleSetProjects(
            this.props.projects.concat({
                name: this.state.projectName,
                id
            })
        );
        this.setState({projectName: ''})
    };

    handleRemove = (index) => {
        if(this.props.edit){
            this.props.projects.splice(index, 1);

            this.props.handleSetProjects(
                this.props.projects
            )
        } else {
            alert('Click on Edit first');
        }
    };

    render(){
        const {scanType, projects, handleChange, edit} = this.props;
        const newScanType = scanType === 'Calendar' ? 'CalendarM' : scanType;
        return(
            <div className='col-lg-12'>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className='row'>
                            <label className='col-md-4 col-form-label'>Scan Type:</label>
                            <div className='col-md-8' >
                                <select className='form-control mr-2' style={{height: 31, padding: '2px 1rem'}}
                                        name='scanType'
                                        disabled={!edit}
                                        value={newScanType} onChange={handleChange}>
                                    <option value='Other'>Ebox</option>
                                    <option value='Project'>Project</option>
                                    <option value='CalendarY'>Calendar Yearly</option>
                                    <option value='CalendarM'>Calendar Monthly</option>
                                </select>
                            </div>
                        </div>
                        <div className='row'>
                            <label className='col-md-4 col-form-label'>Add Project Name:</label>
                            <div className='col-md-8' >
                                <div style={{display: 'flex'}}>
                                    <input className='form-control mr-2'
                                           name='projectName'
                                           type='text'
                                           disabled={scanType !== 'Project' || !edit}
                                           onChange={this.handleChange}
                                           value={this.state.projectName}/>

                                    <button className='btn btn-sm btn-success'
                                            disabled={scanType !== 'Project' || !edit}
                                            onClick={this.handleAddMore}>Add</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        !projects.length
                            ? <label className='col-md-4 col-form-label'>No Projects added yet.</label>
                            :
                            <div className='col-lg-6'>
                                <span className="select2 select2-container select2-container--default">
                                    <span className="selection">
                                        <span className="select2-container select2-selection select2-selection--multiple">
                                            <ul className="select2-selection__rendered">
                                                {
                                                    projects.map((p,i) => {
                                                        return (
                                                            <li className="select2-selection__choice mr-2"
                                                                key={i}
                                                                // style={{listStyle: 'none'}}
                                                            >
                                                                <span className="select2-selection__choice__remove"
                                                                      onClick={()=> this.handleRemove(i)}>×</span>
                                                                {p.name}
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </span>
                                    </span>
                                </span>
                            </div>
                    }
                </div>
            </div>
        )
    }
}
