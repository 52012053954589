import axios from "axios";
import { Baseurl } from "./Baseurl";
import firebaseApp from "../utils/Fire";

// export function postPrivate(userData){
//     const firebaseApp.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
//         console.log(idToken)
//       }).catch(function(error) {
//         // Handle error
//       });
//     return axios({
//         method: 'post',
//         url: Baseurl + 'api/user/register',
//         headers: {
//             "Content-Type": "application/json",
//         },
//         data: userData,
//     })
// }

const getToken = () => {
  return firebaseApp.auth().currentUser.getIdToken();
};

export function getUser(emailId) {
  return axios({
    method: "get",
    url: Baseurl + `api/user/getUser?email=${emailId}`,
    headers: { "content-type": "application/json" },
  });
}

export function getUserFromId(id) {
  return axios({
    method: "get",
    url: Baseurl + `api/user/getUserFromId?uid=${id}`,
    headers: { "content-type": "application/json" },
  });
}

export function createUser(userData) {
  return axios({
    method: "post",
    url: Baseurl + "api/user/register",
    headers: {
      "Content-Type": "application/json",
    },
    data: userData,
  });
}


export function updateUser(data) {
  return axios({
    method: "post",
    url: Baseurl + "api/user/updateUser",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  });
}


// delete
export function DELETE(url) {
  return axios({
    method: "delete",
    url: Baseurl + url,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function GET(url) {
  const token = await getToken();
  return axios({
    method: "get",
    url: Baseurl + url,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}




export async function POST(url, data) {
  const token = await getToken();
  return axios({
    method: "post",
    url: Baseurl + url,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    data: data,
  });
}

export function getAccountant(id) {
  return axios({
    method: "get",
    url: Baseurl + `api/accountant/getAccountant?id=${id}`,
    headers: { "content-type": "application/json" },
  });
}

export function getStaff(id) {
  return axios({
    method: "get",
    url: Baseurl + `api/user/getStaff?accountantId=${id}`,
    headers: { "content-type": "application/json" },
  });
}

export function getClient(id) {
  return axios({
    method: "get",
    url: Baseurl + `api/client/getClient?id=${id}`,
    headers: { "content-type": "application/json" },
  });
}

export function getClientByEmail(id) {
  return axios({
    method: "get",
    url: Baseurl + `api/client/getClientByEmail?email=${id}`,
    headers: { "content-type": "application/json" },
  });
}

// export function addDocType(data){
//     return axios({
//         method: 'post',
//         url: Baseurl + `api/docType/addDocType`,
//         headers: {
//             "Content-Type": "application/json",
//         },
//         data: data,
//     })
// }

export async function getDocType(id) {
  const token = await getToken();
  return axios({
    method: "get",
    url: Baseurl + `api/docType/get-accountant-docType?accountantId=${id}`,
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}
export async function getDocType1() {
  const token = await getToken();
  return axios({
    method: "get",
    url: Baseurl + `api/docType/getDocType`,
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

export async function getClientNotifications(id, email) {
  const token = await getToken();
  return axios({
    method: "get",
    url: Baseurl + `api/storage/clientNotify?clientId=${id}&email=${email}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}

// export function updateDocType(data) {
//   return axios({
//     method: "post",
//     url: Baseurl + `api/docType/updateDocType`,
//     headers: {
//       "Content-Type": "application/json",
//     },
//     data: data,
//   });
// }

export async function addDocType(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    url: Baseurl + `api/docType/add-accountant-docType`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    data: data,
  });
}

export async function updateDocType(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    url: Baseurl + `api/docType/update-accountant-docType`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    data: data,
  });
}

export function copyFile(data) {
  return axios({
    method: "post",
    url: Baseurl + `api/storage/copy`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export function uploadToOneDrive(data) {
  // console.log(data);

  return axios({
    method: "post",
    url: Baseurl + `api/storage/uploadToOneDrive`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  });
}

export async function getReferral(clientId) {
  const token = await getToken();
  return axios({
    method: "get",
    url: Baseurl + `api/referal/getClientReferrals?clientId=${clientId}`,
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
}


