import React, {Component} from 'react';
import firebaseApp from "../../../../utils/Fire";
import JSZip from "jszip";
import saveAs from "file-saver";
import {EXPAND_RIGHT, ZOOM_OUT} from "react-ladda";
import LaddaButton from "react-ladda";


export default class Download extends Component {
    state = {
        isLoading: false,
        items: [],
        mainCheck: false,
        filesToDownload: []
    };

    loadFilesInState = (props) => {
        
        const {folder, year, client} = props; 
        const isBusiness = client[0].clientType === 'Business';
        // console.log(isBusiness)
        const storageRef = firebaseApp.storage().ref();
        const id = props.location.pathname.split('/').pop();
        const listRef = isBusiness ? storageRef.child(`${id}/${folder}/${year}`) : storageRef.child(`${id}/${folder}`)


        listRef.listAll()
            .then(res => {
                const items = res.items.map(item => {
                    return new Promise((resolve, reject) => {
                        const inboxRef = storageRef.child(item.fullPath);
                        inboxRef.getMetadata()
                            .then(result => resolve(result))
                            .catch(err => {console.log('meta error: ', err); reject(err)});
                    })
                });

                Promise.all(items)
                    .then(res => {
                        this.setState({items: res, isLoading: false})
                    })
                    .catch(err => console.log('promise err: ', err));

            })
            .catch(err => {
                this.setState({isLoading: false});
                console.log('get inbox list error: ', err)
            })
    };

    componentDidMount(){
        this.setState({isLoading: true});
        this.loadFilesInState(this.props)
    }

    UNSAFE_componentWillReceiveProps(props){
        this.setState({isLoading: true});
        this.loadFilesInState(props)
    }

    handleCheckbox = (ref) => {
        const {mainCheck, items} = this.state;

        if(ref === 'main'){
            this.setState({mainCheck: !mainCheck, filesToDownload: !mainCheck ? [...items] : []});

        } else {
            // now ref is basically item to collect.
            const item = ref;
            const isIncluded = this.state.filesToDownload.some(value => value.name === item.name);
            if(isIncluded){
                const files = this.state.filesToDownload;
                const index = files.findIndex(value => value.name === item.name);
                files.splice(index, 1);
                this.setState({filesToDownload: files, mainCheck: false});
            } else {
                this.setState({filesToDownload: this.state.filesToDownload.concat(item), mainCheck: false});
            }
        }
    };

    handleChange = e => {
        console.log(e.target.name, e.target.value)
    };

    handleDownload = (item) => {

        const storageRef = firebaseApp.storage().ref();
        storageRef.child(item.fullPath).getDownloadURL()
            .then(url => {
                window.open(url);
            })
            .catch(err => console.log(err))
    };

    handleDelete = (item) => {
        const that = this;
        if(window.confirm(`Delete ${item.name}`)){
            this.state.items.map(i => {
                if(item.fullPath === i.fullPath){
                    i.deleting = true;
                }
                return null
            });
            this.setState({items: this.state.items});
    
            const storageRef = firebaseApp.storage().ref();
            const userFileRef = storageRef.child(item.fullPath);
            userFileRef.delete().then(function() {
                that.loadFilesInState(that.props);
              }).catch(function(error) {
                alert('Something went wrong while deleting file.')
                console.log(error)
              });
        }
    }

    handleMultipleDownload = () => {
        console.log('now download these: ', this.state.filesToDownload);
        this.setState({downloading: true});
        const self = this;

        const zip = new JSZip();
        const files = this.state.filesToDownload;

        const storageRef = firebaseApp.storage().ref();
        files.map((file, index) => {
            // console.log(file.fullPath)
            storageRef.child(file.fullPath).getDownloadURL()
                .then(url => {
                    let xhr = new XMLHttpRequest();
                    xhr.responseType = 'blob';
                    xhr.onload = function(event) {
                        let blob = xhr.response;
                        zip.file(file.name, blob);
                        if(files.length === (index + 1)){
                            setTimeout(() => {
                                zip.generateAsync({type: 'blob'})
                                    .then(blob => saveAs(blob, 'Files.zip'))
                                    .then(() => self.setState({downloading: false}))
                            }, 1000);
                        }
                    };
                    xhr.open('GET', url);
                    xhr.send();
                })
                .catch(err => {
                    alert('Something went wrong while downloading. Please contact admin.');
                    console.log(err);
                });
            return null
        })
    };

    render(){
        const {items, isLoading, downloading} = this.state;
        const {folder} = this.props;
        // console.log(isClient);
        return(
            <div className='col-lg-12 mt-5'>
                <div className='row'>
                    <h2>{folder}</h2>
                    <div className='p-2'>
                        {
                            this.state.filesToDownload.length > 1 &&
                            <LaddaButton loading={downloading}
                                         onClick={this.handleMultipleDownload}
                                         data-style={EXPAND_RIGHT}
                                         className='btn btn-sm btn-secondary'
                            >
                                {downloading ? 'Downloading..' : 'Download All'}
                            </LaddaButton>
                        }
                    </div>
                </div>
                {!items.length && <hr/>}
                <div>
                    {isLoading
                        ? <p>Fetching {folder} files. Please wait....</p>
                        : !items.length
                            ? <h5>No files in {folder}.</h5>
                            : this.renderTable()
                    }

                </div>
            </div>
        )
    }

    renderTable(){
        const {items} = this.state;
        const {isClient} = this.props;
        return(
            <div className='row mb-4'>
                <div className='table-responsive'>
                    <table className='table table-hover'>
                        <thead>
                        <tr>
                            <th>
                                <label className='cui-utils-control cui-utils-control-checkbox' style={{marginBottom: '1.76rem'}}>
                                    <input type="checkbox"
                                           onChange={() => this.handleCheckbox('main')}
                                           checked={this.state.mainCheck}
                                           disabled={this.state.downloading}
                                    />
                                    <span className='cui-utils-control-indicator'/>
                                </label>
                            </th>
                            <th>Files</th>
                            {/*<th>User</th>*/}
                            {/*<th>Type</th>*/}
                            {/*<th>Structure</th>*/}
                            {/*<th>Status</th>*/}
                            <th>Date</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            items.map((item, idx) => {
                                // const custom = item.customMetadata;
                                const toDownload = this.state.filesToDownload.some(value => value.name === item.name);

                                return (
                                    <tr key={idx}>
                                        <td>
                                            <label className='cui-utils-control cui-utils-control-checkbox'>
                                                <input type="checkbox"
                                                       onChange={() => this.handleCheckbox(item)}
                                                       checked={toDownload}
                                                       disabled={this.state.downloading}
                                                />
                                                <span className='cui-utils-control-indicator'/>
                                            </label>
                                        </td>
                                        <td>{item.name}</td>
                                        {/*<td>{custom.user}</td>*/}
                                        {/*<td>{custom.type}</td>*/}
                                        {/*<td>{custom.structure}</td>*/}
                                        {/*<td>*/}
                                            {/*<select className='form-control' style={{height: 31, padding: '2px 1rem'}}*/}
                                                    {/*value={custom.status} onChange={this.handleChange}>*/}
                                                {/*<option value='Pending'>Pending</option>*/}
                                                {/*<option value='In-Process'>In-Process</option>*/}
                                                {/*<option value='Processed'>Processed</option>*/}
                                                {/*<option value='Query'>Query</option>*/}
                                                {/*<option value='Discard'>Discard</option>*/}
                                            {/*</select>*/}
                                        {/*</td>*/}
                                        <td>{item.timeCreated.split('T')[0]}</td>
                                        <td>
                                            <button className='btn btn-sm btn-secondary' onClick={() => this.handleDownload(item)}>
                                                Download
                                            </button>
                                            {!isClient && 
                                                <LaddaButton loading={item.deleting}
                                                    onClick={() => this.handleDelete(item)}
                                                    data-style={ZOOM_OUT}
                                                    className='btn btn-sm btn-danger ml-1'
                                                >
                                                    Delete
                                                </LaddaButton>
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
