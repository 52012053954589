import React, {Component} from 'react';
import firebaseApp from "../../../../../utils/Fire";
import ReasonModal from "../ReasonModal";
import JSZip from "jszip";
import saveAs from 'file-saver';
import {EXPAND_RIGHT, ZOOM_OUT} from "react-ladda";
import LaddaButton from "react-ladda";
import { autoCapitalize } from '../../../../../utils/FormValidater';
// import SelectBox from "./SelectBox";


export default class Inbox extends Component {
    state = {
        isLoading: true,
        items: [],
        mainCheck: false,
        filesToDownload: []
    };

    setThisState = (props) => {
        const folder = props.folder;
        const storageRef = firebaseApp.storage().ref();
        const id = props.location.pathname.split('/').pop();
        const listRef = storageRef.child(`${id}/${folder}`);

        // const listRef = storageRef.child(`34b8dc05-4013-4d0b-b25a-52ca28dc4e66/inbox`);

        listRef.listAll()
            .then(res => {
                // console.log(res);
                const items = res.items.map(item => {
                    return new Promise((resolve, reject) => {
                        if(item.fullPath.split(".")[1].toLowerCase()=== "jpg"){
                            resolve({name: 'jpgFile'})
                        } else {
                            const inboxRef = storageRef.child(item.fullPath);
                            inboxRef.getMetadata()
                                .then(result => {
                                    // console.log(result);
                                    resolve(result)
                                })
                                .catch(err => {console.log('meta error: ', err); reject(err)});
                        }
                        
                    })
                    
                });

                Promise.all(items)
                    .then(result => {
                        const withoutMetaFile = result.filter(value => value.name !== 'sm_meta.json' && value.name !== "jpgFile");
                        withoutMetaFile.sort((a,b) => (a.timeCreated < b.timeCreated) ? 1 : ((b.timeCreated < a.timeCreated) ? -1 : 0));
                        this.setState({items: withoutMetaFile, isLoading: false})
                    })
                    .catch(err => console.log('promise err: ', err));

            })
            .catch(err => {
                this.setState({isLoading: false});
                console.log('get inbox list error: ', err)
            })
    };

    componentDidMount(){
        this.setThisState(this.props)
    }

    UNSAFE_componentWillReceiveProps(props){
        this.setState({isLoading: true});
        this.setThisState(props)
    }

    handleCheckbox = (ref) => {
        const {mainCheck, items} = this.state;

        if(ref === 'main'){
            this.setState({mainCheck: !mainCheck, filesToDownload: !mainCheck ? [...items] : []});

        } else {
            // now ref is basically the item to collect.
            const item = ref;
            const isIncluded = this.state.filesToDownload.some(value => value.name === item.name);
            if(isIncluded){
                const files = this.state.filesToDownload;
                const index = files.findIndex(value => value.name === item.name);
                files.splice(index, 1);
                this.setState({filesToDownload: files, mainCheck: false});
            } else {
                this.setState({filesToDownload: this.state.filesToDownload.concat(item), mainCheck: false});
            }
        }
    };

    toggleReasonModal = () => {
        this.setState({openReasonModal: false, selectedItem: null, selectedStatus: ''});
        this.props.toggleModal();
    };

    handleDownload = (item) => {

        const storageRef = firebaseApp.storage().ref();
        storageRef.child(item.fullPath).getDownloadURL()
            .then(url => {
                let xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = function(event) {
                    let blob = xhr.response;
                    saveAs(blob, item.name);
                };
                xhr.open('GET', url);
                xhr.send();
                // window.open(url);
            })
            .catch(err => console.log(err))
    };

    handleDelete = (item) => {
        const that = this;
        if(window.confirm(`Delete ${item.name}`)){
            this.state.items.map(i => {
                if(item.fullPath === i.fullPath){
                    i.deleting = true;
                }
                return null
            });
            this.setState({items: this.state.items});
    
            const storageRef = firebaseApp.storage().ref();
            const userFileRef = storageRef.child(item.fullPath);
            userFileRef.delete().then(function() {
                that.reloadInbox();
              }).catch(function(error) {
                alert('Something went wrong while deleting file.')
                console.log(error)
              });
        }
    }

    reloadInbox = () => {
        this.componentDidMount();
    };

    handleMultipleDownload = () => {
        console.log('now download these: ', this.state.filesToDownload);
        this.setState({downloading: true});
        const self = this;

        const zip = new JSZip();
        const files = this.state.filesToDownload;

        const storageRef = firebaseApp.storage().ref();
        files.map((file, index) => {
            // console.log(file.fullPath)
            storageRef.child(file.fullPath).getDownloadURL()
                .then(url => {
                    let xhr = new XMLHttpRequest();
                    xhr.responseType = 'blob';
                    xhr.onload = function(event) {
                        let blob = xhr.response;
                        zip.file(file.name, blob);
                        if(files.length === (index + 1)){
                            setTimeout(() => {
                                zip.generateAsync({type: 'blob'})
                                    .then(blob => saveAs(blob, 'Files.zip'))
                                    .then(() => self.setState({downloading: false}))
                            }, 1000);
                        }
                    };
                    xhr.open('GET', url);
                    xhr.send();
                })
                .catch(err => {
                    alert('Something went wrong while downloading. Please contact admin.');
                    console.log(err);
                });
            return null
        })
    };

    render(){
        const {items, isLoading, downloading} = this.state;
        const folderName = autoCapitalize(this.props.folder);

        return(
            <div className='col-lg-12 mt-5'>
                <div className='row'>
                    <h2 className='mr-2'>{folderName}</h2>
                    <div className='p-2'>
                        {
                            this.state.filesToDownload.length > 1 &&
                            <LaddaButton loading={downloading}
                                         onClick={this.handleMultipleDownload}
                                         data-style={EXPAND_RIGHT}
                                         className='btn btn-sm btn-secondary'
                            >
                                {downloading ? 'Downloading..' : 'Download All'}
                            </LaddaButton>
                        }
                    </div>
                </div>
                {!items.length && <hr/>}
                <div>
                    {isLoading
                        ? <p>Fetching {folderName} files. Please wait....</p>
                        : !items.length
                            ? <h5>No files in {folderName}.</h5>
                            : this.renderTable()
                    }

                </div>
                {this.state.openReasonModal && <ReasonModal {...this.state} {...this.props}
                                                            reloadInbox={this.reloadInbox}
                                                            toggleReasonModal={this.toggleReasonModal}/>}
            </div>
        )
    }

    renderTable(){
        const {items} = this.state;
        const {isClient} = this.props;
        // console.log("items = ",items)
        // const hasProject = items.some(v => v.customMetadata.scanType === 'Project');
        // const hasCalY = items.some(v => v.customMetadata.scanType === 'CalendarY');
        // const hasCalM = items.some(v => v.customMetadata.scanType === 'CalendarM');

        return(
            <div className='row mb-4'>
                <div className='table-responsive'>
                    <table className='table table-hover'>
                        <thead>
                        <tr>
                            <th>
                                <label className='cui-utils-control cui-utils-control-checkbox' style={{marginBottom: '1.76rem'}}>
                                    <input type="checkbox"
                                           onChange={() => this.handleCheckbox('main')}
                                           checked={this.state.mainCheck}
                                        // disabled={!this.state.edit}
                                    />
                                    <span className='cui-utils-control-indicator'/>
                                </label>
                            </th>
                            <th>Files</th>
                            {/* {hasProject &&
                                <th>Project</th>
                            }
                            { hasCalY || hasCalM  ?   <th>Month/Year</th> : ""} */}
                            <th>User</th>
                            <th>Type</th>
                            <th>Structure</th>
                            <th>Status</th>
                            <th>Date</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            items.map((item, idx) => {
                                const custom = item.customMetadata;
                                const [year, month, date] = item.timeCreated.split('T')[0].split('-');
                                const toDownload = this.state.filesToDownload.some(value => value.name === item.name);

                                return (
                                    <tr key={idx}>
                                        <td>
                                            <label className='cui-utils-control cui-utils-control-checkbox'>
                                                <input type="checkbox"
                                                       onChange={() => this.handleCheckbox(item)}
                                                       checked={toDownload}
                                                       // disabled={!this.state.edit}
                                                />
                                                <span className='cui-utils-control-indicator'/>
                                            </label>
                                        </td>
                                        <td style={{display:"flex",flexDirection:"column"}}>
                                        <span>{item.name}</span>
                                        <span style={{fontSize:"11px",color:"#adadad"}}>{custom?.scanType === "Project" ? custom.projectName !== 'none' ? custom.projectName : '' : custom?.scanType === "CalendarY" ? custom?.year : custom?.scanType === "CalendarM" ? `${custom?.month ? custom.month : "-"}/${custom?.year ? custom.year : "-"}` : ""}</span>
                                        </td>
                                        {/* {hasProject &&
                                            <td>{custom.projectName !== 'none' ? custom.projectName : ''}</td>
                                        }
                                        {hasCalY || hasCalM ?  <td>{ `${custom?.month ? custom.month : "-"}/${custom?.year ? custom.year : "-"}`}</td> : ""} */}
                                        <td>{custom.user}</td>
                                        <td>{custom.type}</td>
                                        <td>{custom.structure}</td>
                                        <td style={{display: 'flex'}}>
                                            {
                                                // this.props.isClient
                                                //     ?
                                                    custom.status
                                                    // : <SelectBox custom={custom}
                                                    //              item={item}
                                                    //              reloadInbox={this.reloadInbox}
                                                    //              clientData={this.props.clientData} location={this.props.location}/>
                                            }
                                        </td>
                                        <td>{month}-{date}-{year}</td>
                                        <td>
                                            <button className='btn btn-sm btn-secondary' onClick={() => this.handleDownload(item)}>
                                                Download
                                            </button>
                                            {isClient && 
                                                <LaddaButton loading={item.deleting}
                                                    onClick={() => this.handleDelete(item)}
                                                    data-style={ZOOM_OUT}
                                                    className='btn btn-sm btn-danger ml-1'
                                                >
                                                    Delete
                                                </LaddaButton>}
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
