import React, { Component, Fragment } from "react";
import "../../../assests/styles/topNav/top-bar.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { Baseurl } from "../../../utils/Baseurl";
import LaddaButton, { EXPAND_RIGHT } from "react-ladda";
import { LinkWithTooltip } from "../../../utils/Tooltip";

export default class TopNav extends Component {
	state = {
		showClientModal: false,
		showAccModal: false,
		company_name: "",
		clientType: "Business",
		accountantName: "",
		business_client: "",
		personal_client: "",
		staff: "",
		first_name: "",
		last_name: "",
	};

	createClient(client) {
		return axios({
			method: "post",
			url: Baseurl + "api/client/register",
			headers: {
				"Content-Type": "application/json",
			},
			data: client,
		});
	}

	handleChange = (e) => {
		e.target.name === "clientType"
			? this.setState({
					[e.target.name]: e.target.value,
					first_name: "",
					last_name: "",
			  })
			: this.setState({ [e.target.name]: e.target.value });
	};

	toggleModal = (modal) => {
		modal !== null
			? this.setState({ [modal]: !this.state[modal] })
			: this.setState({
					showClientModal: false,
					showAccModal: false,
					accountantName: "",
					business_client: "",
					personal_client: "",
					staff: "",
					first_name: "",
					last_name: "",
					company_name: "",
			  });
		// props toggleModal called to fade main page
		this.props.toggleModal();
	};

	handleAddClient = () => {
		this.setState({ isLoading: true });

		const { company_name, clientType, first_name, last_name } = this.state;

		if (
			company_name.length > 1 ||
			(first_name.length > 1 && last_name.length > 1)
		) {
			const pathId = this.props.location.pathname.split("/")[2];

			const client = {
				primary_email: "na",
				business_type: "na",
				secondary_first_name: "na",
				secondary_last_name: "na",
				secondary_phone: "na",
				access: "na",
				contactType: clientType === "Business" ? "Business" : "Self",
				primaryContact: "na",
				primaryContactRelation: "na",
				primaryContactOtherRelation: "na",
				parent: "Y",
				parentId: pathId,
				clientType,
				clientName:
					clientType === "Business"
						? company_name
						: first_name + "&" + last_name,
				scanType: "Other",
				projects: JSON.stringify([]),
			};

			this.createClient(client)
				.then((res) => {
					const id = res.data.data.clientId;
					this.props.reload();

					this.setState({
						isLoading: false,
						first_name: "",
						last_name: "",
						company_name: "",
					});
					this.toggleModal(null);
					this.props.history.push(`/client/${pathId}/${id}`);
				})
				.catch((error) => {
					const { data } = error.response;
					if (data.code === "AddClientLimitExceeded") {
						alert(data.message);
					} else {
						console.log("Add client error", error.response);
					}
					this.setState({ isLoading: false });
				});
		} else {
			alert("Input fields should not be blank.");
			this.setState({ isLoading: false });
		}
	};

	handleDocumentRoute = (accountantId) => {
		// console.log(this.props, accountantId)
		if (accountantId) this.props.history.push(`/document-type/${accountantId}`);
	};

	handleAddAccountant = () => {
		this.setState({ isLoading: true });

		const { accountantName, business_client, personal_client, staff } =
			this.state;

		const accountant = {
			email: "na",
			first_name: "na",
			last_name: "na",
			company_name: accountantName,
			business_client: business_client,
			personal_client: personal_client,
			staff: staff,
			phone: "na",
			active: "N",
		};
		// console.log(business_client);

		if (accountantName.length > 1) {
			axios({
				method: "post",
				url: Baseurl + "api/accountant/register",
				headers: {
					"Content-Type": "application/json",
				},
				data: accountant,
			})
				.then((res) => {
					const id = res.data.data.accountantId;
					console.log("added Accountant", id);
					this.props.reload();

					this.setState({
						isLoading: false,
						accountantName: "",
						business_client: "",
						personal_client: "",
						staff: "",
					});
					this.toggleModal(null);
					this.props.history.push(`/accountant/${id}`);
				})
				.catch((error) => {
					console.log("Add client error", error);
					this.setState({ isLoading: false });
				});
		}
	};

	render() {
		const {
			isAccountant,
			location,
			isClient,
			isStaff,
			clientData,
			accountantData,
			user,
		} = this.props;
		const [, route, accountantId, clientId] = location.pathname.split("/");
		// console.log(user, "this.props");
		const clientRouteAllowed =
			route.toLowerCase() === "document" && accountantId && clientId;
		const documentRoute =
			route.toLowerCase() === "client" ? `${accountantId}/${clientId}` : "";
		const clientRoute = clientRouteAllowed
			? `client/${accountantId}/${clientId}`
			: `document/${documentRoute}`;
		const displayPic =
			isClient && clientData.length
				? clientData[0].accountantDetails.displayPicURL
				: "";
		// console.log(clientData);

		return (
			<Fragment>
				<div className="cui-topbar">
					<div className="cui-topbar-left">
						<div className="cui-topbar-item">
							{!isClient ? (
								<Link to={`/${clientRoute}`} className="btn btn-sm btn-primary">
									{clientRouteAllowed ? "Client Details" : "Document Manager"}
								</Link>
							) : (
								<div
									className="cui-menu-left-logo"
									style={{ padding: "0 0 0 13px", background: "none" }}
								>
									<Link to={`/${clientRoute}`}>
										<img src={displayPic} alt="Ray" style={{ height: 64 }} />
									</Link>
								</div>
							)}
						</div>
					</div>
					<div className="cui-topbar-right">
						{user?.email && (
							<>
								<LinkWithTooltip tooltip={"Messages"} id={"tooltip-1"}>
									<a
										target="_blank"
										href={`http://www.tbavirtual.ca/frmmessenger.aspx?email=${user.email}`}
										className="cui-topbar-item"
									>
										{/* Messages/ */}
										<img
											alt="message"
											src="https://storage.googleapis.com/ray-business-f2291/logo/chat.png"
											style={{ height: "28px" }}
										></img>
									</a>
								</LinkWithTooltip>
								{/* <button
									className="cui-topbar-item btn btn-sm btn-primary"
									onClick={() => this.handleChat(accountantData)}
								>
									Chat
								</button> */}

								<LinkWithTooltip tooltip={"Payrite"} id={"tooltip-2"}>
									<a
										target="_blank"
										href={`https://payrite.ca/home.aspx?email=${user.email}`}
										className="cui-topbar-item"
									>
										<img
											alt="payrite"
											src="https://storage.googleapis.com/ray-business-f2291/logo/payrite.png"
											style={{ height: "28px" }}
										></img>
									</a>
								</LinkWithTooltip>
								<LinkWithTooltip tooltip={"Sign Assist"} id={"tooltip-3"}>
									<a
										target="_blank"
										href={`https://signassist.ca/frmlogin.aspx?email=${user.email}`}
										className="cui-topbar-item"
										data-tooltip-id="signassist-tooltip" data-tooltip-content="Sign Assist"
									>
										<img
											alt="signassist"
											src="https://storage.googleapis.com/ray-business-f2291/logo/signassist.png"
											style={{ height: "28px" }}
										></img>
									</a>
								</LinkWithTooltip>
							</>
						)}
						{/* <Fragment>
                            {(isAccountant || hasAcc) && !isClient && <Link to={`/document-type/${accountantIdCheck}`} className='cui-topbar-item btn btn-sm btn-primary'>Document Type</Link>}
                           {!isClient && <button className='cui-topbar-item btn btn-sm btn-primary'
                                onClick={() => this.toggleModal('showAccModal')}>Add Accountant</button>}
                        </Fragment> */}

						{
							// !isAccountant &&
							!isClient && (
								<Fragment>
									{/* <Link
                                        to="/document-type"
                                        className="cui-topbar-item btn btn-sm btn-primary"
                                        disabled={!hasAcc}
                                        >
                                        Document Type
                                    </Link> */}
									{accountantData.length > 1 ? (
										<button
											className="cui-topbar-item btn btn-sm btn-primary"
											disabled={!accountantId && !isAccountant}
											onClick={() => this.handleDocumentRoute(accountantId)}
										>
											Document Type
										</button>
									) : (
										<button
											className="cui-topbar-item btn btn-sm btn-primary"
											onClick={() =>
												this.handleDocumentRoute(
													accountantData[0]?.accountantId
												)
											}
										>
											Document Type
										</button>
									)}
									{!isAccountant && (
										<>
											<Link
												to="/year"
												className="cui-topbar-item btn btn-sm btn-primary"
											>
												Year List
											</Link>

											<button
												className="cui-topbar-item btn btn-sm btn-primary"
												onClick={() => this.toggleModal("showAccModal")}
											>
												Add Accountant
											</button>
										</>
									)}
								</Fragment>
							)
						}

						{!isClient && (
							<>
								<Link to="/" className="cui-topbar-item btn btn-sm btn-primary">
									Notifications
								</Link>
								<Link
									to="/referral"
									className="cui-topbar-item btn btn-sm btn-primary"
								>
									Referrals
								</Link>
								<button
									className="cui-topbar-item btn btn-sm btn-primary"
									// disabled={!hasAcc}
									disabled={!clientId && !accountantId}
									onClick={() =>
										this.props.history.push(`/bulk/${accountantId}`)
									}
								>
									Bulk Import
								</button>
								<button
									className="cui-topbar-item btn btn-sm btn-primary"
									// disabled={!hasAcc}
									disabled={!clientId && !accountantId}
									onClick={() => this.toggleModal("showClientModal")}
								>
									Add Client
								</button>
							</>
						)}
						{!isClient && !isStaff && (
							<>
								{accountantData.length > 1 ? (
									<button
										className="cui-topbar-item btn btn-sm btn-primary"
										// disabled={!hasAcc}
										disabled={!clientId && !accountantId && !isAccountant}
										onClick={() =>
											this.props.history.push(`/staff/${accountantId}`)
										}
									>
										Manage Staff
									</button>
								) : (
									<button
										className="cui-topbar-item btn btn-sm btn-primary"
										// disabled={!hasAcc}
										onClick={() =>
											this.props.history.push(
												`/staff/${accountantData[0]?.accountantId}`
											)
										}
									>
										Manage Staff
									</button>
								)}
							</>
						)}
						{isClient && (
							<Fragment>
								<Link
									to={`/document/${clientData[0].clientId}`}
									className="cui-topbar-item btn btn-sm btn-primary"
								>
									Document Manager
								</Link>
								<Link
									to="/notification"
									className="cui-topbar-item btn btn-sm btn-primary"
								>
									Notification
								</Link>
								<Link
									to="/referral"
									className="cui-topbar-item btn btn-sm btn-primary"
								>
									Add Referrals
								</Link>
							</Fragment>
						)}

						<button
							className="cui-topbar-item btn btn-sm btn-warning"
							onClick={() => {
								this.props.handleLogout();
								this.props.history.push("/");
							}}
						>
							Logout
						</button>
					</div>
				</div>
				{this.renderModal()}
			</Fragment>
		);
	}

	renderModal() {
		const { showClientModal, showAccModal, clientType } = this.state;
		const [block, showModal] =
			showClientModal || showAccModal ? ["block", "show"] : ["", ""];

		return (
			<div
				className={`modal fade modal-size-small ${showModal}`}
				style={{ display: `${block}` }}
			>
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">
								Add {showClientModal ? "Client" : "Accountant"} Name
							</h5>
							<button className="close" onClick={() => this.toggleModal(null)}>
								<span aria-hidden="true">×</span>
							</button>
						</div>
						<div className="modal-body">
							{showClientModal && (
								<div className="row">
									<label className="col-md-3 col-form-label">Type: </label>
									<div className="col-md-9">
										<select
											value={clientType}
											onChange={this.handleChange}
											name="clientType"
											className="form-control mb-2"
											style={{ paddingTop: 5, paddingBottom: 5 }}
										>
											<option value="Business">Business</option>
											<option value="Individual">Personal</option>
										</select>
									</div>
								</div>
							)}

							{showClientModal ? (
								clientType === "Business" ? (
									<input
										type="text"
										className="form-control"
										placeholder="Client Name"
										name="company_name"
										value={this.state.company_name}
										onChange={this.handleChange}
									/>
								) : (
									<Fragment>
										<input
											type="text"
											className="form-control mb-2"
											placeholder="Client First Name"
											name="first_name"
											value={this.state.first_name}
											onChange={this.handleChange}
										/>
										<input
											type="text"
											className="form-control"
											placeholder="Client Last Name"
											name="last_name"
											value={this.state.last_name}
											onChange={this.handleChange}
										/>
									</Fragment>
								)
							) : (
								<Fragment>
									<input
										type="text"
										className="form-control"
										placeholder="Accountant Name"
										name="accountantName"
										value={this.state.accountantName}
										onChange={this.handleChange}
									/>

									<input
										type="number"
										className="form-control"
										placeholder="Business Client"
										name="business_client"
										value={this.state.business_client}
										onChange={this.handleChange}
										style={{ marginTop: "10px" }}
									/>
									<input
										type="number"
										className="form-control"
										placeholder="Personal Client"
										name="personal_client"
										value={this.state.personal_client}
										onChange={this.handleChange}
										style={{ marginTop: "10px" }}
									/>
									<input
										type="number"
										className="form-control"
										placeholder="Staff"
										name="staff"
										value={this.state.staff}
										onChange={this.handleChange}
										style={{ marginTop: "10px" }}
									/>
								</Fragment>
							)}
						</div>
						<div className="modal-footer">
							<button
								className="btn btn-sm"
								onClick={() => this.toggleModal(null)}
								disabled={this.state.isLoading}
							>
								Cancel
							</button>

							<LaddaButton
								loading={this.state.isLoading}
								onClick={
									showClientModal
										? this.handleAddClient
										: this.handleAddAccountant
								}
								data-style={EXPAND_RIGHT}
								className="btn btn-sm btn-primary"
							>
								Save
							</LaddaButton>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
