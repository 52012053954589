import React, {Component, Fragment} from 'react';
import { Switch, Route} from 'react-router-dom';
import {TextSpinner} from "./assests/loader/Spinner";
import NotFound from "./UnAuthenticated/ErrorPage/NotFound";
import UnAuthenticatedHome from "./UnAuthenticated/UnAuthenticatedHome";
import Login from "./UnAuthenticated/AuthPages/Login";
import AuthenticatedHome from "./Authenticated/AuthenticatedHome";
import ForgotPassword from "./UnAuthenticated/AuthPages/ForgotPassword";
import firebaseApp from "./utils/Fire";
import "../src/assests/styles/feather/feather.css";
import "../src/assests/styles/custom/custom.css";


export default class App extends Component {
    constructor(props){
        super(props);
        this.state = {
            isAuthenticated: false,
            isAuthenticating: true,
            user: {
                attributes: {
                    name: '',
                    email: ''
                }
            }
        }
    }

    componentDidMount(){
        const self = this;
        firebaseApp.auth().onAuthStateChanged(function(user) {
            if (user) {
                self.setState({isAuthenticated: true, isAuthenticating: false});
            } else {
                console.log('no user', user);
                self.setState({isAuthenticated: false , isAuthenticating: false})
            }
        });
    }


    handleLogout = async event => {
        this.setState({isAuthenticating: true});
        try {
            await firebaseApp.auth().signOut();
            this.setState({isAuthenticating: false});
        } catch (e) {
            console.log(e);
            this.setState({isAuthenticating: false});
        }
    };

    render() {
        return (
            this.state.isAuthenticating
                ? <TextSpinner/>
                :
                <Fragment>
                    { this.state.isAuthenticated
                        ?
                        <Switch>
                            <Route render={(props) => <AuthenticatedHome {...props}
                                                                         handleLogout={this.handleLogout}
                                                                         // user={this.state.user}
                            />}/>
                        </Switch>
                        :
                        <div className='cui-layout'>
                            <div className='cui-layout-content'>
                                <div className='cui-utils-content'>
                                    <Switch>
                                        <Route exact path="/"
                                               render={(props) => <UnAuthenticatedHome {...props}
                                                                                       isAuthenticated={this.state.isAuthenticated}
                                               />}/>
                                        <Route path="/login"
                                               render={(props) => <Login {...props}
                                                                         userHasAuthenticated={this.userHasAuthenticated}
                                               />}/>
                                        <Route path="/forgotpassword"
                                               render={(props) => <ForgotPassword {...props}/>}
                                        />
                                        { /* Finally, catch all unmatched routes */ }
                                        <Route component={NotFound} />
                                    </Switch>
                                </div>
                            </div>
                        </div>
                    }
                </Fragment>
        );
    }
}
