import React, { Component } from 'react';
// import {Link} from 'react-router-dom';
import LaddaButton, { EXPAND_RIGHT } from 'react-ladda';
import { validateEmail } from "../../utils/FormValidater";
import firebaseApp from '../../utils/Fire';

import '../../assests/styles/pages/login.css';
import CommonHeader from "./CommonHeader";
// import CommonFooter from "./CommonFooter";
import TopAlert from "../../utils/TopAlert";

import 'firebase/auth';
import { getClientByEmail } from '../../utils/API';

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '', emailError: false,
            password: '', passwordError: false,
            showAlertBox: false, active: 'Y'
        };
    }

    validateForm() {
        const { email, password } = this.state;
        !validateEmail(email) && this.setState({ emailError: true });
        password.length < 6 && this.setState({ passwordError: true });

        return validateEmail(email) && password.length > 5;
    };

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
        event.target.name === 'email' && this.setState({ emailError: false, notAuthorized: false, userNotExist: false });
        event.target.name === 'password' && this.setState({ passwordError: false, notAuthorized: false });
    };

    handleSubmit = async (e) => {
        e.preventDefault();

        const { email, password } = this.state;

        this.setState({ isLoading: true });

        if (this.validateForm()) {
            try {
                                       
                   await firebaseApp.auth().signInWithEmailAndPassword(email, password);
                    // this.props.userHasAuthenticated(true);
                   this.props.history.push('/')
              
                
            } catch (e) {
                e.code === 'auth/wrong-password' && this.setState({ notAuthorized: true });
                e.code === 'auth/user-not-found' && this.setState({ userNotExist: true });
                console.log('login error', e.code);

                this.setState({ isLoading: false });
            }
        } else {
            this.setState({ isLoading: false });
            return false
        }
    };

    render() {
        const { emailError, userNotExist, passwordError, notAuthorized } = this.state;
        return (
            <div className="cui-login cui-login-fullscreen">
                <CommonHeader />
                <div className="cui-login-block-inner" style={{ marginTop: 50 }}>
                    <div className="cui-login-block-form">
                        <h3 className="title">Log in to your account</h3>
                        <br />
                        <form id="form-validation" name="form-validation" onSubmit={this.handleSubmit}>
                            <div className={emailError || userNotExist ? 'form-group has-danger' : 'form-group'}>
                                <label className="form-label">Email</label>
                                <input
                                    id="validation-email"
                                    className={emailError || userNotExist ? 'form-control has-danger' : 'form-control'}
                                    placeholder="Email Address"
                                    name="email"
                                    type="text"
                                    data-validation="[EMAIL]"
                                    onChange={this.handleChange}
                                />
                                {emailError && <p className='type--fine-print error-text'>* A valid email address is needed.</p>}
                                {userNotExist && <p className='type--fine-print error-text'>* This user does not exist.</p>}
                            </div>
                            <div className={passwordError ? 'form-group has-danger' : 'form-group'}>
                                <label className="form-label">Password</label>
                                <input
                                    id="validation-password"
                                    className={passwordError ? 'form-control password has-danger' : 'form-control password'}
                                    name="password"
                                    type="password"
                                    data-validation="[L>=6]"
                                    data-validation-message="$ must be at least 6 characters"
                                    placeholder="Password"
                                    onChange={this.handleChange}
                                />
                                {passwordError
                                    && <p className='type--fine-print error-text'>* Password must be of at least 6 characters.</p>
                                }
                            </div>
                            {notAuthorized && this.renderErrorBox()}
                            <div className="form-group">
                                {/*<Link to="/forgotpassword"*/}
                                {/*className="pull-right cui-utils-link-blue cui-utils-link-underlined" style={{float: 'right'}}>*/}
                                {/*Forgot Password?*/}
                                {/*</Link>*/}
                                <div className="checkbox">
                                    <label>
                                        <input type="checkbox"
                                            style={{ marginRight: 5 }}
                                            name="example6"
                                            defaultChecked={true} />
                                        Remember me
                                    </label>
                                </div>
                            </div>
                            <div className="form-actions">
                                <LaddaButton loading={this.state.isLoading}
                                    onClick={this.handleSubmit}
                                    data-style={EXPAND_RIGHT}
                                    className='btn btn-info mr-3'
                                >
                                    Log In
                                </LaddaButton>
                            </div>
                        </form>
                    </div>
                </div>
                {/*<CommonFooter/>*/}
                {/* e={this.state.topAlert} close={() => this.setState({ topAlert: null })} */}
                {this.state.active=='N' && <TopAlert e={{message:'Your Account is not active. Please contact admin ',type:'alert-warning'}} close={() => this.setState({ active: null })}/>}
            </div>
        )
    }

    renderErrorBox() {
        return (
            <div className="alert alert-warning" role="alert" style={{ lineHeight: 1.2 }}>
                <button type="button" className="close" data-dismiss="alert"
                    aria-label="Close"
                    onClick={() => this.setState({ notAuthorized: false })}>
                    <span aria-hidden="true">&times;</span>
                </button>
                <a href="/" className="alert-link" onClick={e => e.preventDefault()}>
                    The username and password you entered did not match our records. Please double-check and try again.
                </a>
            </div>
        )
    }
}
