import firebase from 'firebase';

const config = {
    apiKey: "AIzaSyDhQKlvi8Vni4aT3VmfITCIL-Kl35OIbNg",
    authDomain: "ray-business-f2291.firebaseapp.com",
    databaseURL: "https://ray-business-f2291.firebaseio.com",
    projectId: "ray-business-f2291",
    storageBucket: "ray-business-f2291",
    messagingSenderId: "942232276000",
    appId: "1:942232276000:web:5d1248bc02536362e40045"
};

const firebaseApp = firebase.initializeApp(config);

export const secondAuth = firebase.initializeApp(config, 'Secondary');

export default firebaseApp;
