import React, { Component, Fragment } from 'react';


export default class StaffAccess extends Component {
    state = {
        clientsAccess: []
    };

    parseAccess = (jsonData, type = 1) => {
        try {
            return JSON.parse(jsonData);
        } catch (error) {
            if (type === 1) {
                return {
                    All: 'N', T1: 'N', Payrite: 'N', IScan: 'N',
                    Xero: 'N', QBO: 'N', Calculator: 'N'
                };
            } else {
                return {
                    All: 'N', FS: 'N', PD: 'N', T2: 'N', T3: 'N', T4: 'N', T4A: 'N', T5: 'N', T5018: 'N', Payrite: 'N', IScan: 'N',
                    Xero: 'N', QBO: 'N', Calculator: 'N'
                };
            }
        }
    }

    componentDidMount() {
        const { clients, staffAccessData } = this.props;
        // console.log(clients, staffAccessData);

        // const defaultAccess = {
        //     All: 'N', FS: 'N', PD: 'N', T1: 'N', T2: 'N', T3: 'N', T4: 'N', T4A: 'N', T5: 'N', T5018: 'N', Payrite: 'N', IScan: 'N',
        //     Xero: 'N', QBO: 'N', Calculator: 'N'
        // };
        // console.log(clients);

        if (staffAccessData.length) {
            // console.log('clients',clients,staffAccessData)
            const newData = clients.map(client => {
                const hasClient = staffAccessData.filter(value => value.clientId === client.clientId);
                if (hasClient.length) {
                    return hasClient[0]
                } else {
                    return {
                        allowed: false,
                        clientId: client.clientId,
                        clientName: client.clientName,
                        clientType: client.clientType,
                        staffAccess: JSON.stringify(this.parseAccess(client.access, (client.clientType === 'Business' ? 2 : 1)))
                        // JSON.stringify used above because all the access object were changing
                    }
                }
            });

            this.setState({ clientsAccess: newData })
        } else {
            const clientsAccess = clients.map((c, i) => {
                return {
                    clientId: c.clientId,
                    clientName: c.clientName,
                    clientType: c.clientType,
                    staffAccess: JSON.stringify(this.parseAccess(c.access, (c.clientType === 'Business' ? 2 : 1)))
                    // JSON.stringify used above because all the access object were changing
                };
            });

            this.setState({ clientsAccess });
        }
    }

    handleClick = (key, client) => {
        const { clientsAccess } = this.state;

        clientsAccess.map(c => {
            if (c.clientId === client.clientId) {
                const staffAccess = JSON.parse(c.staffAccess);

                if (key === 'All') {
                    staffAccess.All = staffAccess.All === 'N' ? 'Y' : 'N';

                    Object.keys(staffAccess).map((accessKey, i) => {
                        staffAccess[accessKey] = staffAccess.All;
                        return null
                    });
                    c.staffAccess = JSON.stringify(staffAccess);
                    this.setState({ clientsAccess });
                } else {
                    staffAccess.All = 'N';
                    staffAccess[key] = staffAccess[key] === 'N' ? 'Y' : 'N';
                    c.staffAccess = JSON.stringify(staffAccess);
                    this.setState({ clientsAccess });
                }
            }
            return null
        });

        this.props.setStaffAccess(clientsAccess)
    };

    active = (key, accessList) => {
        return accessList[key] === 'Y' ? 'btn btn-sm btn-success' : 'btn btn-sm btn-default'
    };

    handleCheckbox = (client) => {
        const { clientsAccess } = this.state;
        this.state.clientsAccess.map(c => {
            if (client.clientId === c.clientId) {
                c.allowed = !c.allowed
            }
            return null
        });
        this.setState({ clientsAccess });
        this.props.setStaffAccess(clientsAccess);
    };

    handleSelect = (type) => {
        const { clientsAccess } = this.state;
        this.state.clientsAccess.map(c => {
            if (type === "select") {
                c.allowed = true  
            } else {
                c.allowed = false  
            }
            return null
        });
        this.setState({ clientsAccess });
        this.props.setStaffAccess(clientsAccess);
    };

    render() {
        const { clientsAccess } = this.state;

        return (
            <>
                <div className='row'>
                    <div className='col-md-8'>
                        <button className='btn btn-sm btn-outline-primary mr-2' onClick={() => this.handleSelect('select')}>Select All Clients</button>
                        <button className='btn btn-sm btn-outline-warning' onClick={this.handleSelect}>De-Select All Clients</button>
                    </div>
                </div>
                <hr />
                <div className='row'>
                {clientsAccess.map((client, idx) => {
                    const [firstName, lastName] = client.clientName.split('&');
                    const clientName = client.clientType === 'Business' ? client.clientName : `${firstName} ${lastName}`;
                    return (
                        <Fragment key={idx}>
                            <div className='col-md-3'>
                                <label className='cui-utils-control cui-utils-control-checkbox'>
                                    {clientName}
                                    <input type='checkbox' onChange={() => this.handleCheckbox(client)} checked={client.allowed} />
                                    <span className='cui-utils-control-indicator' />
                                </label>
                            </div>
                            <div className='col-md-9'>
                                <div className='row'>
                                    {
                                        Object.keys(JSON.parse(client.staffAccess)).map((accessKey, i) => {
                                            return (
                                                <button key={i}
                                                    className={`${this.active(accessKey, JSON.parse(client.staffAccess))} mr-2 mb-2`}
                                                    disabled={!this.props.edit}
                                                    onClick={() => this.handleClick(accessKey, client)}>
                                                    {accessKey}
                                                </button>
                                            )
                                        })
                                    }
                                </div>
                                <hr />
                            </div>
                        </Fragment>
                    )
                })
                }
                </div>
                </>
        )
    }
}
