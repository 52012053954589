import React, {Component} from 'react';
import firebaseApp from "../../../../utils/Fire";
// import MoveModal from "./MoveModal";
import LaddaButton, {EXPAND_RIGHT} from "react-ladda";
import {uploadToOneDrive} from "../../../../utils/API";


export default class UploadModalClient extends Component {
    state = {
        structure: 'Single',
        docType: '',
        docTypes: [],
        files: [],
        showModal: false,
        selectedItem: {},
        years: [],
        year: '2019',
        month: 'Jan',
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May','Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    };

    componentDidMount(){
        const {scanType, docTypes, projects, years} = this.props;
        // console.log("1++ = ",this.props);
        console.log("scanType = ",scanType);

        // const filterDocTypes = () => {
        //     return scanType === 'Project'
        //         ? docTypes.project
        //         : scanType === 'CalendarY' || scanType === 'CalendarM'
        //             ? docTypes.calendar
        //             : [{id: 0, name: 'Other', icon: ''}]
        // };
        
        this.setState({
            // docTypes: filterDocTypes(),
            docTypes,
            // docType: filterDocTypes()[0].name,
            docType: docTypes?.[0]?.name,
            scanType,
            projects,
            projectName: scanType === 'Project' && projects?.length ? projects?.[0]?.name : 'none',
            years,
            year: String(new Date().getFullYear())
        });
    }

    handleRadioClick = (state) => {
        this.setState({structure: state})
    };

    handleChange = e => {
        this.setState({[e.target.name]: e.target.value})
    };

    handleFiles = e => {
        const {files} = this.state;

        // console.log(e.target.files);

        Object.keys(e.target.files).map(key => {
            // files.push(e.target.files[key]);
            let ext = e.target.files[key].name.split(".").pop();
            if (ext === "pdf") {
                files.push(e.target.files[key]);
            }
            return null
        });
        this.setState({files});
    };

    handleUpload = e => {
        const {client, user, scanType} = this.props;

        this.setState({isLoading: true});

        const [firstName, lastName] = client[0].clientName.split('&');
        const clientName = client[0].clientType === 'Business' ? client[0].clientName 
            : lastName.length > 0 ? `${lastName}, ${firstName}` : firstName

        const storageRef = firebaseApp.storage().ref();
        const newPromise = this.state.files.map(file => {
            return new Promise((resolve, reject) => {

                const metadata = {
                    customMetadata: {
                        user: user.last_name + ', ' + user.first_name,
                        type: this.state.docType,
                        structure: this.state.structure,
                        scanType: scanType,
                        projectName: this.state.projectName,
                        status: 'Pending',
                        clientName, 
                        clientType: client[0].clientType,
                        year: this.state.year,
                        month: this.state.month
                    }
                };
                // resolve(metadata)
                const userFileRef = storageRef.child(`${client[0].clientId}/inbox/${file.name}`);

                userFileRef.put(file, metadata)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        console.log(`Error uploading to storage: ${file.name}`, err);
                        reject(`Error uploading to storage: ${file.name}`)
                    })
            })
        });

        Promise.all(newPromise)
            .then(res => {                
                const fullPathArr = [];
                res.map(fileMeta => {
                    fullPathArr.push(fileMeta.metadata.fullPath);
                    return null
                });

                // console.log(res, fullPathArr)
                uploadToOneDrive({fullPathArr})
                    .then(response => {
                        console.log('Uploaded to oneDrive: ', response);
                    })
                    .catch(error => {
                        console.log(`Error uploading to oneDrive: `, error);
                    });
                this.setState({isLoading: false, files: []});   
                this.props.toggleModal();
            })
            .catch(err => console.log('Promise err: ', err))
    };

    render() {
        const { files, isLoading, docType, docTypes, scanType, projects, projectName, year, years, month, months} = this.state;
        const client = this.props.client[0];
        // console.log("===== ",this.props.client,this.props.docTypes)

        return (
            <div className={`modal fade show`} style={{display: 'block'}}>
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Upload Files</h5>
                            <button className="close" onClick={this.props.toggleModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className='modal-body'>
                            <div className='col-lg-12'>
                                {/* <div className='mb-4' style={{display: 'flex'}}>
                                    <label className='cui-utils-control cui-utils-control-radio mr-5'>
                                        <strong>Single Item</strong>
                                        <input type='radio'
                                               name='uploadRadio'
                                               checked={structure === 'Single'}
                                               onChange={() => this.handleRadioClick('Single')}/>
                                        <span className='cui-utils-control-indicator'/>
                                    </label>
                                    <label className='cui-utils-control cui-utils-control-radio mr-5'>
                                        <strong>More Items</strong>
                                        <input type='radio'
                                               name='uploadRadio'
                                               checked={structure === 'More'}
                                               onChange={() => this.handleRadioClick('More')}/>
                                        <span className='cui-utils-control-indicator'/>
                                    </label>
                                    <label className='cui-utils-control cui-utils-control-radio'>
                                        <strong>Combine Items</strong>
                                        <input type='radio'
                                               name='uploadRadio'
                                               checked={structure === 'Combine'}
                                               onChange={() => this.handleRadioClick('Combine')}/>
                                        <span className='cui-utils-control-indicator'/>
                                    </label>
                                </div> */}
                                {
                                    scanType === 'Project' &&
                                    <div className='row'>
                                        <label className='col-md-4 col-form-label'>Project:</label>
                                        <div className='col-md-8 mb-2'>
                                            <select className='form-control' name='projectName' onChange={this.handleChange}
                                                    value={projectName}>
                                                {
                                                    projects.map((p, i) => {
                                                        return <option key={i} value={p.name}>{p.name}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                }
                                {
                                    scanType === 'CalendarY' &&
                                    <div className='row'>
                                        <label className='col-md-4 col-form-label'>Year:</label>
                                        <div className='col-md-8 mb-2'>
                                            <select className='form-control' name='year' onChange={this.handleChange}
                                                    value={year}>
                                                {
                                                    years.map((y, i) => {
                                                        return <option key={i} value={y}>{y}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                }
                                {
                                    scanType === 'CalendarM' &&
                                    <div className='row'>
                                        <label className='col-md-4 col-form-label'>Year and Month:</label>
                                        <div className='col-md-8 mb-2 row'>
                                            <div className='col-md-6'>
                                                <select className='form-control' name='year' onChange={this.handleChange}
                                                        value={year}>
                                                    {
                                                        years.map((y, i) => {
                                                            return <option key={i} value={y}>{y}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className='col-md-6'>
                                                <select className='form-control' name='month' onChange={this.handleChange}
                                                        value={month}>
                                                    {
                                                        months.map((m, i) => {
                                                            return <option key={i} value={m}>{m}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    client && client.clientType === 'Business' &&
                                        <div className='row'>
                                            <label className='col-md-4 col-form-label'>Document Type: </label>
                                            <div className='col-md-8 mb-4' >
                                                <select className='form-control' value={docType} onChange={this.handleChange} name='docType'>
                                                    {docTypes.map((d, i) => {
                                                        return <option key={i} value={d.name}>{d.name}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                }
                                
                                <div className="dropify-wrapper">
                                    <div className="dropify-message">
                                        <span className="file-icon"/>
                                        <p>Drag and drop a file here or click to upload</p>
                                        <p className="dropify-error">Ooops, something wrong appended.</p>
                                    </div>
                                    <div className="dropify-loader" style={{display: 'none'}}/>

                                    <input type="file" accept='.pdf' className="dropify" multiple={true} disabled={isLoading}
                                           onChange={this.handleFiles}/>
                                    <button type="button" className="dropify-clear">Remove</button>
                                    <div className="dropify-preview" style={{display: 'none'}}>
                                        <span className="dropify-render"/>
                                        <div className="dropify-infos">
                                            <div className="dropify-infos-inner">
                                                <p className="dropify-filename">
                                                    <span className="file-icon"/>
                                                    <span className="dropify-filename-inner"/>
                                                </p>
                                                <p className="dropify-infos-message">Drag and drop or click to
                                                    replace</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    files.length > 0 &&
                                    <div className='table-responsive' style={{maxHeight: 185}}>
                                        <table className='table table-hover'>
                                            <thead>
                                            <tr>
                                                <th>File Name (Only PDF accepted)</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                files.map((item, idx) => {
                                                    return (
                                                        <tr key={idx}>
                                                            <td>{item.name}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='modal-footer'>
                            {files.length > 0 &&
                                <LaddaButton loading={isLoading}
                                             onClick={this.handleUpload}
                                             data-style={EXPAND_RIGHT}
                                             className='btn btn-sm btn-primary'
                                >
                                    {isLoading ? 'Uploading..' : 'Upload'}
                                </LaddaButton>
                            }
                            <button className='btn btn-sm'
                                    onClick={this.props.toggleModal}
                                    disabled={this.state.isLoading}>Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
