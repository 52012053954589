import React, { Component } from "react";
import { getDocType, updateDocType, addDocType } from "../../../utils/API";

export default class DocumentType extends Component {
    state = {
        calendarName: "",
        projectName: "",
        calendar: [],
        project: [],
        years: [],
        accountantId: "",
        addApi: false,
        errorIs: {
            calendarName: "",
            projectName: "",
        },
    };

    componentDidMount() {
        // console.log(this.props);
        const { location } = this.props;
        const [, route, accountantId] = location.pathname.split("/");
        // console.log('1v = ',route, accountantId);
        // console.log(route);
    //  console.log(accountantId);
        const id = route === "document-type" ? accountantId ? accountantId : '' : '';
        if (id) {
            getDocType(id)
            .then((res) => {
                // console.log("res", res.data);
                // console.log("addApi", res.data == null ? true : false);
                const data = res?.data ? res.data : [];
                let calendar = data?.calendar ? data.calendar : [];
                let project = data?.project ? data.project : [];
                // console.log()
        
                    this.setState({
                        calendar,
                        project,
                        accountantId: id,
                        addApi: res.data == null ? true : false,
                    });
                })
                .catch((err) => {
                    console.log("getDocType error: ", err);
                });
             
                
        }
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        this.validation(e.target.name, e.target.value);
    };

    handleAdd = (e, type) => {
        e.preventDefault();
        if (this.validateForm(type)) {
            const my_array = this.state[type];
            const last_element = my_array.length
                ? my_array[my_array.length - 1]
                : { id: 100 };
            // console.log("my_array = ", my_array);
            // console.log("last_element = ", last_element);

        this.state[type].push({
            id: last_element.id + 1,
            name: this.state[`${type}Name`],
            icon: 'https://storage.googleapis.com/ray-business-f2291/logo/doctypeIcon.png'
        });

            this.setState({ [type]: this.state[type], [`${type}Name`]: "" });
        }
    };

    handleUpdate = () => {
        const { calendar, project, accountantId, addApi } = this.state;
        if (calendar.length > 0 || project.length > 0) {
            if (
                window.confirm(
                    "WARNING. Document type once saved cannot be deleted later."
                )
            ) {
                if (addApi) {
                    console.log("add api");
                    addDocType({ calendar, project, accountantId })
                        .then((res) => console.log(res))
                        .catch((err) => console.log("addDocType error: ", err));
                } else {
                    console.log("update api");
                    updateDocType({ calendar, project, accountantId })
                        .then((res) => console.log(res))
                        .catch((err) => console.log("updateDocType error: ", err));
                }
            }
        } else {
            window.confirm("Please Add Calender Or Project");
        }
    };

    handleDelete = (type, index) => {
        const { calendar, project } = this.state;
        // console.log("delete", calendar, project);
        let deletedArr = [];
        if (type === "calendar") {
            deletedArr = calendar.filter((_, i) => i !== index);
        } else {
            deletedArr = project.filter((_, i) => i !== index);
        }
        // console.log("deletedArr", deletedArr);
        if (type === "calendar") {
            this.setState(
                {
                    calendar: [],
                },
                () => {
                    this.setState({
                        calendar: [...deletedArr],
                    });
                }
            );
        } else {
            this.setState(
                {
                    project: [],
                },
                () => {
                    this.setState({
                        project: [...deletedArr],
                    });
                }
            );
        }
    };

    validation(name, value) {
        const { errorIs } = this.state;
        switch (name) {
            case "calendarName":
                errorIs.calendarName = !value.length ? "Required Field" : "";
                break;
            case "projectName":
                errorIs.projectName = !value.length ? "Required Field" : "";
                break;
            default:
                break;
        }
    }

    validateForm(type) {
        const { errorIs, calendarName, projectName } = this.state;
        // console.log("type", type);
        if (type === "calendar") {
            errorIs.calendarName = !calendarName.length ? "Required Field" : "";
        } else {
            errorIs.projectName = !projectName.length ? "Required Field" : "";
        }
        // console.log("3 ", errorIs);
        this.setState({ errorIs });
        if (type === "calendar") {
            // console.log("4 ", !errorIs.calendarName);
            return !errorIs.calendarName;
        } else {
            // console.log("5 ", !errorIs.projectName);
            return !errorIs.projectName;
        }
    }

    render() {
        const { calendar, project, errorIs } = this.state;
        const { location } = this.props;
        const [/*route,*/ accountantId] = location.pathname.split("/");

        return (
            <>
                {!accountantId ? (
                    <section className="card">
                        <div className="card-header">
                            <span className="cui-utils-title">
                                <strong>Document Type </strong>
                            </span>
                        </div>
                        <div className="card-body">
                            <div className="col-lg-12">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <label className="col-form-label">
                                            <strong>CALENDAR</strong>
                                        </label>
                                        <hr />
                                        {calendar.length > 0
                                            ? calendar.map((c, i) => {
                                                return (
                                                    <div
                                                        key={i}
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            justifyContent: "space-between",
                                                        }}
                                                    >
                                                        <p>
                                                            0{i + 1}. {c.name}
                                                        </p>
                                                        <i
                                                            className="fe fe-minus-circle text-danger plus_icon"
                                                            onClick={() => this.handleDelete("calendar", i)}
                                                        />
                                                    </div>
                                                );
                                            })
                                            : ""}
                                        <div className="input-group width-80p">
                                            <input
                                                type="text"
                                                className={`form-control ${errorIs.calendarName ? `error` : ``
                                                    }`}
                                                name="calendarName"
                                                onChange={this.handleChange}
                                                value={this.state.calendarName}
                                            />

                                            <div className="input-group-append">
                                                <a
                                                    href="/"
                                                    className="btn btn-success btn-sm"
                                                    onClick={(e) => this.handleAdd(e, "calendar")}
                                                >
                                                    Add
                                                </a>
                                            </div>
                                        </div>
                                        <div>
                                            {errorIs.calendarName ? (
                                                <span className="err-msg">{errorIs.calendarName}</span>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <label className="col-form-label">
                                            <strong>PROJECT</strong>
                                        </label>
                                        <hr />
                                        {project.length > 0
                                            ? project.map((c, i) => {
                                                return (
                                                    <div key={i}
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            justifyContent: "space-between",
                                                        }}
                                                    >
                                                        <p >
                                                            0{i + 1}. {c.name}
                                                        </p>
                                                        <i
                                                            className="fe fe-minus-circle text-danger plus_icon"
                                                            onClick={() => this.handleDelete("project", i)}
                                                        />
                                                    </div>
                                                );
                                            })
                                            : ""}
                                        <div className="input-group width-80p">
                                            <input
                                                type="text"
                                                className={`form-control ${errorIs.projectName ? `error` : ``
                                                    }`}
                                                name="projectName"
                                                onChange={this.handleChange}
                                                value={this.state.projectName}
                                            />

                                            <div className="input-group-append">
                                                <a
                                                    href="/"
                                                    className="btn btn-success btn-sm"
                                                    onClick={(e) => this.handleAdd(e, "project")}
                                                >
                                                    Add
                                                </a>
                                            </div>
                                        </div>
                                        <div>
                                            {errorIs.projectName ? (
                                                <span className="err-msg">{errorIs.projectName}</span>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <button
                                className="btn btn-sm btn-primary mr-2"
                                onClick={this.handleUpdate}
                            >
                                Save
                            </button>
                        </div>
                    </section>
                ) : (
                    "aaaa"
                )}
            </>
        );
    }
}


// export default class DocumentType extends Component {
//     state = {
//         calendarName: '',
//         projectName: '',
//         calendar: [],
//         project: [],
//         years: []
//     };

//     componentDidMount() {
//         // console.log(this.props);
//         const { isAccountant, tree, location, isClient, isStaff, clientData } = this.props;
//         const [, route, accountantId] = location.pathname.split('/');
//         // console.log('1v = ',route, accountantId);
//         const id = route == "document-type" ? accountantId ? accountantId : '' : '';
//         getDocType(id)
//             .then(res => {
//                 const data = res.length == undefined ? res.data : res.data[0];
//                 this.setState({
//                     calendar: data.calendar,
//                     project: data.project,
//                     _id: data._id
//                 })
//             })
//             .catch(err => { console.log('getDocType error: ', err) })
//     }

//     handleChange = (e) => {
//         this.setState({ [e.target.name]: e.target.value })
//     };

//     handleAdd = (e, type) => {
//         e.preventDefault();
//         const my_array = this.state[type];
//         const last_element = my_array[my_array.length - 1];

//         this.state[type].push({
//             id: last_element.id + 1,
//             name: this.state[`${type}Name`],
//             icon: 'https://storage.googleapis.com/ray-business-f2291/logo/doctypeIcon.png'
//         });

//         this.setState({ [type]: this.state[type], [`${type}Name`]: '' })
//     };

//     handleUpdate = () => {
//         const { calendar, project, _id } = this.state;
//         if (window.confirm('WARNING. Document type once saved cannot be deleted later.')) {
//             updateDocType({ calendar, project, _id })
//                 .then(res => console.log(res))
//                 .catch(err => console.log('updateDocType error: ', err))
//         }
//     };

//     render() {
//         const { calendar, project } = this.state;

//         return (
//             <section className='card'>
//                 <div className='card-header'>
//                     <span className='cui-utils-title'>
//                         {/* <strong>Document Type (Super Admin Only)</strong> */}
//                         <strong>Document Type</strong>
//                     </span>
//                 </div>
//                 <div className='card-body'>
//                     <div className='col-lg-12'>
//                         <div className='row'>
//                             <div className='col-lg-6'>
//                                 <label className='col-form-label'><strong>CALENDAR</strong></label>
//                                 <hr />
//                                 {
//                                     calendar.map((c, i) => {
//                                         return <p key={i}>0{i + 1}. {c.name}</p>
//                                     })
//                                 }
//                                 <div className='input-group width-80p'>
//                                     <input type='text' className='form-control' name='calendarName'
//                                         onChange={this.handleChange} value={this.state.calendarName} />
//                                     <div className='input-group-append'>
//                                         <a href='/' className='btn btn-success btn-sm' onClick={e => this.handleAdd(e, 'calendar')}>Add</a>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className='col-lg-6'>
//                                 <label className='col-form-label'><strong>PROJECT</strong></label>
//                                 <hr />
//                                 {
//                                     project.map((c, i) => {
//                                         return <p key={i}>0{i + 1}. {c.name}</p>
//                                     })
//                                 }
//                                 <div className='input-group width-80p'>
//                                     <input type='text' className='form-control' name='projectName'
//                                         onChange={this.handleChange} value={this.state.projectName} />
//                                     <div className='input-group-append'>
//                                         <a href='/' className='btn btn-success btn-sm' onClick={e => this.handleAdd(e, 'project')}>Add</a>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <hr />
//                     <button className='btn btn-sm btn-primary mr-2' onClick={this.handleUpdate}>Save</button>
//                 </div>
//             </section>
//         )
//     }
// }
