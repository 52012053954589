import React, {Component} from 'react';
import { Switch, Route} from 'react-router-dom';
import ClientDetails from "./ClientDetails/ClientDetails";


export default class AccountantRoutes extends Component {
    render(){
        return(
            <Switch>
                <Route exact path="/client"
                       render={(props) => <ClientMain {...props}/>}
                />
                <Route exact path="/client/:id"
                       render={(props) => <ClientMain {...props}/>}
                />
                <Route exact path="/client/:id/:id"
                       render={(props) => <ClientDetails {...props}
                                                         // toggleModal={this.props.toggleModal}
                                                         reload={this.props.reload}
                                                         // tree={this.props.tree}
                                                         {...this.props}
                       />}
                />
            </Switch>
        )
    }
}


class ClientMain extends Component {
    componentDidMount(){
        alert("Invalid address");
        this.props.history.push('/')
    }

    render(){
        return(
            <h1>Client Main</h1>
        )
    }
}
