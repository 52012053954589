import React, { Component, Fragment } from 'react';
import AccessClient from "../AccessClient";
// import ReferredClients from "./ReferredClients";
import { EXPAND_RIGHT } from "react-ladda";
import LaddaButton from "react-ladda";
import axios from "axios";
import { Baseurl } from "../../../../../utils/Baseurl";
import { validateEmail, validateName } from "../../../../../utils/FormValidater";
import { secondAuth } from "../../../../../utils/Fire";
import { createUser, getClient, getUser, updateUser, DELETE, getAccountant, getClientByEmail } from "../../../../../utils/API";
// import firebaseApp from "../../../../../utils/Fire";
import ScanType from "./ScanType";
import PrimaryContact from "./PrimaryContact";
import ResetPwd from './ResetPwd';
import { JustSpinner } from '../../../../../assests/loader/Spinner';


export default class Dashboard extends Component {
    state = {
        edit: false,
        deleting: false,
        active: 'N',
        access: {
            All: 'N', Payrite: 'N', IScan: 'N',
            Xero: 'N', QBO: 'N', Calculator: 'N'
        },
        password: '',
        c_password: '',
        // type: 'New',
        show: false,
        clientName: '',
        clientEmail: '',
        clientPhone: '',
        businessType: '',
        first_name: '', last_name: '', company_name: '', phone: '', business_type: '',
        secondary_first_name: '', secondary_last_name: '', secondary_phone: '',
        updatedAt: 'na',
        email: '', primary_email: '',
        clientData: {},
        isNewAccount: false,
        userData: {},
        scanType: 'Other',
        projects: [],
        contactType: 'Self',
        primaryContact: 'na',
        primaryContactRelation: 'na',
        primaryContactOtherRelation: '',
        resetPwd: false,
        allClients: []
    };

    updateClient(data) {
        // console.log(data);
        return axios({
            method: 'post',
            url: Baseurl + 'api/client/updateClient',
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        })
    }

    parseAccess = (jsonData, type = 1) => {
        try {
            return JSON.parse(jsonData);
        } catch (error) {
            if (type === 1) {
                return {
                    All: 'N', T1: 'N', Payrite: 'N', IScan: 'N',
                    Xero: 'N', QBO: 'N', Calculator: 'N'
                };
            } else {
                return {
                    All: 'N',Payrite: 'N', IScan: 'N',
                    Xero: 'N', QBO: 'N', Calculator: 'N'
                };
            }
        }
    }


    setDefaultState = async (data, validEmail) => {
        // console.log(data, validEmail);
        let allSlash = this.props.location.pathname.split('/');
        const id = allSlash[(allSlash.length - 2)];
        let currentAccountant = await getAccountant(id)
            .then(res => {
                if (res.data.length) {
                    return res.data[0];
                } else {
                    return {};
                }
            })
        const user = validEmail ? await getUser(data.primary_email) : null;
        // const clientData = await getClientByEmail(data.primary_email)
        console.log(data) 
        const isBClient = data.clientType === 'Business';
        const [first_name, last_name] = isBClient ? ['', ''] : data.clientName.split('&');

        let defaultAccess = {
            All: 'N', FS: 'N', PD: 'N', T1: 'N', T2: 'N', T3: 'N', T4: 'N', T4A: 'N', T5: 'N', T5018: 'N', Payrite: 'N', IScan: 'N',
            Xero: 'N', QBO: 'N', Calculator: 'N'
        };
        if (Object.keys(currentAccountant).length > 0) {
            if (isBClient) {
                defaultAccess = this.parseAccess(currentAccountant.businessDefaultFolders, 1)
            } else {
                defaultAccess = this.parseAccess(currentAccountant.personalDefaultFolders, 2)
            }
        }
        // console.log(currentAccountant);

        // console.log(user, data);
        const hasUserData = user && user.data.userData.length;
        const userData = hasUserData && user.data.userData[0]; 
        this.setState({
            isNewAccount: false,
             active: data.activeCheck,
            password: '', c_password: '',
            access: data.access === 'na' ? defaultAccess : JSON.parse(data.access),
            first_name: hasUserData ? userData.first_name : first_name,
            last_name: hasUserData ? userData.last_name : last_name,
            company_name: isBClient ? data.clientName : '',
            phone: hasUserData
                ? userData.phone === 'na' ? '' : userData.phone
                : '',
            business_type: data.business_type === 'na' ? '' : data.business_type,
            secondary_first_name: data.secondary_first_name === 'na' ? '' : data.secondary_first_name,
            secondary_last_name: data.secondary_last_name === 'na' ? '' : data.secondary_last_name,
            secondary_phone: data.secondary_phone === 'na' ? '' : data.secondary_phone,
            updatedAt: data.updatedAt,
            edit: false,
            email: validEmail ? data.primary_email : '',
            primary_email: validEmail ? data.primary_email : 'na',
            emailChange: !validEmail,
            clientData: data,
            userData: hasUserData ? userData : { uid: 'na' },
            scanType: data.scanType ? data.scanType : 'Other',
            projects: data.projects ? JSON.parse(data.projects) : [],
            contactType: data.contactType ? data.contactType : 'Self',
            primaryContact: data.primaryContact ? data.primaryContact : 'na',
            primaryContactRelation: data.primaryContactRelation ? data.primaryContactRelation : 'na',
            primaryContactOtherRelation: data.primaryContactOtherRelation ? data.primaryContactOtherRelation : 'na',
            resetPwd: false,
            isPageLoading: false
        })
    };

    stateSetting = (propsPath) => {
        this.setState({isPageLoading: true});
        const id = propsPath ? propsPath : this.props.location.pathname.split('/').pop();
        
        // console.log(id,this.props.location.pathname)
        getClient(id)
            .then(res => {
                if (res.data.length) {
                    const data = res.data[0];
                    // console.log(data);
                    const validEmail = validateEmail(data.primary_email);

                    this.setDefaultState(data, validEmail);
                }
            }).catch(err => { console.log('getAllClients error', err) })

        let all = this.props.location.pathname.split('/')
        let account;
        if (all.length > 0) {
            account = all[all.length - 2]
        }
        let allClients = [];
        if (this.props.clientData.length > 0) {
            for (const cd of this.props.clientData) {
                if (cd.parentId === account) {
                    allClients.push(cd)
                }
            }
        }
        this.setState({ allClients })
    };

    componentDidMount() {
        this.stateSetting()
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.stateSetting(props.location.pathname.split('/').pop())
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    toggleModal = () => {
        this.setState({ show: !this.state.show });
        // props toggleModal called to fade main page
        this.props.toggleModal();
    };

    handleCheckbox = () => {
        this.setState({ active: this.state.active === 'Y' ? 'N' : 'Y' })
    };

    setAccess = (access) => {
        this.setState({ access });
    };

    handleUser = async (user) => {
        const { first_name, last_name, phone, primary_email, active, isNewAccount, userData } = this.state;
        // console.log(userData);

        if (isNewAccount) {
            const newUser = await createUser({
                first_name, last_name,
                phone: phone.length > 0 ? phone : 'na',
                active,
                email: primary_email,
                uid: user ? user.user.uid : 'na',
                userType: 'C',
                userAccess: 'na'
            });
            this.setState({ isNewAccount: false });
            console.log('New User', newUser.data)
        } else {
            const res = await updateUser({
                first_name, last_name,
                phone: phone.length > 0 ? phone : 'na',
                
                email: primary_email,
                userAccess: 'na',
                uid: user ? user.user.uid : userData.uid,
            });
            console.log("not new account, updated...", res.data);
        }
    };

    updateClientInfo = async () => {

        const { first_name, last_name, company_name, primary_email, business_type, password, secondary_first_name,
            secondary_last_name, secondary_phone, access, clientData, scanType, projects,
            contactType, primaryContact, primaryContactRelation, primaryContactOtherRelation,active } = this.state;
        const isBusiness = clientData.clientType === 'Business';

        try {
            await this.updateClient({
                clientName: isBusiness ? company_name : first_name + '&' + last_name,
                primary_email,
                business_type: business_type.length > 0 ? business_type : 'na',
                secondary_first_name: secondary_first_name.length > 0 ? secondary_first_name : 'na',
                secondary_last_name: secondary_last_name.length > 0 ? secondary_last_name : 'na',
                secondary_phone: secondary_phone.length > 0 ? secondary_phone : 'na',
                access,
                updatedAt: Date.now(),
                clientId: clientData.clientId,
                scanType,
                projects: JSON.stringify(projects),
                contactType, primaryContact, primaryContactRelation, primaryContactOtherRelation,activeCheck:active
            });
            // console.log('Client updated.', res.data);
            if (password.length > 5) {
                const user = await secondAuth.auth().createUserWithEmailAndPassword(primary_email, password);
                await this.handleUser(user);
                
                await secondAuth.auth().signOut();             
            } else {
                await this.handleUser(null)
            }

            this.props.reload();
        }
         catch (e) {
            console.log('updating client error', e);
            alert(e.message);
        }
    };

    handleSave = async () => {
        this.setState({ isLoading: true });

        const { first_name, last_name, company_name, clientData, password, c_password, scanType, projects,
            contactType, primaryContact, primaryContactRelation, primaryContactOtherRelation } = this.state;
        const isBusiness = clientData.clientType === 'Business';
        const validatePrimaryContact = contactType === 'Business' || contactType === 'Self' || (primaryContact !== 'na' && primaryContactRelation !== 'na' && (primaryContactRelation !== 'Others' || primaryContactOtherRelation.length > 2))

        if (this.state.edit) {
            if (company_name.length > 0 || (validateName(first_name) && validateName(last_name))) {
                if (password.length === 0 || (password.length > 5 && password === c_password)) {
                    if (scanType !== 'Project' || projects.length) {
                        if (validatePrimaryContact) {
                            console.log('updating client', this.state);
                            await this.updateClientInfo();
                            this.setState({ isLoading: false, edit: false })
                        } else {
                            // alert("\u2022 Point1\n\u2022 Point2");
                            alert('Primary contact name and relation are mandatory.')
                            this.setState({ isLoading: false });
                        }
                    } else {
                        alert('There should be atleast one project.');
                        this.setState({ isLoading: false });
                    }
                } else {
                    alert('Password and confirm password should match and password should not be less than 6 characters.');
                    this.setState({ isLoading: false });
                }
            } else {
                isBusiness
                    ? alert('Company name cannot be blank.')
                    : alert('First name and Last name cannot be blank and cannot contain numbers.');
                this.setState({ isLoading: false });
            }
        } else {
            console.log('editing client');
            this.setState({ edit: true, isLoading: false })
            
        }
    };

    deleteClient = () => {
        const { clientData } = this.state;
        const name = clientData.clientName.split('&').join(' ')
        if (window.confirm(`Are you sure you want to delete ${name}`)) {
            this.setState({ deleting: true });

            let clientId = clientData.clientId;
            return DELETE('api/user/' + clientId).then(r => {
                this.setState({ deleting: false });
                if (r.status === 200) {
                    alert(r?.data?.message)
                    if (typeof this.props.reload === 'function') {
                        this.props.reload();
                    }
                    this.props.history.push('/');
                } else {
                    alert(r?.data?.message)
                }
            }).catch(err => {
                console.log(err);
                this.setState({ deleting: false })
            })
        }
    }

    populateUserData = async (user, populateIndividual) => {
        // console.log(user);
        !populateIndividual && alert('User exist. Populating data');

        populateIndividual
            ? this.setState({
                email: user.email,
                primary_email: user.email,
                userData: user,
                emailChange: false,
                edit: true
            })
            : this.setState({
                email: user.email,
                primary_email: user.email,
                first_name: user.first_name,
                last_name: user.last_name,
                phone: user.phone === 'na' ? '' : user.phone,
                userData: user,
                emailChange: false,
                edit: true
            })
    };

    handleSearch = async (e) => {
        e.preventDefault();

        const { clientData, email } = this.state;
        const isBusiness = clientData.clientType === 'Business';
        // console.log(this.state);

        if (validateEmail(email)) {
            try {
                const res = await getUser(email);
                const { userStatus, userData } = res.data;
                // console.log(userStatus !== 'userNotExist')
                userStatus !== 'userNotExist'
                    ? isBusiness
                        ? this.populateUserData(userData[0], false)
                        : userStatus === 'IndividualExist'
                            ? alert(`Please enter a different email. This email is already assigned to ${userData[0].last_name}, ${userData[0].first_name}`)
                            : this.populateUserData(userData[0], true)
                    :
                    this.setState({
                        primary_email: email,
                        edit: true,
                        isNewAccount: true,
                        emailChange: false,
                        userData: { uid: 'na' }
                    });
                console.log(res);
            } catch (e) {
                console.log(e)
            }
        } else {
            alert('Please enter a valid email address.')
        }

    };

    handleReset = (e) => {
        this.setState({ resetPwd: !this.state.resetPwd });
    };

    handleEmailChange = () => {
        // console.log(this.state.userData);
        this.setState({ emailChange: true, edit: false })
    };

    handleSetProjects = (projects) => {
        this.setState({ projects })
    };

    setDetailState = (state) => {
        this.setState(state);
    }

    renderDiv(label, type, stateName, disabled) {
        return (
            <div className='row'>
                <label className='col-md-4 col-form-label'>{label}</label>
                <div className='col-md-8' >
                    <input className='form-control'
                        name={stateName}
                        type={type}
                        disabled={disabled}
                        onChange={this.handleChange}
                        value={this.state[stateName]} />
                </div>
            </div>
        )
    };

    render() {
        const { clientData, edit, email, primary_email, userData, resetting, emailChange, scanType, projects, resetPwd, deleting } = this.state;
        const isBusiness = clientData.clientType === 'Business';
        const isDisabled = !edit || primary_email === 'na';

        return (
            this.state.isPageLoading ? 
                <div className='client-details-loader' style={{marginLeft: '50%'}}>
                    <JustSpinner style={{width: 50, height: 50}}/>
                </div> 
                :
            <Fragment>
                <section className='card'>
                    <div className='card-header'>
                        <div className='pull-left'>

                        </div>
                        <div className='pull-right'>
                            {/*<button className='btn btn-sm btn-primary mr-2'>Primary Contact </button>*/}
                            {/*<button className='btn btn-sm btn-primary mr-2'>Type</button>*/}
                        </div>
                    </div>
                    <div className='card-body'>
                        <label className='col-lg-6 col-form-label mb-2'><strong>{isBusiness ? 'Primary Contact' : 'Client Details'}</strong> </label>
                        <div className='col-lg-12'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className='row'>
                                        <label className='col-md-4 col-form-label'>Email: </label>
                                        <div className='col-md-8'>
                                            <div className='input-group'>
                                                <input className='form-control'
                                                    // onBlur={this.handleBlur}
                                                    name='email'
                                                    value={email}
                                                    disabled={!emailChange}
                                                    type='text'
                                                    placeholder='Enter email id of contact'
                                                    onChange={this.handleChange} />
                                                <div className='input-group-append'>
                                                    {
                                                        !emailChange
                                                            ?
                                                            <button className='btn btn-sm btn-primary'
                                                                onClick={this.handleEmailChange}>
                                                                Change
                                                            </button>
                                                            :
                                                            <button className='btn btn-sm btn-primary'
                                                                onClick={this.handleSearch}
                                                            >
                                                                <i className="icmn-search" />
                                                            </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.renderDiv('First Name:', 'text', 'first_name', !edit || (isBusiness && primary_email === 'na'))}
                                    {isBusiness && this.renderDiv('Company Name:', 'text', 'company_name', !edit)}

                                    {resetPwd && <ResetPwd userData={userData} setDetailState={this.setDetailState} />}

                                    {userData.uid === 'na' && this.renderDiv('Password:', 'password', 'password', isDisabled)}
                                    {userData.uid === 'na'
                                        ? this.renderDiv('Confirm Password:', 'password', 'c_password', isDisabled)
                                        : !resetPwd && <LaddaButton loading={resetting}
                                            onClick={this.handleReset}
                                            data-style={EXPAND_RIGHT}
                                            className='btn btn-sm btn-warning'
                                        >
                                            {resetting ? 'Sending email' : 'Reset Password'}
                                        </LaddaButton>
                                    }
                                </div>
                                <div className='col-lg-6' >
                                    <div className='row'>
                                        <label className='col-md-4 col-form-label'>Active: </label>
                                        <div className='col-md-8 col-form-label'>
                                            <label className='cui-utils-control cui-utils-control-checkbox'>
                                                <input type="checkbox"
                                                    onChange={this.handleCheckbox}
                                                    disabled={isDisabled}
                                                    checked={this.state.active === 'Y'} />
                                                <span className='cui-utils-control-indicator' />
                                            </label>
                                        </div>
                                    </div>
                                    {this.renderDiv('Last Name:', 'text', 'last_name', !edit || (isBusiness && primary_email === 'na'))}
                                    {/*{!isBusiness && this.renderDiv('Primary Contact:', 'text', 'primary_contact', true)}*/}
                                    {this.renderDiv('Phone:', 'text', 'phone', isDisabled)}
                                    {isBusiness && this.renderDiv('Business Type:', 'text', 'business_type', isDisabled)}
                                </div>
                            </div>
                        </div>
                        <hr />

                        <label className='col-md-3 col-form-label mb-2'><strong>{isBusiness ? 'Business Scan Type' : 'Primary Contact'}</strong> </label>

                        {
                            isBusiness
                                ? <ScanType scanType={scanType}
                                    projects={projects}
                                    handleChange={this.handleChange}
                                    handleSetProjects={this.handleSetProjects}
                                    edit={edit}
                                />
                                : <PrimaryContact state={this.state}
                                    handleChange={this.handleChange}
                                    allClients={this.state.allClients}
                                />

                        }

                        <hr />

                        <label className='col-md-3 col-form-label mb-2'><strong>Secondary Contact</strong> </label>
                        <div className='col-lg-12'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    {this.renderDiv('First Name:', 'text', 'secondary_first_name', !edit)}
                                    {this.renderDiv('Phone:', 'text', 'secondary_phone', !edit)}
                                </div>
                                <div className='col-lg-6'>
                                    {this.renderDiv('Last Name:', 'text', 'secondary_last_name', !edit)}
                                </div>
                            </div>
                        </div>
                        <hr className='mb-4' />
                        <AccessClient setAccess={this.setAccess} access={this.state.access} edit={edit} />
                        <hr className='mb-5' />
                        <div className="row">
                            <div className="col-md-6">
                                <LaddaButton loading={this.state.isLoading}
                                    onClick={this.handleSave}
                                    data-style={EXPAND_RIGHT}
                                    className='btn btn-primary mr-2'
                                >
                                    {edit ? 'Save' : 'Edit'}
                                </LaddaButton>

                                <LaddaButton loading={this.state.isLoading}
                                    onClick={this.deleteClient}
                                    data-style={EXPAND_RIGHT}
                                    className='btn btn-primary'
                                >
                                    {deleting ? 'Deleting..' : 'Delete'}
                                </LaddaButton>
                            </div>
                        </div>
                    </div>
                </section>
                {/*{this.renderModal()}*/}
            </Fragment>
        )
    }
}
