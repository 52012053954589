import React, {Component} from 'react'
import Explorer from "./Explorer";
import { sortedIndividual, sortedBusiness } from '../../../../utils/Sorting';
// import axios from "axios";
// import {Baseurl} from "../../../../utils/Baseurl";


export default class DocumentManager extends Component {
    state = {
        mainTree: [],
        accountantId: 'none',
        selectedAccountant: [],
        clientType: 'business',
        clientId: 'none',
        selectedClient: [],
        clientData: [],
        selectedCompany:'',
        isClient:this.props.isClient
    };

    // getFiles(){
    //     return axios({
    //         method: 'get',
    //         url: Baseurl + `api/storage/get?path=34b8dc05-4013-4d0b-b25a-52ca28dc4e66/FS`,
    //         headers: { 'content-type': 'application/json' },
    //     })
    // }


    componentDidMount(){
        // this.getFiles()
        //     .then(res => console.log(res))
        //     .catch(err => console.log('err: ', err));
        this.settingState(this.props);
        // console.log(this.props)
    }

    UNSAFE_componentWillReceiveProps(props){
        // console.log(props.tree);
        this.settingState(props)
    }

    settingState = (props) => {
        const path = window.location.pathname.split('/');
        // console.log("props = ",props);

        if(props.tree.length){
            const accountantId = path[2];
            const clientId = path[3];
            // console.log(props.tree)
            const selectedAccountant = props.tree.filter(value => value.accountantId === accountantId);

            const selectedClient = selectedAccountant.length && clientId
                ? this.getSelectedClient(selectedAccountant[0], clientId)
                // selectedAccountant[0][this.state.clientType].filter(value => value.clientId === clientId)
                : [];

            const clientType = selectedClient.length ? selectedClient[0].clientType.toLowerCase() : this.state.clientType;
            // console.log(selectedAccountant, selectedClient);
            this.setState({mainTree: props.tree, accountantId, clientId,isClient:true,clientData: sortedBusiness(props.clientData),selectedCompany:clientId, selectedAccountant, selectedClient, clientType})
        } else {
            const selectedCompany = path[2];
            this.setState({clientData: sortedBusiness(props.clientData), selectedCompany})
        }
    };

    getSelectedClient = (accountant, clientId) => {
        const allClients = [...accountant.business, ...accountant.individual]
        return allClients.filter(client => client.clientId === clientId);
    }

    handleAccountantSelect = (e) => {
        e.target.value !== 'none'
            ? this.props.history.push(`/document/${e.target.value}`)
            : this.props.history.push(`/document`);
    };

    handleClientSelect = (e) => {
        const {selectedAccountant} = this.state;

        e.target.value !== 'none'
            ? this.props.history.push(`/document/${selectedAccountant[0].accountantId}/${e.target.value}`)
            : this.props.history.push(`/document/${selectedAccountant[0].accountantId}`);

    };

    handleRadioClick = () => {
        this.setState({
            clientType: this.state.clientType === 'business' ? 'individual' : 'business',
            clientId: 'none'
        })
    };

    handleCompanySelect = (e) => {
        this.setState({clientId:e.target.value,selectedCompany:e.target.value})
        // console.log(this.state.clientId,this.state.selectedCompany)
        if(e.target.value === 'none'){
            this.props.history.push(`/document`);
        } else {
            this.props.history.push(`/document/${e.target.value}`);
        }
    };


    render(){
        const {mainTree, selectedAccountant, clientType, selectedClient, clientData, clientId} = this.state;
        const {isClient, user} = this.props; 
        const clientList = selectedAccountant.length ? (clientType === 'business' ? sortedBusiness(selectedAccountant[0].business) : sortedIndividual(selectedAccountant[0].individual)) : []
        // console.log('selectedAccountant',selectedAccountant);
        // console.log(clientData)
        
        return(
            <section className='card'>
                <div className='card-header'>
                    <div className='row'>
                        <div className='col-lg-4'>
                            {!isClient ? <span className='cui-utils-title mr-5'><strong>Document Manager</strong></span> :
                            <span className='cui-utils-title mr-5'><strong>{user.last_name}, {user.first_name}</strong></span>}
                        </div>
                        {!isClient &&
                            <div className='col-lg-4'>
                                <select className='form-control'
                                        onChange={this.handleAccountantSelect}
                                        value={this.state.accountantId}
                                        name='accountantId'
                                >
                                    <option value={'none'}>Select Accountant</option>
                                    {mainTree.map((m, i) => {
                                        return(
                                            <option key={i} value={m.accountantId}>{m.company_name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        }
                        {
                            isClient && clientData.length > 1 &&
                            <div className='col-lg-4'>
                                <select className='form-control'
                                        onChange={this.handleCompanySelect}
                                        value={this.state.selectedCompany}
                                    // name='accountantId'
                                >
                                    {clientData.map((c, i) => {                                        
                                        // console.log(c.clientName, this.state.selectedCompany)
                                        return(
                                            <option key={i} value={c.clientId}>{c.clientName}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        }
                        {

                        }
                    </div>
                </div>
                <div className='card-body'>
                    {!isClient &&
                        <div className='row mb-4'>
                            <div className='col-lg-4'>
                                <div className='row' style={{marginLeft: 0}}>
                                    <label className='cui-utils-control cui-utils-control-radio mr-5'>
                                        <strong>Business</strong>
                                        <input type='radio'
                                               name='radio'
                                               checked={this.state.clientType === 'business'}
                                               onChange={this.handleRadioClick}/>
                                        <span className='cui-utils-control-indicator'/>
                                    </label>
                                    <label className='cui-utils-control cui-utils-control-radio'>
                                        <strong>Personal</strong>
                                        <input type='radio'
                                               name='radio'
                                               checked={this.state.clientType === 'individual'}
                                               onChange={this.handleRadioClick}/>
                                        <span className='cui-utils-control-indicator'/>
                                    </label>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <select className='form-control'
                                        onChange={this.handleClientSelect}
                                        value={this.state.clientId}
                                        name='clientId'
                                >
                                    <option value={'none'}>Select Client</option>
                                    {
                                        clientList.map((c, i) => {

                                            const [firstName, lastName] = c.clientName.split('&');
                                            const clientName = c.clientType === 'Business' ? c.clientName : `${lastName}, ${firstName}`;
                                            return(
                                                <option key={i} value={c.clientId}>{clientName}</option>
                                            )
                                        })}
                                </select>
                            </div>
                        </div>
                    }
                    <hr style={{marginBottom: '2rem'}}/>
                    <div className='col-lg-12'>
                        {    
                            (selectedClient.length && clientId !== 'none') || isClient
                                ? <Explorer {...this.props} selectedAccountant={selectedAccountant?.[0]} clientData={clientData.find(cl=>cl.clientId==this.state.selectedCompany)} clientType={clientType} check={this.state.isClient} />
                                : <p>No client selected.</p>
                        }
                    </div>
                </div>
            </section>
        )
    }
}
