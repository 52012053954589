import React, { Component, Fragment } from 'react';
import UploadModalClient from "./UploadModalClient";
import Inbox from "./Inbox/Inbox";
// import Discard from "./Discard";
// import Query from "./Query";
// import Archive from "./Archive";
import { getClient } from "../../../../utils/API";
import UploadModalAdmins from "./UploadModalAdmins";
import Download from "./Download";


export default class Explorer extends Component {
    state = {
        folder: 'inbox',
        subFolder: 'FS',
        subFolders: [],
        year: String(new Date().getFullYear()),
        years: [],
        month: 'January',
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        client: [],
        docTypes: [],
        scanType: 'Other',
        projects: []
    };

    filterFolder(key) {
        switch (key) {
            case 'All': return false;
            case 'Payrite': return false;
            case 'IScan': return false;
            case 'Xero': return false;
            case 'QBO': return false;
            case 'Calculator': return false;
            default: return true
        }
    }

    settingState = async (props) => {
        const { user, location, isClient, isStaff, selectedAccountant, clientData, clientType, check } = props;
        // console.log(props)
        const clientId = location.pathname.split('/').pop();
        const accessData = []
        let accessDt = []

        // console.log("selectedAccountant === ", selectedAccountant, clientData, clientType, check)
        if (check) {
            if (clientData) {
                accessDt = JSON.parse(clientData?.access)
            }
        } else {
            if (selectedAccountant) {
                accessDt = clientType === "business" ? JSON.parse(selectedAccountant?.businessDefaultFolders) : JSON.parse(selectedAccountant?.personalDefaultFolders)
            }
        }
        //  console.log(accessDt)
        for (const [key, value] of Object.entries(accessDt)) {
            // console.log(`${key}: ${value}`);
            if (key != "All" && key != "Payrite" && key != "IScan" && key != "Xero" && key != "QBO" && key != "Calculator" && value=='Y') {
                accessData.push(key)
            }
        }

        try {
            const client = await getClient(clientId);

            // console.log('client data',client," ++++ =  ",this.props)
            const { scanType } = client.data[0];
            const projects = JSON.parse(client.data[0].projects);

            // console.log('settingState user', user)
            const access = user && user.client.length
                // actual filtering for staff done in MenuLeft

                ? JSON.parse(user.client.filter(value => value.clientId === clientId)[0].staffAccess)
                : client.data[0].access !== 'na'
                    ? JSON.parse(client.data[0].access)
                    : 'na';

            // console.log('access', access)
            // const subFolders = isClient || isStaff
            //     ? Object.keys(access).filter(key => access[key] === 'Y' && this.filterFolder(key))
            //     : ["FS", "PD", "T1", "T2", "T3", "T4", "T4A", "T5", "T5018"];

            // const docTypes = await getDocType();

            const subFolders = accessData
               
            this.setState({
                client: client.data,
                // docTypes: docTypes.data[0],
                docTypes: client.data[0].docType,
                years: client.data[0].years,
                // year: String(new Date().getFullYear()),
                subFolders,
                subFolder: subFolders[0],
                scanType: scanType ? scanType : 'Other',
                projects
            });
        } catch (e) {
            console.log(e)
        }
    };

    async componentDidMount() {
        await this.settingState(this.props)
    }

    async UNSAFE_componentWillReceiveProps(props) {
        await this.settingState(props);
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    };

    toggleModal = () => {
        // console.log(this.props);
        this.setState({ upload: !this.state.upload });
        // props toggleModal called to fade main page
        this.props.toggleModal();
    };

    render() {
        const { folder, subFolders, year, years, client, upload, projects, scanType } = this.state;
        const { isClient } = this.props;
        const isBusiness = client.length && client[0].clientType === 'Business';
        const isInboxOrArchive = folder === 'inbox' || folder === 'archive';
        // console.log(subFolders,"pop");
        return (
            <Fragment>
                <div className='row'>
                    <div className='col-md-3'>
                        <label className='col-form-label'>Folder</label>
                        <select className='form-control' name='folder' onChange={this.handleChange} value={folder}>
                            <option value='inbox'>Inbox</option>
                            <option value='archive'>Archive</option>
                            {/*<option value='archive'>Archive</option>*/}
                            {/*<option value='query'>Query</option>*/}
                            {/*<option value='discard'>Discard</option>*/}
                            {subFolders.map((folder, i) => {
                                // console.log(folder)
                                // if (!isBusiness) {
                                //     // if(folder === 'T1'){
                                //     return folder === 'T1' && <option key={i} value={folder}>{folder}</option>
                                //     // }
                                // } else {
                                //     return folder !== 'T1' && <option key={i} value={folder}>{folder}</option>
                                // }
                                return <option key={i} value={folder}>{folder}</option>
                            })}
                            {/* <option value='Download'>Download</option> */}
                        </select>
                    </div>
                    <div className='col-md-3'>
                        {
                            folder !== 'inbox' && folder !== 'archive' && isBusiness &&
                            <>
                                <label className='col-form-label'>Year</label>
                                <select className='form-control' name='year' onChange={this.handleChange} value={year} disabled={!subFolders.length}>
                                    {years.map((year, i) => {
                                        return <option key={i} value={year}>{year}</option>
                                    })}
                                </select>
                            </>
                        }
                    </div>
                    <div className='col-md-3'>
                        {/* {
                                folder === 'Download' && isBusiness &&
                                <>
                                    <label className='col-form-label'>Sub Folder</label>
                                    <select className='form-control' name='subFolder' onChange={this.handleChange} value={subFolder} disabled={!subFolders.length}>
                                        {subFolders.map((folder, i) => {
                                            return <option key={i} value={folder}>{folder}</option>
                                        })}
                                    </select>
                                </>
                            } */}
                    </div>
                    <div className='col-md-3 mt-4'>
                        <button className='btn btn-sm btn-primary pull-right width-200' onClick={this.toggleModal}>Upload Files</button>
                    </div>
                </div>
                <br />
                {isInboxOrArchive && <Inbox {...this.state} {...this.props} />}
                {/* {folder === 'archive' && <Archive client={client} {...this.props}/>} */}
                {/* {folder === 'query' && <Query client={client} {...this.props}/>}
                {folder === 'discard' && <Discard client={client} {...this.props}/>} */}
                {/* {folder === 'Download' && <Download {...this.state} {...this.props}/>} */}
                {!isInboxOrArchive && <Download {...this.state} {...this.props} />}

                {
                    upload && isClient &&
                    <UploadModalClient client={client} {...this.props}
                        toggleModal={this.toggleModal}
                        docTypes={this.state.docTypes}
                        scanType={scanType}
                        projects={projects}
                        years={years}
                        />
                }
                {upload && !isClient && <UploadModalAdmins {...this.state} {...this.props} 
                toggleModal={this.toggleModal} 
                handleUploadState={(updateState)=>{this.setState({...this.state,...updateState})}}
                />}
            </Fragment>
        )
    }
}
