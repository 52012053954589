import React, {Component} from 'react';


export default class AccessClient extends Component {

    handleClick = (key) => {
        const {access} = this.props;
        if(key === 'All'){
            access.All = access.All === 'N' ? 'Y' : 'N';

            Object.keys(access).map((accessKey, i)=> {
                access[accessKey] = access.All;
                return null
            });
            this.props.setAccess(access);
        } else {
            access.All = 'N';
            access[key] = access[key] === 'N' ? 'Y' : 'N';
            this.props.setAccess(access);
        }
    };

    active = (state) => {
        return this.props.access[state] === 'Y' ? 'btn btn-sm btn-success' : 'btn btn-sm btn-default'
    };

    getButtonName = key => {
        switch (key){
            case 'Calculator': return 'Tax Calculator';
            case 'IScan': return 'Scanner';
            default: return key
        }
    };

    render(){
        const {access, title, edit} = this.props;
        return(
            <div className='col-lg-12'>
                <div className='row'>
                    <label className='col-md-2 col-form-label'>
                        {/* <strong> */}
                            {title ? title: 'Access'}
                        {/* </strong> */}
                    </label>
                    {
                        Object.keys(access).map((accessKey, i)=> {
                            return(
                                <button key={i}
                                        className={`${this.active(accessKey)} mr-2 mb-2`}
                                        disabled={!edit}
                                        onClick={() => this.handleClick(accessKey)}>
                                    {this.getButtonName(accessKey)}
                                </button>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}
