import React, {Component} from 'react';
import { Switch, Route} from 'react-router-dom';
import AccountantDetails from "./AccountantDetails";


export default class AccountantRoutes extends Component {
    render(){
        return(
            <Switch>
                <Route exact path="/accountant"
                       render={(props) => <AccountantMain {...props}
                                                             toggleModal={this.props.toggleModal}
                       />}
                />
                <Route exact path="/accountant/:id"
                       render={(props) => <AccountantDetails {...props}
                                                             toggleModal={this.props.toggleModal}
                                                             reload={this.props.reload}
                                                             isAccountant={this.props.isAccountant}
                       />}
                />
            </Switch>
        )
    }
}


class AccountantMain extends Component {
    componentDidMount(){
        alert("Invalid address");
        this.props.history.push('/')
    }

    render(){
        return(
            <h1>Accountant Main</h1>
        )
    }
}