import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import "../../../assests/styles/menuLeft/menu-left.css";
import logo from "../../../assests/img/logo.png";
import {
	sortedIndividual,
	sortedBusiness,
	sortedAccount,
} from "../../../utils/Sorting";
import firebaseApp from "../../../utils/Fire";

export default class MenuLeft extends Component {
	state = {
		filterBy: "Both",
		searchBox: "",
		logoImage: "",
		readonly: true,
		collapseData: [],
	};
	componentDidMount() {
		const storageRef = firebaseApp.storage().ref();
		// const listRef = storageRef.child(`${id}/inbox`);
		const { isAccountant, accountantData } = this.props;

		if (isAccountant && accountantData[0].displayPic) {
			storageRef
				.child(accountantData[0].displayPic)
				.getDownloadURL()
				.then((url) => {
					this.setState({ logoImage: url });
				})
				.catch((err) => {
					this.setState({ logoImage: logo });
					console.log("google image err: ", err);
				});
		} else {
			this.setState({ logoImage: logo });
		}
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	handleChangeExpand = (e, action) => {
		if (action === 1) {
			const fil = this.state.collapseData.filter((f) => f._id === e);
			if (fil.length === 0) {
				this.setState({ collapseData: [...this.state.collapseData, e] });
			}
		} else {
			const newArray = this.state.collapseData.filter((_, i) => i === e);
			this.setState({ collapseData: [...newArray] });
		}
	};

	handleSearch = (e) => {
		this.setState({ searchBox: e.target.value });
	};

	getSearchResult = (tree) => {
		const { searchBox } = this.state;

		if (searchBox.length > 0) {
			const filterData = [];
			// console.log(JSON.parse(tree))
			JSON.parse(tree).map((t) => {
				t.business = t.business.filter((value) =>
					value.clientName.toLowerCase().includes(searchBox.toLowerCase())
				);
				t.individual = t.individual.filter(
					(value) =>
						value.clientName.toLowerCase().includes(searchBox.toLowerCase()) ||
						value.children.some((c) =>
							c.clientName.toLowerCase().includes(searchBox.toLowerCase())
						)
				);
				t.individual.forEach((i) => {
					if (i.children.length) {
						i.children = i.children.filter((c) =>
							c.clientName.toLowerCase().includes(searchBox.toLowerCase())
						);
					}
				});
				filterData.push(t);
				return null;
			});
			let data = [];
			for (let x of filterData) {
				const fil = this.state.collapseData.filter((t) => x._id === t._id);
				if (fil.length === 0) {
					data.push(x._id, "lkjlkj");
				}
			}
			setTimeout(() => {
				this.setState({ collapseData: [...this.state.collapseData, ...data] });
			}, 1000);

			return filterData;
		} else {
			return JSON.parse(tree);
		}
	};

	getClients = (user, tree) => {
		// restrict to only allowed-clients for staff
		if (user && user.client.length) {
			const access = user.client;
			tree.map((t) => {
				const businessArray = t.business.map((b) => {
					return access.some(
						(value) => value.allowed && value.clientId === b.clientId
					)
						? b
						: null;
				});
				const individualArray = t.individual.map((i) => {
					return access.some(
						(value) => value.allowed && value.clientId === i.clientId
					)
						? i
						: null;
				});
				t.business = businessArray.filter((value) => value !== null);
				t.individual = individualArray.filter((value) => value !== null);
				return null;
			});
			return JSON.parse(JSON.stringify(tree));
		} else {
			// console.log('getClients error')
			return JSON.parse(JSON.stringify(tree));
		}
	};

	findChildren(parent, all) {
		let r = [];
		if (all.length < 1) return r;

		for (const a of all) {
			if (a.primaryContact === parent.clientId) {
				r.push(a);
			}
		}
		return r;
	}

	moldClientLists = (newTress) => {
		if (newTress.length < 1) return newTress;

		for (let nt of newTress) {
			let pArray = Object.assign([], nt.individual);
			nt.individual = [];
			for (const pa of pArray) {
				if (pa.primaryContact === "na") {
					let findChildrent = this.findChildren(pa, pArray);
					nt.individual.push(
						Object.assign({}, pa, { children: findChildrent })
					);
				}
			}
		}

		return newTress;
	};

	render() {
		const { filterBy, logoImage } = this.state;
		const { tree, location, isClient, user } = this.props;
		// console.log("tree",tree,"user",user)
		let newTree = this.getClients(user, tree);

		newTree = this.moldClientLists(Object.assign([], newTree));

		const filterTree = sortedAccount(
			this.getSearchResult(JSON.stringify(newTree))
		);
		// console.log('ft',filterTree)

		const path = location.pathname.split("/").pop();
		const clientRoute =
			location.pathname.split("/")[1] === "document" ? "/document" : "/client";

		const showBusinessClients = filterBy === "Both" || filterBy === "Business";
		const showIndividualClients =
			filterBy === "Both" || filterBy === "Individual";

		const pStyle = {
			display: "block",
			padding: "5px 1.5rem 0",
			margin: 0,
		};

		const link_style = {
			display: "block",
			padding: "5px 2rem 0 2.3rem",
		};

		const link_style_child = {
			display: "block",
			padding: "5px 2rem 0 3.3rem",
		};

		return (
			<Fragment>
				<nav className="cui-menu-left">
					<div className="cui-menu-left-inner">
						<div
							className="cui-menu-left-logo text-center"
							style={{ padding: "0 0 0 13px", background: "none" }}
						>
							<Link to="/">
								<img
									src={logoImage}
									alt=""
									style={{ maxHeight: 64, maxWidth: 315, height: "auto" }}
								/>
							</Link>
						</div>
						{/* <input type='hidden' value='prayer' /> */}
						{!isClient && (
							<>
								<div className="mb-1 mt-1" style={{ display: "flex" }}>
									<select
										className="form-control"
										style={{ width: "50%", paddingTop: 0, paddingBottom: 0 }}
										name="filterBy"
										value={this.state.filterBy}
										onChange={this.handleChange}
									>
										<option value="Both">Both</option>
										<option value="Business">Business</option>
										<option value="Individual">Personal</option>
									</select>
									<input
										type="text"
										placeholder="search"
										className="form-control width-50p"
										name={`searchBox-${Date.now()}`}
										value={this.state.searchBox}
										onChange={this.handleSearch}
										readOnly={this.state.readonly}
										onFocus={() => this.setState({ readonly: false })}
										onBlur={() => this.setState({ readonly: true })}
									/>
								</div>
								<div
									className="cui-menu-left-scroll"
									style={{ height: "calc(100% - 100px)", overflow: "auto" }}
								>
									{filterTree.map((m, i) => {
										const active =
											path === m.accountantId ? "#e6f8ff" : "#e6e6e6";
										const accStyle = {
											display: "flex",
											justifyContent: "space-between",
											padding: "5px 1rem 0",
											backgroundColor: active,
										};
										const find = this.state.collapseData.filter(
											(e) => e === m._id
										);

										return (
											<div key={i} className="mb-2">
												<div>
													<Link
														to={`/accountant/${m.accountantId}`}
														style={accStyle}
													>
														<strong>{m.company_name}</strong>
														{find.length === 0 ? (
															<span
																className="fs-18"
																onClick={() =>
																	this.handleChangeExpand(m._id, 1)
																}
															>
																{" "}
																<i class="bi bi-chevron-down"></i>
															</span>
														) : (
															<span
																className="fs-18"
																onClick={() =>
																	this.handleChangeExpand(m._id, 0)
																}
															>
																{" "}
																<i class="bi bi-chevron-up"></i>
															</span>
														)}
													</Link>
												</div>

												{/* ----- showing all businesses here if any ----- */}
												{showBusinessClients &&
													sortedBusiness(m.business).map((b, index) => {
														const find = this.state.collapseData.filter(
															(e) => e === m._id
														);
														if (find.length !== 0) {
															const active =
																path === b.clientId ? "active_link" : "";
															return (
																<Fragment key={index}>
																	{index === 0 && (
																		<p style={pStyle}>Business</p>
																	)}
																	<Link
																		className={active}
																		to={`${clientRoute}/${m.accountantId}/${b.clientId}`}
																		style={link_style}
																	>
																		<strong>{b.clientName}</strong>
																	</Link>
																</Fragment>
															);
														}
													})}

												{/*showing all individuals here if any */}
												{showIndividualClients &&
													sortedIndividual(m.individual).map((ind, idx) => {
														const find = this.state.collapseData.filter(
															(e) => e === m._id
														);
														if (find.length !== 0) {
															const active =
																path === ind.clientId ? "active_link" : "";
															const [firstName, lastName] =
																ind.clientName.split("&");
															return (
																<Fragment key={idx}>
																	{idx === 0 && <p style={pStyle}>Personal</p>}
																	<Link
																		className={active}
																		to={`${clientRoute}/${m.accountantId}/${ind.clientId}`}
																		style={link_style}
																	>
																		<strong>
																			{lastName}, {firstName}
																		</strong>
																	</Link>
																	{ind.children &&
																		ind.children.length > 0 &&
																		sortedIndividual(ind.children).map(
																			(indc, idxc) => {
																				const active1 =
																					path === indc.clientId
																						? "active_link"
																						: "";
																				const [firstName, lastName] =
																					indc.clientName.split("&");
																				return (
																					<Fragment key={idxc}>
																						<Link
																							className={active1}
																							to={`${clientRoute}/${m.accountantId}/${indc.clientId}`}
																							style={link_style_child}
																						>
																							<strong>
																								{lastName}, {firstName}
																							</strong>
																						</Link>
																					</Fragment>
																				);
																			}
																		)}
																</Fragment>
															);
														}
													})}
											</div>
										);
									})}
								</div>
							</>
						)}
					</div>
				</nav>
			</Fragment>
		);
	}
}
