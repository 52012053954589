import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {validateEmail} from "../../utils/FormValidater";
import CommonHeader from "./CommonHeader";
import CommonFooter from "./CommonFooter";
import '../../assests/styles/pages/register.css';
import {EXPAND_RIGHT} from "react-ladda";
import LaddaButton from "react-ladda";
import TopAlert from "../../utils/TopAlert";

export default class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAlertBox: false,
            email: '', emailError: false,
            code: '', codeError: false,
            newPwd: '', newPwdError: false,
            cPassword:'',
            matchError: false,
            isLoading: false,
            resetPwd: null,
            confirmation: null
        };
    };

    validateForm() {
        if (validateEmail(this.state.email)) {
            this.setState({emailError: false});
            return true
        } else {
            this.setState({emailError: true});
            return false;
        }
    };

    validateConfirmation() {
        const {newPwd, cPassword,code} = this.state;
        newPwd.length < 6 && this.setState({newPwdError: true});
        newPwd.length > 36 && this.setState({tooLong: true});
        newPwd !== cPassword && this.setState({matchError: true});
        code.length !== 6 && this.setState({codeError: true});

        return code.length === 6 && newPwd.length > 5 && newPwd.length < 37 && newPwd === cPassword;
    };

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
        event.target.name === 'email' && this.setState({emailError: false, userNotFound: false});
        event.target.name === 'code' && this.setState({codeError: false, codeMismatch: false});
        event.target.name === 'newPwd' && this.setState({newPwdError: false, tooLong: false});
        event.target.name === 'cPassword' && this.setState({matchError: false});
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({isLoading: true});

        if (this.validateForm()) {

        } else {
            this.setState({isLoading: false});
        }
        this.setState({isLoading: false});
    };

    renderForgotPwdForm() {
        const {emailError, userNotFound} = this.state;
        return (
            <div className="cui-register-block-form" style={{textAlign: 'center'}}>
                <h3 className='title'>Can't Log In?</h3>
                <p className="lead mb-0">We will send you a code or recovery link to reset password.</p>
                <br/>
                <form id="form-validation" name="form-validation">
                    <div className={emailError || userNotFound ? 'form-group has-danger' : 'form-group'}>
                        <input type="text"
                               className={emailError || userNotFound ? 'form-control has-danger' : 'form-control'}
                               name="email"
                               defaultValue={this.state.email}
                               placeholder="Email Address"
                               onChange={this.handleChange}
                        />
                        {emailError && <p className='type--fine-print error-text'>* A valid email is needed.</p>}
                        {userNotFound && <p className='type--fine-print error-text'>* User does not exist.</p>}
                    </div>
                    <LaddaButton loading={this.state.isLoading}
                                 onClick={this.handleSubmit}
                                 data-style={EXPAND_RIGHT}
                                 className='btn btn-info mr-3'
                    >
                        Send password reset email
                    </LaddaButton>
                </form>

                <hr/>
                <span className="register-link">Need help? <Link className="cui-utils-link-blue cui-utils-link-underlined"
                    to="">Contact support</Link>. Or <Link className="cui-utils-link-blue cui-utils-link-underlined"
                    to="/login">Return to login</Link>
                </span>
            </div>
        )
    }

    render() {
        return (
            <div className="cui-login cui-login-fullscreen">
                <CommonHeader/>
                <div className="cui-register-block">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="cui-register-block-inner">

                                {this.renderForgotPwdForm()}

                            </div>
                        </div>
                    </div>
                </div>
                <CommonFooter/>
                {this.state.topAlert && <TopAlert e={this.state.topAlert} close={()=> this.setState({topAlert: null})}/>}
            </div>
        )
    }
}
