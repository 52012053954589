import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import * as serviceWorker from './serviceWorker';

import {BrowserRouter} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.css';
import './assests/styles/vendors/bootstrap/bootstrap.cleanui.css';
import './assests/styles/core/common/core.cleanui.css';
import './assests/styles/settings/themes/themes.cleanui.css';
import './assests/styles/settings/common/settings.cleanui.css';
import './assests/styles/pages/pricing-table.css';
import './assests/styles/font-icomoon/style.css';
import './assests/styles/font-awesome/css/font-awesome.css';
import './assests/styles/vendors/ladda/ladda-themeless.min.css';
import './assests/styles/vendors/dropify/dropify.cleanui.css';
import './assests/styles/vendors/dropify/dist/css/dropify.min.css';
import './assests/styles/vendors/select2/select2.min.css';
import './assests/styles/vendors/select2/select2.cleanui.css';
import './index.css';

ReactDOM.render(
    <BrowserRouter>
        <App/>
    </BrowserRouter>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
