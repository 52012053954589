export const validateEmail = (email) =>{
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const validateName = (name) =>{
    return /^[A-Za-z '-]+$/.test(name) && name.length > 0;
};

export const autoCapitalize = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1)
};
