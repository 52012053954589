import React, { Component } from 'react'
// import { Switch, Route} from 'react-router-dom';
import YearDetails from './YearDetails';


export class YearRoutes extends Component {
    render() {
        return (
          
            <YearDetails/>
        )
    }
}

export default YearRoutes
