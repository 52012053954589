export const sortedIndividual = (clients) => {
    // console.log(clients);
    const name = (clientDetail)  => {
        const [firstName, lastName] = clientDetail.clientName.split('&');
        return `${lastName.toLowerCase()} ${firstName.toLowerCase()}`
    }
    const sorted = clients.sort((a,b) => (name(a) < name(b)) ? -1 : ((name(b) < name(a)) ? 1 : 0));
    return sorted;
};

export const sortedBusiness = (clients) => {
    const sorted = clients.sort((a,b) => (a.clientName < b.clientName) ? -1 : ((b.clientName < a.clientName) ? 1 : 0));
    return sorted;
};

export const sortedAccount = (accounts) => {
    const sorted = accounts.sort((a,b) => (a.company_name.toLowerCase() < b.company_name.toLowerCase()) ? -1 : ((b.company_name.toLowerCase() < a.company_name.toLowerCase()) ? 1 : 0));
    return sorted;
};