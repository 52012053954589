import React, {Component} from 'react';
import LaddaButton, {EXPAND_RIGHT} from "react-ladda";
import {copyFile} from "../../../../utils/API";
import firebaseApp from "../../../../utils/Fire";


export default class Upload extends Component {
    state = {
        isLoading: false,
        reason: ''
    };

    handleChange = e => {
        this.setState({[e.target.name]: e.target.value})
    };

    toggleModal = () => {
        this.props.toggleReasonModal();
    };


    handleSave = async () => {
        this.setState({isLoading: true});

        const {reason} = this.state;
        const {reloadInbox, selectedItem, client, selectedStatus} = this.props;

        const source = selectedItem.fullPath;
        const destination = `${client[0].clientId}/${selectedStatus.toLowerCase()}/${selectedItem.name}`;

        const {user, type, structure} = selectedItem.customMetadata;
        const newMetadata = {
            customMetadata: {
                user, type, structure,
                status: selectedStatus,
                reason
            }
        };

        const storageRef = firebaseApp.storage().ref();
        const fileRef = storageRef.child(selectedItem.fullPath);

        fileRef.updateMetadata(newMetadata)
            .then(res => {
                console.log(res);
                copyFile({source, destination})
                    .then(result => {
                        console.log(result);
                        this.setState({isLoading: false});
                        reloadInbox();
                        this.toggleModal();
                    })
                    .catch(err => console.log('File copy err: ', err))
            })
            .catch(err => console.log(err));

        console.log(selectedItem, client, selectedStatus, reason);
    };

    render(){
        const {isLoading, reason} = this.state;

        return(
            <div className={`modal fade modal-size-small show`} style={{display: 'block'}}>
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Reason Modal</h5>
                            <button className="close" onClick={this.toggleModal}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className='modal-body'>
                            <div className='col-lg-12'>
                                <input className='form-control' value={reason} onChange={this.handleChange} name='reason'/>
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button className='btn btn-sm'
                                    onClick={this.toggleModal}
                                    disabled={this.state.isLoading}>Cancel</button>
                            <LaddaButton loading={this.state.isLoading}
                                         onClick={this.handleSave}
                                         data-style={EXPAND_RIGHT}
                                         className='btn btn-sm btn-primary'
                            >
                                {isLoading ? 'Saving' : 'Save'}
                            </LaddaButton>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
