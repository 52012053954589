import React, { Component, Fragment } from 'react';
import axios from "axios";
import { Baseurl } from "../../../../utils/Baseurl";

import { GET } from '../../../../utils/API';
// import AddYear from '../AddYear';
// import firebaseApp from '../../../../utils/Fire';


export default class Dashboard extends Component {
    state = {
        active: false,
        type: 'Business',
        notifications: [],
    };

    componentDidMount() {
        if (!this.props.isClient) {
            let url = 'api/user/notifications'
            if (this.props.isAccountant) {
                let account = this.props.accountantData[0]
                url += '?accountantId=' + account.accountantId
            }
            GET(url)
                .then(r => {
                    
                    this.setState({
                        notifications: r.data
                    });
                });
        }
    }

    // updateClient(data) {
    //     // console.log(data);
    //     return axios({
    //         method: 'post',
    //         url: Baseurl + 'api/client/updateClient',
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         data: data,
    //     })
    // }

    // getRefreshToken = async (e) => {
    //     try {
    //         const result = await axios({
    //             method: 'post',
    //             url: Baseurl + 'api/storage/getRefreshToken',
    //             headers: {
    //                 "Content-Type": "application/json",
    //             },
    //             data: { code: 'OAQABAAIAAAAGV_bv21oQQ4ROqh0_1-tA-O4gEckb5PoUQ3AiIZZ1sHhXq92JlOaDXEl_wZjHv5Ol-hfgsmjOPfQ29nRO-hoBmOajYExyxz51sCJkAtjFLMrPBvx9KD9usjsdxgt7YFfL4v3n2jJErV7jXJ1rzo7TQ1GYUw8GlSFqk2GBGN-wwAwUNpAVLGQIhdXychVAqNMtf1DhNuAI--jXsBzkuUecXTH9jeWGGVPttjx2LmjmYIMmRulKL8DcRa88U6eguXKsh17X7Zdt5986tH7-dCgxY31ExmRLrThONg47H4RaMH4T6ReIO1Y68l9hFgyLPOIEvNsTOOXzjD6-jse9-jJ9Zi8VuLptjsWETTfyOB5J104RyEiHiHzQXnM4sTtVKzHmTnznxVG-qjPN8PuMPTC6OnzlQTKTG7eUh-iud3TgDB0eg57sZqnUfBR7W_AZ5KIBHETvSqACUSgNzEfxrQEZDniHkpY1AHVDmtXDpXj7aJfRVw9xCL9ya1tB8928R1fEB2jZiNxjquGPImOJMossu6GbqJyQnJ5Ie14m9_QKqpB3IM9X7PaIKPIfzgjwN_pN-4w-RaVtqOs7t-5kvc9_u3-kTHpD8bs9AVqyPaVLahqK1ZhG4PnAd_sIfGTcSUMgAA' },
    //         });
    //         console.log('result. ', result)
    //     } catch (e) {
    //         console.log(e)
    //     }

    // }

    handleTest = async (e) => {
        console.log('handle test coming..')
        try {
            const result = await axios({
                method: 'get',
                url: Baseurl + `api/storage/get`,
                headers: { 'content-type': 'application/json' }
            });
            console.log('result. ', result)
        } catch (e) {
            console.log(e)
        }
    };

    // handleTokenTest = () => {
    //     firebaseApp.auth().currentUser.getIdToken(true).then(function(idToken) {
    //         console.log(idToken)
    //         axios({
    //             method: 'post',
    //             url: Baseurl + `api/client/update-firebase-user`,
    //             headers: { 'content-type': 'application/json', 'Authorization': 'Bearer ' + idToken },
    //             data: { password: '123456' },
    //         }).then(res => console.log(res))
    //         .catch(err => console.log(err.response))
    //     }).catch(function(error) {
    //     // Handle error
    //     });  
    // }



     // week
     getCurrentWeek =()=> {
        const { notifications } = this.state
        let weekDay=[]
         let first = new Date((new Date()).getTime() - (7 * 24 * 60 * 60 * 1000));
        for(let i of notifications ){
            let date = new Date(i.createdAt);

            if(date.getTime()>first.getTime())
            {
                //   console.log(a)
                weekDay.push(i);

            }
           
        }
        console.log(weekDay);
       const AccountantData= weekDay.map((v , k)=>{
        
        return (
            <tr key={k}>
                <td>{new Date(v?.createdAt)?.toString()?.split(" G")[0]}</td>
                <td>{v?.extra?.clientType === "Individual" ? v?.Name : v?.extra?.clientName}</td>
                <td>{v?.extra?.filename}</td>
                <td>{v.Type}</td>
            </tr>
        )
        })
        return AccountantData
        
    }

    getLastWeek = () => {
        const { notifications } = this.state
        const weekDay=[];
        let first = new Date((new Date()).getTime() - (7 * 24 * 60 * 60 * 1000));
        for(let a of notifications ){
            let date = new Date(a.createdAt);
        
            if(date.getTime()<=first.getTime())
            {  
                //  console.log(a)
                weekDay.push(a);

            }
            
        }

        const AccountantData= weekDay.map((e , idx)=>{
        
            return <tr key={idx}>
                
                <td>{new Date(e?.createdAt)?.toString()?.split(" G")[0]}</td>
                <td>{e?.extra?.clientType === "Individual" ? e?.Name : e?.extra?.clientName}</td>
                <td>{e?.extra?.filename}</td>
                <td>{e.Type}</td>
    
            </tr>
        })
        return AccountantData
    }


    renderTest() {
        return (
            <>
                <button className='btn btn-primary' onClick={this.handleTest}>TEST</button>
                {/* <button className='btn btn-primary' onClick={this.getRefreshToken}>TEST</button> */}
            </>
        )
    }

    render() {
        const { isClient } = this.props;
        // const { notifications } = this.state

        return (
            <Fragment>
                {/* {this.renderTest()} */}
       
              
                <section className='card'>
                    <div className='card-header'>
                        <span className='cui-utils-title'>NOTIFICATIONS</span>
                    </div>
                    {!isClient &&
                        <div className='card-body'>
                            <div className="row">
                                <div className="col-lg-12">
                                    <h5>Current Week</h5>
                                </div>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Time</th>
                                            <th scope="col">Client Name</th>
                                            <th scope="col">File Name</th>
                                            <th scope="col">Type</th>                                                
                                        </tr>
                                    </thead>
                                    <tbody>
                                    
                                        {this.getCurrentWeek().length===0 ? <tr>
                                            <td></td>
                                            <td>
                                                No Data Found
                                            </td>
                                            <td></td>
                                            </tr> : this.getCurrentWeek()
                                        }
                                    
                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <h5>Last Week</h5>
                                </div>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Time</th>
                                            <th scope="col">Client Name</th>
                                            <th scope="col">File Name</th>
                                            <th scope="col">Type</th>                                                
                                        </tr>
                                    </thead>
                                    <tbody>                                    
                                        {this.getLastWeek().length===0 ? <tr>
                                            <td></td>
                                            <td>
                                                No Data Found
                                            </td>
                                            <td></td>
                                            </tr> : this.getLastWeek()
                                        }
                                      {/* {this.getLastWeek()} */}
                                    
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        // </div>
                    }
                </section>
            </Fragment>
        )
    }
}
