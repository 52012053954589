import React, {Component} from 'react';

export default class TopAlert extends Component {

    render(){
        // console.log(this.props);
        let {e} = this.props;
        setTimeout(()=>{this.props.close()}, 5000);

        let err;
        if(e.message === 'User cannot be confirm. Current status is CONFIRMED'){ err = 'Email has already been verified'
        } else if(e.message === 'ExpiredCodeException'){ err = 'Code has been expired.'
        } else { err = e.message
        }

        const type = e.type && e.type === 'success' ? 'alert-success' : 'alert-warning';

        return(
            <div className={`col-xs-11 col-sm-4 alert ${type} animated fadeInDown`}
                 style={{
                     display: "inline-block",
                     margin: "0px auto",
                     position: "fixed",
                     top: "20px",
                     left: "0px",
                     right: "0px"
                 }}
            >
                <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.props.close}>
                    <span aria-hidden="true">&times;</span>
                </button>
                <a href="/" className="alert-link" onClick={e=> e.preventDefault()}>{err}</a>
            </div>
        )
    }
}