import React, { Component } from 'react';


export default class Access extends Component {

    // handleClick = (key) => {
    //     const {access} = this.props;
    //     if(key === 'All'){
    //         access.All = access.All === 'N' ? 'Y' : 'N';

    //         Object.keys(access).map((accessKey, i)=> {
    //             access[accessKey] = access.All;
    //             return null
    //         });
    //         this.props.setAccess(access);
    //     } else {
    //         access.All = 'N';
    //         access[key] = access[key] === 'N' ? 'Y' : 'N';
    //         this.props.setAccess(access);
    //     }
    // };

    // active = (state) => {
    //     return this.props.access[state] === 'Y' ? 'btn btn-sm btn-success' : 'btn btn-sm btn-default'
    // };

    // getButtonName = key => {
    //     switch (key){
    //         case 'Calculator': return 'Tax Calculator';
    //         case 'IScan': return 'Scanner';
    //         default: return key
    //     }
    // };

    handleChange = (e, id, type) => {
        const { title } = this.props;
        // console.log('1 title, e, id, type = ', title, e.target.checked, id, type)
        const menu = title === "Personal" ? this.props.addPer : this.props.addBus;
        // console.log('2 menu = ', menu)
        const newMenu = menu.map(m => {
            if (m.id === id) {
                type === 'name' ? m.name = e.target.value : m.status = e.target.checked ? 'Y' : 'N';
                return m
            } else {
                return m
            }

        });
        // console.log('5 newMenu = ', newMenu)

        title === "Personal" ?
            this.props.handleChange({ target: { name: 'addPer', value: newMenu } })
            :
            this.props.handleChange({ target: { name: 'addBus', value: newMenu } })
    }

    addField = (e) => {
        const { title } = this.props;
        e.preventDefault();
        const menu = title === "Personal" ? this.props.addPer : this.props.addBus;
        const newObj = { id: menu.length + 1, name: '', status: 'N' }
        const newArray = menu.concat(newObj)

        title === "Personal" ?
            this.props.handleChange({ target: { name: 'addPer', value: newArray } })
            :
            this.props.handleChange({ target: { name: 'addBus', value: newArray } })
    }

    deleteField = (id) => {
        const { title } = this.props;
        const menu = title === "Personal" ? this.props.addPer : this.props.addBus;
        menu.splice(id - 1, 1);
        const newMenu = menu.map((m, i) => {
            m.id = i + 1
            return m
        });

        title === "Personal" ?
            this.props.handleChange({ target: { name: 'addPer', value: newMenu } })
            :
            this.props.handleChange({ target: { name: 'addBus', value: newMenu } })
    }

    handleInputAccessFolders = (array) => {
        return (
            <div className='col-md-12'>
                {
                    array.map((obj, i) => {
                        return (
                            <div className="row" key={i}>
                                <div className="col-md-4 d-flex">
                                    <label className='col-form-label mr-4'>Name:</label>
                                    <input className='form-control'
                                        type="text"
                                        disabled={(!this.props.edit || obj.name === "All" || obj.name === "Payrite" || obj.name === "IScan" || obj.name === "Xero" || obj.name === "QBO" || obj.name === "Calculator")}
                                        onChange={e => this.handleChange(e, i + 1, 'name')}
                                        value={obj.name}
                                    />
                                </div>

                                <div className='col-lg-3'>
                                    <div className='row' style={{ marginLeft: 0 }}>
                                        <label className='cui-utils-control cui-utils-control-radio mr-3'>
                                            Active :
                                        </label>

                                        <div className="custom-control custom-switch ">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                disabled={!this.props.edit}
                                                id={`switchone-${this.props.title}-${i}`}
                                                checked={obj.status === "Y"}
                                                onChange={e => this.handleChange(e, i + 1, 'active')}
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor={`switchone-${this.props.title}-${i}`}
                                            />
                                        </div>

                                        {/* <label className='cui-utils-control cui-utils-control-radio mr-5'>
                                            Yes
                                            <input type='radio'
                                                name='radio'
                                                // disabled={!this.props.edit}
                                                checked={obj.status == "Y"}
                                                onChange={e => this.handleChange(e, i + 1, 'status')}
                                            />
                                            <span className='cui-utils-control-indicator' />
                                        </label>
                                        <label className='cui-utils-control cui-utils-control-radio'>
                                            No
                                            <input type='radio'
                                                name='radio'
                                                // disabled={!this.props.edit}
                                                checked={obj.status == "N"}
                                                onChange={e => this.handleChange(e, i + 1, 'status')}
                                            />
                                            <span className='cui-utils-control-indicator' />
                                        </label> */}
                                    </div>
                                </div>

                                <div className="col-md-1">
                                    {(!this.props.edit || obj.name === "All" || obj.name === "Payrite" || obj.name === "IScan" || obj.name === "Xero" || obj.name === "QBO" || obj.name === "Calculator") ? ""
                                        :
                                        <button className="btn btn-sm btn-primary" onClick={() => this.deleteField(obj.id)}>X</button>
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    render() {
        const { /*access,*/ title, edit, addPer, addBus } = this.props;
        return (
            <>
                <div className='col-md-12'>
                    <label className='col-form-label'>
                        <strong> {title ? title : 'Access'}:</strong>
                    </label>
                    {/* {
                        Object.keys(access).map((accessKey, i)=> {
                            return(
                                <button key={i}
                                        className={`${this.active(accessKey)} mr-2 mb-2`}
                                        disabled={!edit}
                                        onClick={() => this.handleClick(accessKey)}>
                                    {this.getButtonName(accessKey)}
                                </button>
                            )
                        })
                    } */}
                    <div className="pull-right">
                        {edit ?
                            title === "Personal" ?
                                addPer.length < 7 ? <button className="btn btn-sm btn-primary" onClick={this.addField}>Add Personal Folder</button> : ""
                                :
                                <button className="btn btn-sm btn-primary" onClick={this.addField}>Add Business Folder</button>
                            : ""}
                    </div>
                </div>

                {
                    title === "Personal" ?
                        this.handleInputAccessFolders(addPer)
                        :
                        this.handleInputAccessFolders(addBus)
                }

            </>
        )
    }
}
