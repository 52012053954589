import React, {Component} from 'react';

export default class CommonFooter extends Component {
    render(){

        return(
            <div className="cui-login-footer text-center">
                <ul className="list-unstyled list-inline">
                    <li className="list-inline-item"><a href="/" onClick={e => e.preventDefault()}>Privacy Policy</a></li>
                    <li className="list-inline-item"><a href="/" onClick={e => e.preventDefault()}>Terms of Use</a></li>
                    <li className="list-inline-item"><a href="/" onClick={e => e.preventDefault()}>Contact Us</a></li>
                </ul>
            </div>
        )
    }
}