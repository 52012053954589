import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const LinkWithTooltip = ({ id, children, tooltip }) => {
    return (
      <OverlayTrigger
        overlay={<Tooltip id={id}>{tooltip}</Tooltip>}
        placement="bottom"
        delayShow={300}
        delayHide={150}
      >
        {children}
      </OverlayTrigger>
    );
}