import React, {Component} from 'react';
import { Switch, Route} from 'react-router-dom';
import StaffDetails from "./StaffDetails";


export default class StaffRoutes extends Component {
    render(){
        return(
            <Switch>
                <Route exact path="/staff"
                       render={(props) => <StaffMain {...props}/>}
                />
                <Route exact path="/staff/:id"
                       render={(props) => <StaffDetails {...props} {...this.props}/>}
                />
            </Switch>
        )
    }
}


class StaffMain extends Component {
    componentDidMount(){
        alert("Invalid address");
        this.props.history.push('/')
    }

    render(){
        return(
            <h1>Client Main</h1>
        )
    }
}
