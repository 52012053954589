import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import axios from 'axios';
import MenuLeft from "./components/menuLeft/MenuLeft";
import TopNav from "./components/topNav/TopNav";
import Dashboard from "./components/pages/Dashboard/Dashboard";
import NotFound from "../UnAuthenticated/ErrorPage/NotFound";
import { TextSpinner } from "../assests/loader/Spinner"
import TopAlert from "../utils/TopAlert";
import { Baseurl } from "../utils/Baseurl";
import AccountantRoutes from "./components/pages/Accountant/AccountantRoutes";
import ClientRoutes from "./components/pages/Client/ClientRoutes";
import firebaseApp from "../utils/Fire";
import DocumentManager from "./components/pages/DocumentManager/DocumentManager";
import StaffRoutes from "./components/pages/Staff/StaffRoutes";
import { getAccountant, getClientByEmail, getUserFromId } from "../utils/API";
import { sortedBusiness } from "../utils/Sorting";
import DocumentType from "./components/pages/DocumentType";
import TokenHandler from "./components/pages/TokenHandler";
import Referal from "./components/pages/Referal";
import ReferalModel from './components/pages/ReferalModel';
import YearRoutes from './components/pages/Year/YearRoutes';
import ClientNotification from './components/pages/ClientNotification/ClientNotification';
import BulkAddClient from './components/pages/Accountant/BulkAddClient';

// import './AuthenticatedHome.css';


export default class AuthenticatedHome extends Component {
    state = {
        homeLoading: true,
        modal: false,
        tree: [],
        accountantData: [],
        clientData: [],
        isAccountant: false,
        isClient: false,
        accountantId: ''
    };

    getAllAccountant() {
        return axios({
            method: 'get',
            url: Baseurl + `api/accountant/getAllAccountant`,
            headers: { 'content-type': 'application/json' },
        })
    }

    getAccountant(id) {
        return axios({
            method: 'get',
            url: Baseurl + `api/accountant/getAccountant?uid=${id}`,
            headers: { 'content-type': 'application/json' },
        })
    }

    getAllClients(id) {
        if (id) {
            return (
                axios({
                    method: 'get',
                    url: Baseurl + `api/client/getAllClients?accountantId=${id}`,
                    headers: { 'content-type': 'application/json' },
                })
            )
        } else {
            return (
                axios({
                    method: 'get',
                    url: Baseurl + `api/client/getAllClients`,
                    headers: { 'content-type': 'application/json' },
                })
            )
        }
    }

    async componentDidMount() {
        firebaseApp.auth().onAuthStateChanged(user => {
            if (user) {
                // console.log("user data",user);
                getUserFromId(user.uid)
                    .then(res => {
                        //  console.log('resp',res)
                        const data = res.data[0];


                        if (data.userType === 'S') {
                            // console.log('super');
                            this.makeSuperUserTree(data, false)

                        } else if (data.userType === 'A') {
                            console.log('accountant');
                            data.active === 'N'
                                ? this.notAllowed()
                                : this.makeAccountantTree(data, true);

                        } else if (data.userType === 'C') {
                            // console.log('client');
                            // this.setState({});
                            getClientByEmail(data.email)
                                .then(result => {
                                    const individualClient = result.data.find(cl => cl.clientType === 'Individual' && cl.primary_email === data.email)
                                    // if (individualClient) this.state.active = individualClient.activeCheck
                                    // console.log(individualClient)
                                    if (!result.data.length) {
                                        this.notAllowed();
                                    } else if (individualClient && (!individualClient?.activeCheck || individualClient?.activeCheck == "N")){
                                        this.notAllowed();
                                    } else {
                                        // console.log(result.data)
                                        // const clientDataRes = result.data.filter(dat=>dat.activeCheck=="Y")
                                        const clientData = sortedBusiness(result.data);
                                        // console.log('clientData id ++',clientData)
                                        this.setState({ isClient: true, homeLoading: false, user: data, clientData });

                                        this.props.history.push(`/document/${clientData[0].clientId}`);
                                    }
                                })
                                .catch(err => { console.log('get client err: ', err) });
                        } else {
                            this.setState({ isStaff: true });
                            if (data.active === 'N') {
                                this.notAllowed()
                            } else {
                                getAccountant(data.userType)
                                    .then(accountant => {
                                        const acc = accountant.data;
                                        // console.log('accountant.data',accountant.data)
                                        console.log('Staff of: ', acc[0].company_name);
                                        if (acc[0].active === 'N') {
                                            this.notAllowed();
                                        } else {
                                            this.getAllClients(acc[0].accountantId)
                                                .then(currentAccountantClients => {
                                                    //filtering client tree for staff in MenuLeft
                                                    this.makeTree(acc, currentAccountantClients.data, true, data);
                                                })
                                                .catch(err => console.log('staff clients error: ', err))
                                        }
                                    })
                                    .catch(err => console.log('staff parent error: ', err));
                            }
                        }
                    })
                    .catch(err => { console.log('get accountant err: ', err); this.setState({ homeLoading: false }) })
            }
        });
    }

    notAllowed = async () => {
        alert('You are not permitted to access this account. Please contact your Admin.');
        await this.props.handleLogout();
        this.props.history.push('/');
    };

    makeSuperUserTree = (user, isAccountant) => {
        this.getAllAccountant()
            .then(res => {
                // console.log('accountants: ', res)
                this.getAllClients()
                    .then(response => {
                        // console.log('all clients: ', response)
                        this.makeTree(res.data, response.data, isAccountant, user)
                    }).catch(err => {
                        console.log('getAllClient error', err)
                    });
            }).catch(err => {
                console.log('getAllAccountant error', err)
            });
    };

    makeAccountantTree = async (user, isAccountant) => {
        try {
            const accountant = await this.getAccountant(user.uid);
            this.setState({ accountantId: user.uid })

            const currentAccountantClients = await this.getAllClients(accountant.data[0].accountantId);

            this.makeTree(accountant.data, currentAccountantClients.data, isAccountant, user);
        } catch (e) {
            console.log('get all related clients err: ', e)
        }
    };

    makeTree = async (accountantData, clientData, isAccountant, user) => {
        // const user1 = await getClientByEmail('ashish@gmail.com');
        //
        // console.log(user1.data);

        const tree = [];

        accountantData.map(d => {
            d.business = [];
            d.individual = [];
            const clients = clientData.filter(client => d.accountantId === client.parentId);
            // console.log(clients);
            if (clients.length) {
                clients.map(c => {
                    if (c.clientType === 'Business') {
                        d.business.push(c);
                    } else {
                        d.individual.push(c)
                    }

                    return null
                });
                tree.push(d);
            } else {
                tree.push(d);
            }
            return null
        });
        // console.log(clientData);
        this.setState({ tree, accountantData, clientData, isAccountant, user, homeLoading: false });
    };

    toggleModal = () => {
        this.setState({ modal: !this.state.modal })
    };

    reload = async () => {
        await this.componentDidMount();
    };


    renderSwitch() {
        const { isClient, /*isAccountant*/ } = this.state;
        // console.log(this.props)
        return (
            <Switch>
                <Route exact path="/"
                    render={(props) => <Dashboard {...props} isClient={isClient} accountantData={this.state.accountantData} isAccountant={this.state.isAccountant}
                    />}
                />
                <Route path="/client"
                    render={(props) => <ClientRoutes {...props}
                        toggleModal={this.toggleModal}
                        reload={this.reload}
                        tree={this.state.tree}
                        clientData={this.state.clientData}
                    />}
                />

                {/* year */}
                <Route path="/year"
                    component={YearRoutes}
                />

                <Route path="/accountant"
                    render={(props) => <AccountantRoutes {...props}
                        toggleModal={this.toggleModal}
                        reload={this.reload}
                        isAccountant={this.state.isAccountant}
                    />}
                />

                <Route path="/staff"
                    render={(props) => <StaffRoutes {...props}
                        toggleModal={this.toggleModal}
                        reload={this.reload}
                        isAccountant={this.state.isAccountant}
                        clientData={this.state.clientData}
                    />}
                />
                <Route path="/document"
                    render={(props) => <DocumentManager {...props} {...this.state}
                        toggleModal={this.toggleModal}
                    // isAccountant={this.state.isAccountant}
                    />}
                />

                <Route path="/token-route-web"
                    render={(props) => <TokenHandler {...props} {...this.state}
                        toggleModal={this.toggleModal}
                    // isAccountant={this.state.isAccountant}
                    />}
                />
                {
                    !isClient && <>
                        <Route path="/document-type"
                            render={(props) => <DocumentType {...props} {...this.state}
                                toggleModal={this.toggleModal}
                            />}
                        />
                        <Route path="/referral"
                            render={(props) => <Referal {...props} {...this.state}
                                accountantData={this.state.accountantData}
                                toggleModal={this.toggleModal}
                            />}
                        />
                        <Route path="/bulk"
                            render={(props) => <BulkAddClient {...props} {...this.state}
                                accountantData={this.state.accountantData}
                                toggleModal={this.toggleModal}
                                reload={this.reload}
                            />}
                        />
                    </>
                }
                {
                    isClient &&
                    <>
                        <Route path="/referral"
                            render={(props) => <ReferalModel {...props} {...this.state}
                                toggleModal={this.toggleModal}
                            />}
                        />
                        <Route path="/notification"
                            render={(props) => <ClientNotification {...props} {...this.state}
                                toggleModal={this.toggleModal}
                            />}
                        />
                    </>
                }

                { /* Finally, catch all unmatched routes */}
                <Route component={NotFound} />
            </Switch>
        )
    }

    render() {
        return (
            this.state.homeLoading
                ? <TextSpinner />
                :
                <Fragment>
                    <div className="cui-initial-loading" />
                    <div className="cui-layout cui-layout-has-sider">
                        {
                            !this.state.isClient &&
                            <MenuLeft {...this.state}
                                {...this.props}
                            />
                        }
                        <div className="cui-layout">
                            <div className="cui-layout-header">
                                <TopNav toggleModal={this.toggleModal}
                                    reload={this.reload}
                                    {...this.state}
                                    {...this.props}
                                />
                            </div>
                            <div className="cui-layout-content">
                                <div className="cui-utils-content">
                                    {this.renderSwitch()}
                                </div>
                            </div>
                            {this.state.topAlert &&
                                <TopAlert e={this.state.topAlert}
                                    close={() => this.setState({ topAlert: null })} />
                            }
                            <div className="cui-layout-footer">
                                {/*{{"footer module"}}*/}
                            </div>
                        </div>
                        {this.state.modal && <div className="modal-backdrop fade show" />}
                    </div>
                </Fragment>
        )
    }
}
