import React, {Component} from 'react';
import axios from "axios";
import {Baseurl} from "../../../../../utils/Baseurl";
import LaddaButton, {EXPAND_RIGHT} from "react-ladda";


export default class ResetPwd extends Component {
    state = {
        password: '',
        c_password:''
    }

    handleChange = e => {
        this.setState({[e.target.name]: e.target.value});
    };

    updateClientPwd = async() => {
        const {password, c_password} = this.state;
        const {userData} = this.props;
        if(password.length > 5 && password === c_password){
            // console.log(userData.uid, password)
            
            this.setState({resetting: true});
            try {
                const result = await axios({
                    method: 'post',
                    url: Baseurl + `api/client/updateFirebaseUser`,
                    headers: { 'content-type': 'application/json' },
                    data: {uid: userData.uid, password},
                });
                console.log('updated user password. ', result)
                this.setState({resetting: false, password: '', c_password: ''});
                this.props.setDetailState({resetPwd: false});
                alert('Password changed successfully.')
            } catch (e) {   
                console.log(e);
                this.setState({resetting: false});
                alert('Something went wrong. Please try again.')
            }
        } else {
            alert('Password and confirm password should match and password should not be less than 6 characters.');
        }
    }

    renderDiv (label, type, stateName, disabled) {
        return (
            <div className='row'>
                <label className='col-md-4 col-form-label'>{label}</label>
                <div className='col-md-8' >
                    <input className='form-control'
                        name={stateName}
                        type={type}
                        disabled={disabled}
                        onChange={this.handleChange}
                        value={this.state[stateName]} />
                </div>
            </div>
        )
    };

    render(){
        const {resetting} = this.state;
        return(
            <React.Fragment>
                {this.renderDiv('Password:', 'password', 'password', false)}
                {this.renderDiv('Confirm Password:', 'password', 'c_password', false)}
                <LaddaButton loading={resetting}
                    onClick={this.updateClientPwd}
                    data-style={EXPAND_RIGHT}
                    className='btn btn-sm btn-primary'
                >
                    {resetting ? 'Resetting...' : 'Reset'}
                </LaddaButton>
            </React.Fragment>
        )
    }
}