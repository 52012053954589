import React, {Component} from 'react';

import {validateEmail} from "../../../../utils/FormValidater";
import {createUser, getStaff, getUser, updateUser} from "../../../../utils/API";
import StaffAccess from "./StaffAccess";
import {secondAuth} from "../../../../utils/Fire";
import {EXPAND_RIGHT} from "react-ladda";
import LaddaButton from "react-ladda";
import firebaseApp from "../../../../utils/Fire";

export default class StaffDetails extends Component {
    state = {
        isNewAccount: false,
        addStaff: false,
        uid: 'na',
        action: 'add',
        edit: false,
        email: '', primary_email: '',
        staff: [],
        clients: [],
        active: 'N',

        first_name: '', last_name: '',
        password: '',
        c_password:'',
        staffAccessData: []
    };

    async componentDidMount(){
        const id = this.props.location.pathname.split('/').pop();
        const staffData = await getStaff(id);
        const clients = this.props.clientData.filter(value => value.parentId === id);
        // console.log('componentDidMount staffData.data',staffData.data,clients);
        this.setState({staff: staffData.data.staffClientData, clients});
    }

    async UNSAFE_componentWillReceiveProps(props){
        const id = props.location.pathname.split('/').pop();
        const staffData = await getStaff(id);
        const clients = props.clientData.filter(value => value.parentId === id);
        console.log('UNSAFE_componentWillReceiveProps staffData.data',staffData.data);

        this.setState({staff: staffData.data.staffClientData, clients});
    }

    toggleCancel = () => {
        this.setState({
            edit: false, addStaff: false,
            first_name: '',
            last_name: '',
            phone: '',
            uid: 'na',
            email: '',
            active: 'N',
            staffAccessData: []
        });
    };

    toggleAddStaff = () => {
        this.setState({addStaff: !this.state.addStaff})
    };

    handleChange = e => {
        this.setState({[e.target.name]: e.target.value})
    };

    handleCheckbox = () => {
        this.setState({active: this.state.active === 'Y' ? 'N' : 'Y'})
    };

    handleSearch = async (e) => {
        e.preventDefault();

        console.log(this.state);
        const {email} = this.state;

        if(validateEmail(email)){
            try {
                const res = await getUser(email);
                res.data.userData.length
                    ? alert('This email already exits please enter a different email.')
                    : this.setState({primary_email: email, edit: true, isNewAccount: true});
                console.log(res);
            } catch (e) {
                console.log(e)
            }
        } else {
            alert('Please enter a valid email.')
        }

    };

    handleUser = async (user) => {
        const id = this.props.location.pathname.split('/').pop();
        const {first_name, last_name, phone, primary_email, uid, active, staffAccessData, isNewAccount} = this.state;
        let data = {
            first_name, last_name,
                phone: phone.length > 0 ? phone : 'na',
                active,
                email: primary_email,
                uid: user ? user.user.uid : 'na',
                userType: id,
                userAccess: staffAccessData.length ? staffAccessData : []
        } 
        let dataString = JSON.stringify(data)
        if(isNewAccount){
            const staff = await createUser(dataString);
            console.log('create', staff);
            // to clear state data
            this.toggleCancel();
        } else {
            // console.log(this.state.staffAccessData);
            const update = await updateUser({
                first_name, last_name,
                phone: phone.length > 0 ? phone : 'na',
                active,
                email: primary_email,
                uid: user ? user.user.uid : uid,
                userAccess: staffAccessData.length ? staffAccessData : []
            });
            console.log('update', update);
            // to clear state data
            this.toggleCancel();
        }
    };

    handleSave = async () => {
        console.log('handling save...');
        const {primary_email, password} = this.state;
        console.log('1',primary_email, password)
        try {
            if(password.length > 5){
                const user = await secondAuth.auth().createUserWithEmailAndPassword(primary_email, password);
                await this.handleUser(user);
                await secondAuth.auth().signOut();
                this.props.reload()
            } else {
                await this.handleUser(null);
                this.props.reload()
            }
        } catch (e) {
            console.log(e)
        }

        this.setState({addStaff: false, isNewAccount: false, edit: false, password: ''})
    };

    setStaffAccess = (staffAccessData) => {
        this.setState({staffAccessData})
    };

    editStaff = (data) => {

        this.setState({
            addStaff: true, edit: true,
            first_name: data.first_name,
            last_name: data.last_name,
            phone: data.phone,
            uid: data.uid,
            email: data.email,
            primary_email: data.email,
            active: data.active,
            staffAccessData: data.client.length ? data.client : []
        })
    };

    handleReset = (e) => {
        this.setState({resetting: true});

        const confirm = window.confirm('Do you want to reset password of ' + this.state.first_name);

        if (confirm) {
            firebaseApp.auth().sendPasswordResetEmail(this.state.primary_email)
                .then(() => {
                    alert('Password reset email send successfully to ' + this.state.email);
                    this.setState({resetting: false});
                })
                .catch(err => alert(err.message))
        } else {
            this.setState({resetting: false});
        }
    };

    // handleSelect = (type) => {
    //     this.state.staffAccessData.map(a => {
    //         a.allowed = type === 'select';
    //         return null
    //     });

    //     this.setStaffAccess(this.state.staffAccessData)
    // };

    render(){
        const {addStaff} = this.state;

        return(
            <section className='card'>
                <div className='card-header'>
                    <div className='pull-left'>
                        <span className='cui-utils-title'>
                            <strong>Staff Members </strong>
                        </span>
                    </div>
                    <div className='pull-right'>
                        {
                            addStaff  &&
                            <button className='btn btn-sm btn-primary mr-2' onClick={this.toggleCancel}>Cancel</button>
                        }
                        {
                            addStaff
                                ? <button className='btn btn-sm btn-primary' onClick={this.handleSave}>Save</button>
                                : <button className='btn btn-sm btn-primary' onClick={this.toggleAddStaff}>Add Staff</button>
                        }
                    </div>
                </div>
                <div className='card-body'>
                    {addStaff
                        ? this.renderStaffProfile()
                        : this.renderStaffList()
                    }
                </div>
            </section>
        )
    }

    renderDiv (label, type, stateName, disabled) {
        return (
            <div className='row'>
                <label className='col-md-4 col-form-label'>{label}</label>
                <div className='col-md-8' >
                    <input className='form-control'
                           name={stateName}
                           type={type}
                           disabled={disabled}
                           onChange={this.handleChange}
                           value={this.state[stateName]}/>
                </div>
            </div>
        )
    };

    renderStaffProfile(){
        const {uid, email, edit, clients, addStaff, resetting} = this.state;

        return(
            <div>
                <label className='col-lg-6 col-form-label mb-2'><strong>PROFILE</strong> </label>
                <div className='col-lg-12'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='row'>
                                <label className='col-md-4 col-form-label'>Email: </label>
                                <div className='col-md-8'>
                                    <div className='input-group'>
                                        <input className='form-control'
                                               name='email'
                                               value={email}
                                               disabled={!edit && !addStaff}
                                               type='text'
                                               placeholder='Enter email id of staff'
                                               onChange={this.handleChange}/>
                                        <div className='input-group-append'>
                                            <button className='btn btn-sm btn-primary'
                                                    onClick={this.handleSearch}
                                            >
                                                <i className="icmn-search"/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.renderDiv('First Name:', 'text', 'first_name', !edit)}
                            {uid === 'na'
                                ? this.renderDiv('Password:', 'password', 'password', !edit)
                                : <LaddaButton loading={resetting}
                                               onClick={this.handleReset}
                                               data-style={EXPAND_RIGHT}
                                               className='btn btn-sm btn-warning'
                                >
                                    {resetting ? 'Sending email' : 'Reset Password'}
                                </LaddaButton>
                            }
                        </div>
                        <div className='col-lg-6' >
                            <div className='row'>
                                <label className='col-md-4 col-form-label'>Active: </label>
                                <div className='col-md-8 col-form-label'>
                                    <label className='cui-utils-control cui-utils-control-checkbox'>
                                        <input type="checkbox"
                                               onChange={this.handleCheckbox}
                                               disabled={!edit}
                                               checked={this.state.active === 'Y'}/>
                                        <span className='cui-utils-control-indicator'/>
                                    </label>
                                </div>
                            </div>
                            {this.renderDiv('Last Name:', 'text', 'last_name', !edit)}
                            {this.renderDiv('Phone:', 'text', 'phone', !edit)}
                        </div>
                    </div>
                    <hr/>
                    {/* <div className='row'>
                        <div className='col-md-8'>
                            <button className='btn btn-sm btn-outline-primary mr-2' onClick={() => this.handleSelect('select')}>Select All Clients</button>
                            <button className='btn btn-sm btn-outline-warning' onClick={this.handleSelect}>De-Select All Clients</button>
                        </div>
                    </div>
                    <hr/> */}
                    {/* <div className='row'> */}
                        <StaffAccess clients={clients}
                                     edit={edit}
                                     setStaffAccess={this.setStaffAccess}
                                     staffAccessData={this.state.staffAccessData}/>
                    {/* </div> */}
                </div>
            </div>
        )
    }

    renderStaffList(){
        return(
            !this.state.staff.length
                ? <h5>Staff members not added yet.</h5>
                :
            <div className='table-responsive'>
                <table className='table table-hover'>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.state.staff.map((s, i) => {
                            return (
                                <tr key={i}>
                                    <td>{s.first_name} {s.last_name}</td>
                                    <td>{s.email}</td>
                                    <td>{s.active === 'N' ? 'Not Active' : 'Active'}</td>
                                    <td>
                                        <button className='btn btn-sm btn-primary'
                                                onClick={() => this.editStaff(s)}>
                                            Edit
                                        </button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
        )
    }
}
