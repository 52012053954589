import React, {Component} from 'react';
import { sortedIndividual } from '../../../../../utils/Sorting';


export default class PrimaryContact extends Component {
    state = {
        projectName: ''
    };

    // handleChange = (e) => {
    //
    // };

    render(){
        const {handleChange, allClients} = this.props;
        const {contactType, primaryContact, primaryContactRelation, primaryContactOtherRelation, edit, clientData} = this.props.state;
        const contactList = allClients.filter(v => v.clientType === 'Individual' && v.clientId !== clientData.clientId)
        const sortedClientList = sortedIndividual(contactList);
        const otherRelationName = primaryContactOtherRelation !== 'na' ? primaryContactOtherRelation : '';
        // console.log(sortedIndividual(contactList));
        return(
            <div className='col-lg-12'>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className='row'>
                            <label className='col-md-4 col-form-label'>Primary Contact Type:</label>
                            <div className='col-md-8' >
                                <select className='form-control mr-2' style={{height: 31, padding: '2px 1rem'}}
                                        name='contactType'
                                        disabled={!edit}
                                        value={contactType} onChange={handleChange}
                                >
                                    <option value='Self'>Self</option>
                                    <option value='Other'>Other</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='row'>
                            <label className='col-md-4 col-form-label'>Relation:</label>
                            <div className='col-md-8' >
                                <select className='form-control mr-2' style={{height: 31, padding: '2px 1rem'}}
                                        name='primaryContactRelation' value={primaryContactRelation}
                                        disabled={!edit || primaryContact === 'na'}
                                        onChange={handleChange}
                                >
                                    <option value='na'>Select Relation</option>
                                    {
                                        ['Husband', 'Wife', 'Son', 'Daughter', 'Father', 'Mother', 'Father-in-Law', 'Mother-in-Law', 'Others']
                                            .map((value, i) => {
                                                return(
                                                    <option value={value} key={i}>{value}</option>
                                                )
                                            })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className='row'>
                            <label className='col-md-4 col-form-label'>Select Contact:</label>
                            <div className='col-md-8' >
                                <select className='form-control mr-2' style={{height: 31, padding: '2px 1rem'}}
                                        name='primaryContact' value={primaryContact}
                                        disabled={!edit || contactType === 'Self'}
                                        onChange={handleChange}
                                >
                                    <option value='na'>Select Contact</option>
                                    {
                                        sortedClientList.map((value, i) => {
                                            const [firstName, lastName] = value.clientName.split('&');
                                                return(
                                                    <option value={value.clientId} key={i}>{lastName}, {firstName}</option>
                                                )
                                            })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='row'>
                            <label className='col-md-4 col-form-label'>Other Relation:</label>
                            <div className='col-md-8' >
                                <input className='form-control' type='text' placeholder='Enter your relationship' 
                                        disabled={!edit || primaryContactRelation !== 'Others'} value={otherRelationName} 
                                        onChange={handleChange} name='primaryContactOtherRelation'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
