import React, { Component } from "react";
import LaddaButton from "react-ladda";
import { EXPAND_RIGHT } from "react-ladda";
import { GET, POST } from "../../../utils/API";
import ClientReferralList from "./Client/ClientReferralList";

export default class ReferalModel extends Component {
  state = {
    email: "",
    firstName: "",
    lastName: "",
    phone_no: "",
    isLoading: false,
    currentClient: {},
    list: [],
    clientType: "business",
    t1Count: "",
    remarks: "",
    businessName: "",
  };

  componentDidMount() {
    const { clientData, user } = this.props;
    // console.log("this.props", this.props.user);
    if (clientData && clientData.length > 0) {
      const currentClient = clientData.find(
        (client) => client.primary_email === user.email
      );

      this.getReferralList(currentClient);

      this.setState({ currentClient });
    }
  }

  getReferralList = (currentClient) => {
    currentClient &&
      GET(`api/referal/getClientReferrals?clientId=${currentClient.clientId}`)
        .then((res) => {
          this.setState({ list: res.data });
        })
        .catch((err) => console.log("client referral error: ", err));
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handlepropsListFun = (array) => {
    this.setState({ list: array });
  };

  renderDiv(label, type, stateName, disabled) {
    return (
      <div className="row">
        <label className="col-md-4 col-form-label">{label}</label>
        <div className="col-md-8">
          <input
            className="form-control"
            name={stateName}
            type={type}
            disabled={disabled}
            onChange={this.handleChange}
            value={this.state[stateName]}
          />
        </div>
      </div>
    );
  }

  handleComplete = (e, type) => {
    e.preventDefault();
    let {
      email,
      firstName,
      lastName,
      phone_no,
      currentClient,
      t1Count,
      remarks,
      businessName,
      clientType
    } = this.state;
    this.setState({ isLoading: true });

    if (!window.confirm("Are you sure you want to Save Referral")) {
      this.setState({ isLoading: false });
      return;
    }

    const data = {
      email: email,
      first_name: firstName,
      last_name: lastName,
      phone_no: phone_no,
      clientId: currentClient.clientId,
      accountantId: currentClient.accountantDetails.accountantId,
      t1Count,
      remarks,
      businessName,
      refStatus: 'Prospect',
      clientType
    };

    console.log("data", data);

    POST("api/referal", { ...data })
      .then((r) => {
        this.setState({
          isLoading: false,
          email: "",
          firstName: "",
          lastName: "",
          phone_no: "",
          t1Count: "",
          remarks: "",
          businessName: "",
        });
        this.getReferralList(currentClient);
      })
      .catch((err) => console.log(err));
  };

  goBack = () => {
    this.props.history.goBack();
  };

  handleRadioClick = () => {
    this.setState({
      clientType:
        this.state.clientType === "business" ? "personal" : "business",
      t1Count: "",
      remarks: "",
      businessName: "",
    });
  };

  render() {
    return (
      <>
        <section className="card">
          <div className="card-header">
            <span className="cui-utils-title">
              <strong>Add Referrals</strong>
            </span>
          </div>
          <div className="card-body">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="row">
                    <label className="col-md-4 col-form-label">
                      <strong> Client Type </strong>
                    </label>
                    <div className="col-md-8">
                      <div className="row" style={{ marginLeft: 0 }}>
                        <label className="cui-utils-control cui-utils-control-radio mr-5">
                          <strong>Business</strong>
                          <input
                            type="radio"
                            name="radio"
                            checked={this.state.clientType === "business"}
                            onChange={this.handleRadioClick}
                          />
                          <span className="cui-utils-control-indicator" />
                        </label>
                        <label className="cui-utils-control cui-utils-control-radio">
                          <strong>Personal</strong>
                          <input
                            type="radio"
                            name="radio"
                            checked={this.state.clientType === "personal"}
                            onChange={this.handleRadioClick}
                          />
                          <span className="cui-utils-control-indicator" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  {this.state.clientType === "business"
                    ? this.renderDiv(
                      "Business Name:",
                      "text",
                      "businessName",
                      false
                    )
                    : this.renderDiv("No Of T1s:", "text", "t1Count", false)}
                  {this.renderDiv("Email:", "email", "email", false)}
                  {this.renderDiv(
                    "First Name:",
                    "firstName",
                    "firstName",
                    false
                  )}
                  {this.renderDiv("Last Name:", "lastName", "lastName", false)}
                  {this.renderDiv("Phone No:", "phone_no", "phone_no", false)}
                  {this.renderDiv("Remarks:", "text", "remarks", false)}
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-lg-6" />
                <div className="col-lg-6">
                  <div className="pull-right">
                    <LaddaButton
                      onClick={this.handleComplete}
                      data-style={EXPAND_RIGHT}
                      className="btn btn-sm btn-primary mr-2"
                    >
                      {this.state.isLoading ? "Saving.." : "Save"}
                    </LaddaButton>
                    <LaddaButton
                      onClick={this.goBack}
                      data-style={EXPAND_RIGHT}
                      className="btn btn-sm btn-secondary"
                    >
                      Go Back
                    </LaddaButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ClientReferralList
          {...this.state}
          data={this.props.user}
        // handlepropsListFun={this.handlepropsListFun}
        />
      </>
    );
  }
}
