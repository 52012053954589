import React,
{ useState, useEffect }
    from 'react';
import { getDocType1 } from '../../../../utils/API';
import axios from 'axios';
import { Baseurl } from "../../../../utils/Baseurl";



function ListUser() {

    const [list, setList] = useState([]);
    const [years, setYears] = useState("");

    // get api
    useEffect(() => {
        async function fetchData(){
            const ab = await getDocType1();
            setList(ab.data[0].years)
        }
        fetchData();
    }, []);


    const create = async () => {
        if(window.confirm(`Confirm to add year "${years}"`)){
            try {
                const res = await axios.post(Baseurl + `api/year/add`, {years});
                setList(res.data.data)
                setYears("")
            } catch (error) {
                console.log(error.message);         
            }
        }        
    }


    return (
        <React.Fragment>
            
            <section className="card">           
                <div className="card-body">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-6">
                                <span className="cui-utils-title">
                                    <strong>ADD YEAR </strong>
                                </span>
                                <hr/>
                                <div className="input-group width-80p">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="calendarName"
                                        value={years}
                                        placeholder='Enter to Add Year'
                                        onChange={(e)=>{setYears(e.target.value)}}
                                    />

                                    <div className="input-group-append">
                                        <button                                            
                                            className="btn btn-success btn-sm"
                                            onClick={create}
                                        >
                                            Add
                                        </button>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="col-lg-6">
                                <span className="cui-utils-title">
                                    <strong>YEAR LIST </strong>
                                </span>
                                <hr/>
                                {list.length > 0
                                    ? list.map((c, i) => {
                                        return (
                                            <div key={i}
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <p >
                                                    0{i + 1}. <strong>{c}</strong>
                                                </p>
                                                <i
                                                    // className="fe fe-minus-circle text-danger plus_icon"
                                                    // onClick={() => this.handleDelete("project", i)}
                                                />
                                            </div>
                                        );
                                    })
                                    : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default ListUser
