import React, {Component} from 'react';

export default class UnAuthenticatedHome extends Component {

    async componentDidMount(){
        // if(this.props.isAuthenticated){
        //     this.props.history.push('/access')
        // } else {
            this.props.history.push('/login')
        // }
    }

    render(){
        return(<div/>)
    }
}