import React, {Component} from 'react';
// import {Link} from 'react-router-dom';
import '../../assests/styles/topNav/top-bar.css';
import logo from "../../assests/img/logo.png";

export default class CommonHeader extends Component {
    render(){

        // const route = window.location.pathname.split('/').pop();
        return(
            <div className="cui-topbar">
                <div className='cui-topbar-left'>
                    <div className='cui-topbar-item'>
                        <div className="cui-menu-left-logo" style={{padding: '0 0 0 13px', background: 'none'}}>
                            <img src={logo} alt='' style={{maxHeight: 64, maxWidth: 315, height: 'auto'}}/>
                        </div>
                    </div>
                </div>
                {/*<div className='cui-topbar-right'>*/}
                    {/*<div className='cui-topbar-item'>*/}
                        {/*<div className='d-xl-inline-block text-nowrap'>*/}
                            {/*<Link to={route === 'login' ? '/signup' : '/login'}>{route === 'login' ? 'Sign Up' : 'Login'}</Link>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</div>*/}
            </div>

        )
    }
}
