import React, {Component} from 'react';


export default class MobileMenuDetails extends Component {

    handleChange = (e, id, type) => {
        const menu = this.props.mobileMenu;
        const newMenu = menu.map(m => {
            if(m.id === id){
                type === 'title' ? m.title = e.target.value : m.link = e.target.value;
                return m
            } else {
                return m
            }
            
        });
        this.props.handleChange({target: {name: 'mobileMenu', value: newMenu}});
    }

    addField = (e) => {
        e.preventDefault();
        const menu = this.props.mobileMenu;
        if(menu.length < 10){
            const newObj = {id: menu.length + 1, title: '', link: ''}
            const newArray = menu.concat(newObj)
            this.props.handleChange({target: {name: 'mobileMenu', value: newArray}})
        } else {
            alert('10 menu items allowed only for now.')
        }        
    }

    deleteField = (id) => {
        const menu = this.props.mobileMenu;
        menu.splice(id-1, 1);
        const newMenu = menu.map((m, i) => {
            m.id = i+1
            return m
        });
        this.props.handleChange({target: {name: 'mobileMenu', value: newMenu}});
    }

    render(){
        const {mobileMenu, edit} = this.props;
        return (
            <>                
                <label className="col-form-label"><strong>Mobile menu details</strong> </label>
                <div className="pull-right">
                    {edit && <button className="btn btn-sm btn-primary" onClick={this.addField}>Add Menu</button>}
                </div>
                <div className='col-md-12'>
                    {
                        mobileMenu.map(menu => {
                            return (
                                <div className="row" key={menu.id}>
                                    <div className="col-md-4 d-flex">
                                        <label className='col-form-label mr-4'>Title:</label>
                                        <input className='form-control'                                        
                                            type="text"
                                            disabled={!edit}
                                            onChange={e => this.handleChange(e, menu.id, 'title')}
                                            value={menu.title} />
                                    </div>
                                    
                                    <div className='col-md-7 d-flex'>
                                        <label className='col-form-label mr-4'>Link:</label>
                                        <input className='form-control'                                        
                                            type="text"
                                            disabled={!edit}
                                            onChange={e => this.handleChange(e, menu.id)}
                                            value={menu.link} />
                                    </div>
                                    
                                    <div className="col-md-1">
                                        {edit && <button className="btn btn-sm btn-primary" onClick={() => this.deleteField(menu.id)}>X</button>}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </>            
        )
    }
}