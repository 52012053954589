import React, { Component } from "react";
import Access from "../Client/Access";
import LaddaButton, { EXPAND_RIGHT } from "react-ladda";
import axios from "axios";
import { Baseurl } from "../../../../utils/Baseurl";
import { validateEmail } from "../../../../utils/FormValidater";
import { secondAuth } from "../../../../utils/Fire";
import {
	createUser,
	getAccountant,
	getUserFromId,
	updateUser,
} from "../../../../utils/API";
import firebaseApp from "../../../../utils/Fire";
import MobileMenuDetails from "./MobileMenuDetails";
import DisplayPicture from "./DisplayPicture";

export default class AccountantDetails extends Component {
	state = {
		edit: false,
		active: "N",
		first_name: "",
		last_name: "",
		company_name: "",
		phone: "",
		email: "",
		accountantId: "",
		password: "",
		c_password: "",
		loginCreatedOn: "na",
		facebook: "",
		twitter: "",
		blogUrl: "",
		instagram: "",
		googlemap: "",
		personalAccess: "",
		// {
		//     All: 'N', Payrite: 'N', IScan: 'N',
		//     Xero: 'N', QBO: 'N', Calculator: 'N'
		// }
		businessAccess: "",
		// {
		//     All: 'N', Payrite: 'N', IScan: 'N',
		//     Xero: 'N', QBO: 'N', Calculator: 'N'
		// }
		mobileMenu: [],
		address1: "",
		address2: "",
		address3: "",
		workingHrs1: "",
		workingHrs2: "",
		addPer: [],
		addBus: [],
		personal_client: "",
		business_client: "",
		staff: "",
	};

	updateAccountant(email, uid) {
		const {
			first_name,
			last_name,
			company_name,
			phone,
			active,
			accountantId,
			facebook,
			twitter,
			instagram,
			blogUrl /*personalAccess, businessAccess,*/,
			googlemap,
			mobileMenu,
			address1,
			address2,
			address3,
			workingHrs1,
			workingHrs2,
			addPer,
			addBus,
			business_client,
			personal_client,
			staff,
		} = this.state;

		console.log("addPer , addBus = ", addPer, addBus, "first updateAccountant");

		let perAll = addPer.filter((item) => item.name === "All");
		let perDyn = addPer.filter(
			(item) =>
				item.name !== "All" &&
				item.name !== "Payrite" &&
				item.name !== "IScan" &&
				item.name !== "Xero" &&
				item.name !== "QBO" &&
				item.name !== "Calculator"
		);
		let perStatic = addPer.filter(
			(item) =>
				item.name === "Payrite" ||
				item.name === "IScan" ||
				item.name === "Xero" ||
				item.name === "QBO" ||
				item.name === "Calculator"
		);

		let busAll = addBus.filter((item) => item.name === "All");
		let busDyn = addBus.filter(
			(item) =>
				item.name !== "All" &&
				item.name !== "Payrite" &&
				item.name !== "IScan" &&
				item.name !== "Xero" &&
				item.name !== "QBO" &&
				item.name !== "Calculator"
		);
		let busStatic = addBus.filter(
			(item) =>
				item.name === "Payrite" ||
				item.name === "IScan" ||
				item.name === "Xero" ||
				item.name === "QBO" ||
				item.name === "Calculator"
		);

		let addPerArr = [...perAll, ...perDyn, ...perStatic];
		let addBusArr = [...busAll, ...busDyn, ...busStatic];

		console.log(
			"addPerArr , addBusArr = ",
			addPerArr,
			addBusArr,
			"second filter arr"
		);

		let objPer = {};
		addPerArr.forEach((obj) => {
			objPer[obj.name] = obj.status;
		});

		let objBus = {};
		addBusArr.forEach((obj) => {
			objBus[obj.name] = obj.status;
		});

		console.log("objPer , objBus = ", objPer, objBus, "forEach with name");

		const data = {
			first_name: first_name.length > 0 ? first_name : "na",
			last_name: last_name.length > 0 ? last_name : "na",
			company_name,
			phone: phone.length > 0 ? phone : "na",
			email,
			active,
			accountantId,
			uid,
			facebook,
			twitter,
			instagram,
			googlemap,
			blogUrl,
			personalAccess: objPer,
			businessAccess: objBus,
			mobileMenu,
			address1,
			address2,
			address3,
			workingHrs1,
			workingHrs2,
			business_client,
			personal_client,
			staff,
		};

		return axios({
			method: "post",
			url: Baseurl + "api/accountant/updateAccountant",
			headers: {
				"Content-Type": "application/json",
			},
			data: data,
		});
	}

	parseAccess = (jsonData, type = 1) => {
		try {
			return JSON.parse(jsonData);
		} catch (error) {
			if (type === 1) {
				return {
					All: "N",
					Payrite: "N",
					IScan: "N",
					Xero: "N",
					QBO: "N",
					Calculator: "N",
				};
			} else {
				return {
					All: "N",
					Payrite: "N",
					IScan: "N",
					Xero: "N",
					QBO: "N",
					Calculator: "N",
				};
			}
		}
	};

	handleForEachAccess = (data) => {
		let array = [];

		Object.keys(data).map((key, i) =>
			array.push({ id: i + 1, name: key, status: data[key] })
		);

		return array;
	};

	settingInitialState = (propsPath) => {
		const id = propsPath
			? propsPath
			: this.props.location.pathname.split("/").pop();

		getAccountant(id)
			.then((res) => {
				if (res.data.length) {
					const data = res.data[0];

					let x = {};
					let y = {};

					if (data.personalDefaultFolders) {
						x = this.parseAccess(data.personalDefaultFolders, 1);
					} else {
						x = {
							All: "N",
							Payrite: "N",
							IScan: "N",
							Xero: "N",
							QBO: "N",
							Calculator: "N",
						};
					}
					if (data.businessDefaultFolders) {
						// console.log('3')
						y = this.parseAccess(data.businessDefaultFolders, 2);
					} else {
						// console.log('4')
						y = {
							All: "N",
							Payrite: "N",
							IScan: "N",
							Xero: "N",
							QBO: "N",
							Calculator: "N",
						};
					}

					// if (!x.All || !x.Payrite || !x.IScan || !x.Xero || !x.QBO || !x.Calculator) {
					//     console.log('1')
					//     x = {
					//         All: 'N', Payrite: 'N', IScan: 'N',
					//         Xero: 'N', QBO: 'N', Calculator: 'N'
					//     }
					// }
					// if (!y.All || !y.Payrite || !y.IScan || !y.xero || !y.QBO || !y.Calculator) {
					//     console.log('2')
					//     y = {
					//         All: 'N', Payrite: 'N', IScan: 'N',
					//         Xero: 'N', QBO: 'N', Calculator: 'N'
					//     }
					// }

					let per = this.handleForEachAccess(x);
					let bus = this.handleForEachAccess(y);

					if (data.uid && data.uid !== "na") {
						getUserFromId(data.uid)
							.then((result) => {
								let date = new Date(result.data[0].createdAt).toLocaleString();

								this.setState({
									first_name: data.first_name === "na" ? "" : data.first_name,
									last_name: data.last_name === "na" ? "" : data.last_name,
									company_name: data.company_name,
									personal_client: data.personal_client,
									business_client: data.business_client,
									staff: data.staff,
									phone: data.phone === "na" ? "" : data.phone,
									email: validateEmail(data.email) ? data.email : "",
									active: data.active,
									accountantId: data.accountantId,
									uid: data.uid,
									loginCreatedOn: date,
									edit: false,
									facebook: data.facebook ? data.facebook : "",
									twitter: data.twitter ? data.twitter : "",
									instagram: data.instagram ? data.instagram : "",
									googlemap: data.googlemap ? data.googlemap : "",
									blogUrl: data.blogUrl ? data.blogUrl : "",
									personalAccess: this.parseAccess(
										data.personalDefaultFolders,
										1
									),
									businessAccess: this.parseAccess(
										data.businessDefaultFolders,
										2
									),
									mobileMenu: data.mobileMenu ? data.mobileMenu : [],
									address1: data.address1 ? data.address1 : "",
									address2: data.address2 ? data.address2 : "",
									address3: data.address3 ? data.address3 : "",
									workingHrs1: data.workingHrs1 ? data.workingHrs1 : "",
									workingHrs2: data.workingHrs2 ? data.workingHrs2 : "",
									addPer: per,
									addBus: bus,
								});
							})
							.catch((err) => {
								alert("Could not fetch accountant details.");
								console.log("get accountant credentials error", err);
							});
					} else {
						// console.log('didn\'t get result.');
						this.setState({
							first_name: data.first_name === "na" ? "" : data.first_name,
							last_name: data.last_name === "na" ? "" : data.last_name,
							company_name: data.company_name,
							personal_client: data.personal_client,
							business_client: data.business_client,
							staff: data.staff,
							phone: data.phone === "na" ? "" : data.phone,
							email: validateEmail(data.email) ? data.email : "",
							active: data.active,
							accountantId: data.accountantId,
							uid: data.uid && data.uid !== "na" ? data.uid : "na",
							loginCreatedOn: "na",
							edit: false,
							facebook: data.facebook ? data.facebook : "",
							twitter: data.twitter ? data.twitter : "",
							instagram: data.instagram ? data.instagram : "",
							googlemap: data.googlemap ? data.googlemap : "",
							blogUrl: data.blogUrl ? data.blogUrl : "",
							personalAccess: this.parseAccess(data.personalDefaultFolders, 1),
							businessAccess: this.parseAccess(data.businessDefaultFolders, 2),
							mobileMenu: data.mobileMenu ? data.mobileMenu : [],
							address1: data.address1 ? data.address1 : "",
							address2: data.address2 ? data.address2 : "",
							address3: data.address3 ? data.address3 : "",
							workingHrs1: data.workingHrs1 ? data.workingHrs1 : "",
							workingHrs2: data.workingHrs2 ? data.workingHrs2 : "",
							addPer: per,
							addBus: bus,
						});
					}
				} else {
					alert("URL is incorrect, going back to Main Page...");
					this.props.history.push("/");
				}
			})
			.catch((err) => {
				console.log("get all accountants error", err);
			});
	};

	componentDidMount() {
		this.settingInitialState();
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.settingInitialState(props.location.pathname.split("/").pop());
	}

	validateForm() {
		const { company_name, email } = this.state;
		email.length !== 0 &&
			!validateEmail(email) &&
			alert("Company Name or Email is invalid.");
		// company_name.length < 1 && this.setState({company_name_error: true})
		return (
			company_name.length > 0 && (email.length === 0 || validateEmail(email))
		);
	}

	validateSignUp() {
		const { password, c_password, email } = this.state;
		c_password !== password &&
			alert("Password and Confirm Password did not match. Please try again");
		!validateEmail(email) && alert("Please enter a valid email address");
		return password === c_password;
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
		// e.target.name === 'company_name' && this.setState({company_name_error: false})
	};

	handleCheckbox = () => {
		this.setState({ active: this.state.active === "Y" ? "N" : "Y" });
	};

	updateAccountantInfo = async () => {
		this.setState({ isLoading: true, edit: false });
		const { first_name, last_name, phone, email, active, accountantId, uid } =
			this.state;
		const newEmail = email.length === 0 ? accountantId : email;

		try {
			const res = await this.updateAccountant(newEmail, uid);

			const user =
				uid === "na"
					? { statusText: "NA" }
					: await updateUser({
							first_name: first_name.length > 0 ? first_name : "na",
							last_name: last_name.length > 0 ? last_name : "na",
							phone: phone.length > 0 ? phone : "na",
							active,
							email,
							uid,
							userAccess: "na",
					  });
			console.log(
				"not new account, updated...",
				user.statusText,
				res.statusText
			);

			this.settingInitialState(accountantId);
			this.setState({ isLoading: false });
		} catch (e) {
			console.log(e.response);
			alert(
				"This email might be used by another accountant. Please enter a different Email to proceed."
			);
			this.setState({ isLoading: false });
		}
	};

	handleSave = async () => {
		this.setState({ isLoading: true });

		if (this.state.edit) {
			if (this.validateForm()) {
				// console.log('Updating accountant');
				await this.updateAccountantInfo();
				this.setState({ isLoading: false, edit: false });
			} else {
				this.setState({ isLoading: false });
			}
		} else {
			// console.log('Editing accountant');
			this.setState({ edit: true, isLoading: false });
		}
	};

	handleSignUp = async () => {
		this.setState({ creatingUser: true, edit: false });

		const {
			first_name,
			last_name,
			phone,
			email,
			active,
			accountantId,
			password,
		} = this.state;

		if (this.validateSignUp()) {
			try {
				const user = await secondAuth
					.auth()
					.createUserWithEmailAndPassword(email, password);
				const res = await this.updateAccountant(
					this.state.email,
					user.user.uid
				);
				console.log(res);

				const newUser = await createUser({
					first_name: first_name.length > 0 ? first_name : "na",
					last_name: last_name.length > 0 ? last_name : "na",
					phone: phone.length > 0 ? phone : "na",
					active,
					email,
					uid: user ? user.user.uid : "na",
					userType: "A",
					userAccess: "na",
				});
				this.setState({ isNewAccount: false });
				console.log("New User", newUser.statusText);

				this.setState({ creatingUser: false, uid: user.user.uid });
				await secondAuth.auth().signOut();
				this.settingInitialState(accountantId);
			} catch (e) {
				console.log(e);
				alert(e.message);
				this.setState({ creatingUser: false });
			}
		} else {
			this.setState({ creatingUser: false, edit: true });
		}
	};

	handleReset = () => {
		this.setState({ resetting: true });

		const confirm = window.confirm(
			"Do you want to reset password of " + this.state.company_name
		);

		if (confirm) {
			firebaseApp
				.auth()
				.sendPasswordResetEmail(this.state.email)
				.then(() => {
					alert(
						"Password reset email send successfully to " + this.state.email
					);
					this.setState({ resetting: false });
				})
				.catch((err) => alert(err.message));
		} else {
			this.setState({ resetting: false });
		}
	};

	renderDiv(label, type, stateName) {
		const { uid, edit } = this.state;
		const hasUID =
			(stateName === "email" ||
				stateName === "password" ||
				stateName === "c_password") &&
			uid !== "na";
		// console.log(stateName, this.state[stateName])
		return (
			<div className="row mb-2">
				<label className="col-md-4 col-form-label">{label}</label>
				<div className="col-md-8">
					<input
						className="form-control"
						name={stateName}
						type={type}
						disabled={!edit || hasUID}
						onChange={this.handleChange}
						value={this.state[stateName]}
					/>
				</div>
			</div>
		);
	}

	setBusinessAccess = (access) => {
		this.setState({ businessAccess: access });
	};

	setPersonalAccess = (access) => {
		this.setState({ personalAccess: access });
	};

	render() {
		const {
			isLoading,
			uid,
			loginCreatedOn,
			creatingUser,
			password,
			edit,
			resetting,
			mobileMenu,
			accountantId,
		} = this.state;
		const { isAccountant } = this.props;
		// console.log(this.props);
		return (
			// !this.props.isAccountant &&
			<section className="card">
				<div className="card-header">
					<div className="pull-left">
						<span className="cui-utils-title">
							<strong>Accountant Details</strong>
						</span>
					</div>
					<div className="pull-right">
						{uid === "na" ? (
							<LaddaButton
								loading={creatingUser}
								onClick={this.handleSignUp}
								disabled={password.length < 6}
								data-style={EXPAND_RIGHT}
								className="btn btn-sm btn-primary mr-2"
							>
								Create Login
							</LaddaButton>
						) : (
							<span className="mr-2">
								<strong>Login Created: {loginCreatedOn}</strong>
							</span>
						)}
						<LaddaButton
							loading={isLoading}
							onClick={this.handleSave}
							data-style={EXPAND_RIGHT}
							className="btn btn-sm btn-primary"
						>
							{edit ? "Save" : "Edit"}
						</LaddaButton>
					</div>
				</div>
				<div className="card-body">
					<div className="col-lg-12">
						<div className="row">
							<div className="col-lg-6">
								{this.renderDiv("First Name:", "text", "first_name")}
								{this.renderDiv("Company Name:", "text", "company_name")}
								{this.renderDiv("Email address:", "text", "email")}
							</div>
							<div className="col-lg-6">
								{this.renderDiv("Last Name:", "text", "last_name")}
								{this.renderDiv("Phone:", "text", "phone")}
								<div className="row">
									<label className="col-md-4 col-form-label">Active: </label>
									<div className="col-md-8 col-form-label">
										<label className="cui-utils-control cui-utils-control-checkbox">
											<input
												type="checkbox"
												onChange={this.handleCheckbox}
												checked={this.state.active === "Y"}
												disabled={!this.state.edit}
											/>
											<span className="cui-utils-control-indicator" />
										</label>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-6">
								{this.renderDiv("Facebook link:", "text", "facebook")}
								{this.renderDiv("Twitter Link:", "text", "twitter")}
								{this.renderDiv("Google Map Link:", "text", "googlemap")}
							</div>
							<div className="col-lg-6">
								{this.renderDiv("Instagram Link:", "text", "instagram")}
								{this.renderDiv("Blog Link:", "text", "blogUrl")}
							</div>
						</div>
						<div className="row">
							<div className="col-lg-6">
								{this.renderDiv("Address Line 1:", "text", "address1")}
								{this.renderDiv("Address Line 2:", "text", "address2")}
								{this.renderDiv("Address Line 3:", "text", "address3")}
							</div>
							<div className="col-lg-6">
								{this.renderDiv("Working Hours 1:", "text", "workingHrs1")}
								{this.renderDiv("Working Hours 2:", "text", "workingHrs2")}
							</div>
						</div>
						{!isAccountant && (
							<div className="row">
								<div className="col-lg-6">
									{this.renderDiv(
										"Personal Client:",
										"text",
										"personal_client"
									)}
									{this.renderDiv(
										"Business Client:",
										"text",
										"business_client"
									)}
								</div>
								{/* <div className="col-lg-6">
                                {this.renderDiv('Staff:', 'text', 'staff')}
                               
                            </div> */}
							</div>
						)}

						<hr className="mb-4" />
						<MobileMenuDetails
							mobileMenu={mobileMenu}
							handleChange={this.handleChange}
							edit={edit}
						/>
						<hr className="mb-4" />
						<label className="col-form-label">
							<strong>Default folder selection for new clients : </strong>{" "}
						</label>
						<Access
							title="Personal"
							setAccess={this.setPersonalAccess}
							access={this.state.personalAccess}
							edit={edit}
							handleChange={this.handleChange}
							addPer={this.state.addPer}
						/>
						<div className="mb-4" />
						<Access
							title="Business"
							setAccess={this.setBusinessAccess}
							access={this.state.businessAccess}
							edit={edit}
							handleChange={this.handleChange}
							addBus={this.state.addBus}
						/>
						<hr className="mb-5" />
						<div className="row">
							<div className="col-lg-6">
								{loginCreatedOn === "na" &&
									this.renderDiv("Password:", "password", "password")}
								{loginCreatedOn === "na" ? (
									this.renderDiv("Confirm Password:", "password", "c_password")
								) : (
									<LaddaButton
										loading={resetting}
										onClick={this.handleReset}
										data-style={EXPAND_RIGHT}
										className="btn btn-sm btn-warning"
									>
										{resetting ? "Sending email" : "Reset Password"}
									</LaddaButton>
								)}
							</div>
							<DisplayPicture accountantId={accountantId} />
						</div>
					</div>
				</div>
			</section>
		);
	}
}
